import { CopyInput } from '@clubhouse/shared/components/CopyInput';
import { getHref } from 'components/gql/stories/links';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const StoryPermalink = ({
  storyId,
  storyName
}) => {
  return _jsxs("div", {
    className: "attribute",
    children: [_jsx("span", {
      className: "name",
      children: "Permalink"
    }), _jsx(CopyInput, {
      value: getHref(storyId, storyName, true),
      className: "clipboard"
    })]
  });
};
StoryPermalink.displayName = "StoryPermalink";