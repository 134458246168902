import { FEATURE_TOGGLES, useVariation } from '@clubhouse/feature-toggles';
import { LoadingDots } from '@clubhouse/shared/animations';
import { Button } from '@clubhouse/shared/components/Button';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { addToast, removeToast, ToastText } from '@clubhouse/shared/components/Toast';
import { useToggleState } from '@clubhouse/shared/hooks';
import { useEffect, useState } from 'react';
import { useConsolidatedDataFetched } from 'utils/consolidatedFetch';
import { getValue, setValue } from 'utils/localStorage';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function useInstallPwa() {
  const isReady = useConsolidatedDataFetched();
  const [showInstallPrompt, toggleShowInstallPrompt] = useToggleState(false);
  const [pendingUserChoice, togglePendingUserChoice] = useToggleState(false);
  const [installPrompt, setInstallPrompt] = useState(null);
  useEffect(() => {
    const onInstalled = () => {
      setInstallPrompt(null);
    };
    const onBeforeInstall = e => {
      e.preventDefault();
      if (getValue('installPromptDismissed')) return;
      setInstallPrompt(e);
    };
    window.addEventListener('appinstalled', onInstalled);
    window.addEventListener('beforeinstallprompt', onBeforeInstall);
    return () => {
      setInstallPrompt(null);
      window.removeEventListener('appinstalled', onInstalled);
      window.removeEventListener('beforeinstallprompt', onBeforeInstall);
    };
  }, []);
  useEffect(() => {
    if (isReady && installPrompt) toggleShowInstallPrompt.on();else toggleShowInstallPrompt.off();
  }, [toggleShowInstallPrompt, installPrompt, isReady]);
  useEffect(() => {
    if (showInstallPrompt) {
      addToast({
        id: 'installPwa',
        kind: 'info',
        customIcon: 'Help',
        Content: () => _jsxs(Spaced, {
          vertically: true,
          amount: "space4",
          children: [_jsx(ToastText, {
            children: "Do you want to install Shortcut?"
          }), _jsxs(Spaced, {
            horizontally: true,
            amount: "space2",
            children: [_jsx(Button, {
              onClick: async () => {
                if (!installPrompt) return;
                togglePendingUserChoice.on();
                const result = await installPrompt.prompt();
                togglePendingUserChoice.off();
                toggleShowInstallPrompt.off();
                if (result.outcome === 'dismissed') {
                  setValue('installPromptDismissed', true);
                }
              },
              children: pendingUserChoice ? _jsx(LoadingDots, {
                fill: "currentColor"
              }) : 'Install'
            }), _jsx(Button, {
              kind: Button.KIND.SECONDARY,
              onClick: () => {
                setValue('installPromptDismissed', true);
                toggleShowInstallPrompt.off();
              },
              children: "No Thanks!"
            })]
          })]
        })
      });
    } else {
      removeToast('installPwa');
    }
  }, [installPrompt, pendingUserChoice, togglePendingUserChoice, showInstallPrompt, toggleShowInstallPrompt]);
}
function _PwaPrompt() {
  useInstallPwa();
  return null;
}
export function PwaPrompt() {
  const {
    value
  } = useVariation(FEATURE_TOGGLES.PWA_PROMPT);
  return value ? _jsx(_PwaPrompt, {}) : null;
}