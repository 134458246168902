import { UpdateParentStoryMutationDocument } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { useCallback } from 'react';
import { gql } from '@clubhouse/datalayer';
import { useMutation } from 'gql';
const UpdateParentStoryMutation = UpdateParentStoryMutationDocument;
export function useUpdateParentStoryMutation() {
  const [updateParent] = useMutation(UpdateParentStoryMutation);
  return useCallback(({
    storyId,
    parentStory
  }) => updateParent({
    variables: {
      storyId,
      input: {
        parentStory
      }
    }
  }), [updateParent]);
}