import { TeamIcon } from '@clubhouse/shared/components/TeamIcon';
import { getTeamColor } from '@clubhouse/shared/utils/teams';
import { TeamTooltip } from 'components/gql/team/TeamTooltip';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Group = ({
  teamId,
  color,
  name,
  thumbnailUrl
}) => {
  return _jsx(TeamTooltip, {
    teamId: teamId,
    children: _jsx("div", {
      children: _jsx(TeamIcon, {
        size: 20,
        iconUrl: thumbnailUrl,
        alt: name ? name : 'No team',
        backgroundColor: getTeamColor({
          color
        }),
        iconType: "new-team-icon"
      })
    })
  });
};
Group.displayName = "Group";