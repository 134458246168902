// apollo-cache-persist provides an API to sync the cache, but it requires some setup work.

import { EventNamesEnum } from '@useshortcut/events';
import { useCallback, useState } from 'react';
import { logNamedEvent } from 'utils/monitoring';
import { useOptInToggleState } from './useOptInToggleState';

// Because this is a temporary component and we haven't needed the cache sync yet, this hack seems worth the tradeoff.
const hackyWaitForApolloCacheSync = () => new Promise(res => setTimeout(res, 2000));
export const useOptInSetState = () => {
  const {
    setValue
  } = useOptInToggleState();
  const [isChangingState, setIsChangingState] = useState(false);
  const setValueAndReload = useCallback(async value => {
    setIsChangingState(true);
    try {
      await setValue(value);
      logNamedEvent(EventNamesEnum.CTA_Clicked, {
        cta_type: 'try-new-stories-page',
        cta_selection: value ? 'toggle-on' : 'toggle-off',
        action_context: 'Stories page 2.0 toggle enabled'
      });
      await hackyWaitForApolloCacheSync();
      window.location.reload();
    } catch (e) {
      setIsChangingState(false);
    }
  }, [setValue]);
  return {
    setValue: setValueAndReload,
    isChangingState
  };
};