
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="value">'+'  ';if (d.name) {str += '    <strong>';str += (d.name);str += '</strong>'+'  ';}str += '  ';str += (d.body);str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
