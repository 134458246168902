import * as NotificationFeedTemplate from 'app/client/core/views/templates/notificationFeed.html';
import * as NotificationFooterTemplate from 'app/client/core/views/templates/notificationFooter.html';
import * as NotificationTabsTemplate from 'app/client/core/views/templates/notificationTabs.html';import NotificationsController from 'app/client/core/js/controllers/notifications.js';
import TabsController from 'app/client/core/js/controllers/tabs.js';
import FeatureModel from 'app/client/core/js/models/feature.js';
import Loading from 'app/client/core/js/modules/loading.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'Notifications'], NotificationsController],
  [['Controller', 'Tabs'], TabsController],
  [['Model', 'Feature'], FeatureModel],
  [['Loading'], Loading],
  [['Controller', 'Notifications'], NotificationsController],
  [['Controller', 'Tabs'], TabsController],
  [['Model', 'Feature'], FeatureModel],
  [['Loading'], Loading]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';var noActivityAvailable = !d.hasActivity && !d.isLoadingInitially;str += '';var isProjectsFeatureEnabled = App.Model.Feature.isProjectsFeatureEnabled();str += '<div class="notification-dialog empty-container" data-notification-dialog>'+'  <h3 class="notification-dialog-header">My Activity Feed <span class="fa fa-question-circle" data-tooltip="This feed shows activity on any Project, Epic, or Story that you follow, or any comment that mentions you." data-tooltip-align="bottom"></span></h3>'+''+'  ';if (d.hasActivity) {str += '    <div id="activity-filters">'+'      <a href="#" class="activity-feed-settings-button" data-controller="Notifications" data-on-click="openSettingsDropdown"><span class="fa fa-gear"></span></a>'+''+'      <div class="tabs underlined-tabs mini clearfix" data-controller="Tabs">'+'        ';str += NotificationTabsTemplate.render( d);str += '      </div>'+''+'      <div class="notification-feed" data-notification-feed>'+'        ';str += NotificationFeedTemplate.render( d);str += '        <div class="notification-footer">'+'          ';str += NotificationFooterTemplate.render( d);str += '        </div>'+'      </div>'+''+'    </div>'+'  ';}str += '  ';if (noActivityAvailable) {str += '    <div class="empty">'+'      <img src="';str += _CfS( d.noActivityImage );str += '" class="dashboard__image activity" alt="" />'+'      <p id="no_activity" class="heading">'+'        No activity yet!'+'      </p>'+'    </div>'+'  ';}str += '  ';if (d.isLoadingInitially) {str += '    <div class="loading-activity">';str += (App.Controller.Notifications.renderLoading());str += '</div>'+'  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
