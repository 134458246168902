import NotificationsController from 'app/client/core/js/controllers/notifications';
import { closeHelpHub } from 'components/shared/command-bar/CommandBar';
export const openNotifications = () => {
  NotificationsController.open();
  closeHelpHub();
};
export const renderNotifications = () => {
  return NotificationsController.renderFeedTemplate({
    showButton: true
  });
};
export const fetchInitialActivityFeed = () => {
  NotificationsController.fetchAndRenderInitialActivity();
};