import "core-js/modules/es.array.push.js";
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import Constants from '../modules/constants';
import * as Event from '../_frontloader/event';
import Log from '../modules/log';
import TeamModel from './team';
import Utils from '../modules/utils';
import WorkflowModel from './workflow';
import SpaceModel from './space';
import Globals from '../_frontloader/globals';
import View from '../modules/view';
import { StatusIcon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons/DeprecatedIconAdapter';
import _ from 'lodash';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
Collection.create('Column', exports);
exports.getOldStoriesCompletedBefore = column => {
  return Globals.get(`oldStoriesCompletedBefore__${column.id}`);
};
exports.setOldStoriesCompletedBefore = (column, momentInstance) => {
  return Globals.set(`oldStoriesCompletedBefore__${column.id}`, momentInstance);
};
exports.isLoadingOldStories = column => {
  return Globals.get(`isLoadingOldStories__${column.id}`) === true;
};
exports.setLoadingOldStories = (column, loading) => {
  return Globals.set(`isLoadingOldStories__${column.id}`, !!loading);
};
exports.getStateTypes = () => {
  return [{
    name: 'Backlog',
    value: 'backlog',
    type: 'backlog'
  }, {
    name: 'Unstarted',
    value: 'unstarted',
    type: 'unstarted'
  }, {
    name: 'Started',
    value: 'started',
    type: 'started'
  }, {
    name: 'Done',
    value: 'done',
    type: 'done'
  }];
};
exports.findStatusIconFromType = type => {
  if (type === 'done') {
    return _jsx(StatusIcon, {
      icon: "Done"
    });
  } else if (type === 'started') {
    return _jsx(StatusIcon, {
      icon: "Started"
    });
  } else if (type === 'backlog') {
    return _jsx(StatusIcon, {
      icon: "Backlog"
    });
  } else {
    return _jsx(StatusIcon, {
      icon: "Unstarted"
    });
  }
};
exports.isBacklog = column => {
  return column && column.type === Constants.WORKFLOW_STATE_TYPES.BACKLOG;
};
exports.isUnstarted = column => {
  return column && column.type === Constants.WORKFLOW_STATE_TYPES.UNSTARTED;
};
exports.isStarted = column => {
  return column && column.type === Constants.WORKFLOW_STATE_TYPES.STARTED;
};
exports.isDone = column => {
  return column && column.type === Constants.WORKFLOW_STATE_TYPES.DONE;
};
let index = 0;
exports.renderStateIconFromStateType = (type = 'unstarted', width = 18) => {
  return View.renderComponentDelayed({
    componentKey: `story-status-icon-${index++}`,
    component: _jsx(DeprecatedIconAdapter, {
      width: width,
      children: exports.findStatusIconFromType(type)
    })
  }).html;
};
exports.getReactIcon = (column, width = 25) => {
  return _jsx(DeprecatedIconAdapter, {
    width: width,
    children: exports.findStatusIconFromType(column?.type ?? 'unstarted')
  });
};
exports.isOnlyOneOfType = (column, workflow) => {
  workflow = workflow || WorkflowModel.getActive();
  return column && exports.filter({
    type: column.type,
    workflow_id: workflow.id
  }).length === 1;
};
exports.isSafeToDelete = (column, workflow) => {
  workflow = workflow || WorkflowModel.getActive();
  return column && workflow && (!exports.isOnlyOneOfType(column, workflow) || exports.isBacklog(column)) && column.num_stories === 0 && column.num_story_templates === 0 && workflow.default_state_id !== column.id;
};
exports.getTeamFromColumn = column => {
  if (!column) return null;
  const workflow = WorkflowModel.getById(column.workflow_id);
  if (!workflow) {
    Log.error(new Error('Workflow not found'), {
      column
    });
  }
  return workflow && TeamModel.getById(workflow.team_id);
};
exports.normalize = column => {
  column.active = column.active === false ? false : true;
  column.sortable = column.sortable === false ? false : true;
  column.class_name = 'column-' + column.id;
  column.onDragIn = () => {
    const update = {
      workflow_state_id: column.id
    };
    return update;
  };
};
exports.getElement = column => {
  return $('#columns .' + column.class_name);
};
exports.allDone = () => {
  const activeWorkflow = WorkflowModel.getActive();
  return exports.filter(column => {
    return exports.isDone(column) && column.workflow_id === activeWorkflow.id;
  });
};
exports.allExceptDone = () => {
  const activeWorkflow = WorkflowModel.getActive();
  return exports.filter(column => {
    return !exports.isDone(column) && column.workflow_id === activeWorkflow.id;
  });
};
exports.allExceptDoneFromAllWorkflows = () => {
  return exports.filter(column => {
    return !exports.isDone(column);
  });
};
exports.allFromActiveWorkflow = () => {
  const workflow = WorkflowModel.getActive();
  return exports.allFromWorkflow(workflow);
};
exports.allFromWorkflow = workflow => {
  const types = exports.getStateTypes();
  return exports.filter(column => {
    return column.workflow_id === workflow.id;
  }).sort((a, b) => {
    if (a.type === b.type) return a.position - b.position;
    return types.findIndex(t => t.value === a.type) - types.findIndex(t => t.value === b.type);
  });
};
exports.allActiveFromActiveWorkflow = () => {
  return exports.allFromActiveWorkflow().filter(column => {
    return column.active;
  });
};
exports.allActiveBacklogFromActiveWorkflow = () => {
  return exports.allFromActiveWorkflow().filter(column => column.active && exports.isBacklog(column));
};
exports.allWithStoriesFromActiveWorkflow = () => {
  return exports.allFromActiveWorkflow().filter(column => {
    return column.storyCount > 0;
  });
};
exports.allInterleaved = columns => {
  columns = columns || exports.all();
  let sortedColumns = [];
  const teams = TeamModel.all();
  Object.values(Constants.WORKFLOW_STATE_TYPES).forEach(type => {
    const arrays = [];
    teams.forEach(team => {
      arrays.push(_.filter(columns, column => {
        return column.type === type && team.workflow.id === column.workflow_id;
      }));
    });
    sortedColumns = sortedColumns.concat(Utils.interleave(arrays));
  });
  return sortedColumns;
};
exports.getTeamName = column => {
  const workflow = WorkflowModel.getById(column.workflow_id);
  const team = TeamModel.getById(workflow.team_id);
  return team.name;
};
exports.firstFromActiveWorkflow = () => {
  return _.head(exports.allFromActiveWorkflow());
};
exports.firstActiveFromActiveWorkflow = () => {
  return _.head(exports.allActiveFromActiveWorkflow());
};
exports.sortByPosition = () => {
  exports.db.sort((a, b) => {
    const positionDifference = a.position - b.position;
    if (positionDifference === 0) {
      return a.workflow_id - b.workflow_id;
    }
    return positionDifference;
  });
};
exports.saveNew = (workflow, data, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/workflows/' + workflow.id + '/states', {
    data,
    onComplete: res => {
      if (!res.error) {
        const currentLastStateOfType = _.last(exports.filter({
          type: data.type,
          workflow_id: workflow.id
        }));
        let updates;
        if (currentLastStateOfType) {
          updates = {
            after_id: currentLastStateOfType.id
          };
        } else {
          // New workflows have first item at position: 1 by default.
          // But if user has manually reordered their states,
          // the first state might have position: 0, so we look for that first.
          const before = exports.get({
            workflow_id: workflow.id,
            position: 0
          }) || exports.get({
            workflow_id: workflow.id,
            position: 1
          });
          if (before) {
            updates = {
              before_id: before.id
            };
          }
        }
        exports.saveUpdate(workflow, res, updates ?? {}, (err, res) => {
          if (!err) {
            // workflowUpdated event is already triggered by saveUpdate fn.
            callback(null, res);
          } else {
            exports.defaultErrorHandler({
              error: err
            }, callback);
          }
        });
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.saveUpdate = (workflow, state, updates, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/workflows/' + workflow.id + '/states/' + state.id, {
    data: updates,
    onComplete: res => {
      if (!res.error) {
        WorkflowModel.fetchAll(() => {
          Event.trigger('workflowUpdated');
          callback(null, res);
        });
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.saveDelete = (workflow, state, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.delete('/api/private/workflows/' + workflow.id + '/states/' + state.id, {
    onComplete: res => {
      if (!res.error) {
        WorkflowModel.fetchAll(() => {
          Event.trigger('workflowUpdated');
          callback(null);
        });
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.fuzzyMatch = str => {
  str = (str || '').toLowerCase();
  return exports.filter(column => {
    if (column.id === Number.parseInt(str, 10) || column.name.toLowerCase().indexOf(str) !== -1) {
      return true;
    }
    return false;
  });
};
exports.setActiveColumnsForSpace = space => {
  const spaceColumnIds = space.data.Column;
  const initialColumns = exports.allFromActiveWorkflow();
  const spaceDataIsIrrelevant = SpaceModel.spaceDataRelatesToAnotherTeam(initialColumns, spaceColumnIds);
  exports.each(column => {
    column.active = spaceDataIsIrrelevant || SpaceModel.isModelInSpace(space, 'Column', column);
  });
};
export { exports as default };