import { attachClosestEdge, extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import { draggable, dropTargetForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { pointerOutsideOfPreview } from '@atlaskit/pragmatic-drag-and-drop/element/pointer-outside-of-preview';
import { setCustomNativeDragPreview } from '@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview';
import { useEffect } from 'react';
import { useSubTaskDragState } from '../SubTaskDragStateContext/useSubTaskDragState';
import { isDragDisallowed, isSubtaskData, subtaskDataKey } from '../utils';
function getSubtaskDataForDnD(subtask) {
  return {
    [subtaskDataKey]: true,
    subtaskId: subtask.id
  };
}
export function useSubTaskDraggable(subtask, listItemRef, dragHandleRef) {
  const {
    dragState,
    subTaskIds,
    setDragState
  } = useSubTaskDragState();
  const {
    id: subTaskId
  } = subtask;
  useEffect(() => {
    if (!listItemRef.current || !dragHandleRef.current) return;
    const listItemElement = listItemRef.current;
    const dragHandleElement = dragHandleRef.current;
    return combine(draggable({
      element: listItemElement,
      dragHandle: dragHandleElement,
      canDrag() {
        return subTaskIds.length > 1;
      },
      getInitialData() {
        return getSubtaskDataForDnD(subtask);
      },
      onGenerateDragPreview({
        nativeSetDragImage
      }) {
        setCustomNativeDragPreview({
          nativeSetDragImage,
          getOffset: pointerOutsideOfPreview({
            x: '16px',
            y: '8px'
          }),
          render({
            container
          }) {
            setDragState(currentState => ({
              ...currentState,
              [subTaskId]: {
                type: 'preview',
                container
              }
            }));
          }
        });
      },
      onDragStart() {
        setDragState(currentState => ({
          ...currentState,
          [subTaskId]: {
            type: 'is-dragging'
          }
        }));
      },
      onDrop() {
        setDragState(currentState => ({
          ...currentState,
          [subTaskId]: {
            type: 'idle'
          }
        }));
      }
    }), dropTargetForElements({
      element: listItemElement,
      canDrop({
        source,
        input,
        element: targetElement
      }) {
        // Calculate the target's edge based on input coordinates
        const targetData = attachClosestEdge({}, {
          element: targetElement,
          input,
          allowedEdges: ['top', 'bottom']
        });
        const targetEdge = extractClosestEdge(targetData);
        const targetSubtaskId = targetElement.getAttribute('data-subtask-id');
        const indexOfSource = subTaskIds.findIndex(subtaskId => subtaskId === source.data.subtaskId);
        const indexOfTarget = subTaskIds.findIndex(subtaskId => subtaskId === targetSubtaskId);
        const disallowDrag = isDragDisallowed(indexOfSource, indexOfTarget, targetEdge);
        if (source.element === listItemElement || disallowDrag) return false;
        return isSubtaskData(source.data);
      },
      getData({
        input
      }) {
        const data = getSubtaskDataForDnD(subtask);
        return attachClosestEdge(data, {
          element: listItemElement,
          input,
          allowedEdges: ['top', 'bottom']
        });
      },
      onDragEnter({
        self
      }) {
        const closestEdge = extractClosestEdge(self.data);
        setDragState(currentState => ({
          ...currentState,
          [subTaskId]: {
            type: 'is-dragging-over',
            closestEdge
          }
        }));
      },
      onDrag({
        self
      }) {
        const closestEdge = extractClosestEdge(self.data);
        setDragState(currentState => {
          const currentSubTaskDragState = currentState[subTaskId];
          if (currentSubTaskDragState.type === 'is-dragging-over' && currentSubTaskDragState.closestEdge === closestEdge) {
            return currentState;
          }
          return {
            ...currentState,
            [subTaskId]: {
              type: 'is-dragging-over',
              closestEdge
            }
          };
        });
      },
      onDragLeave() {
        setDragState(currentState => ({
          ...currentState,
          [subTaskId]: {
            type: 'idle'
          }
        }));
      },
      onDrop() {
        setDragState(currentState => ({
          ...currentState,
          [subTaskId]: {
            type: 'idle'
          }
        }));
      }
    }));
  }, [subtask, subTaskIds, dragHandleRef.current, listItemRef.current, setDragState, subTaskId]);
  return dragState[subTaskId];
}