import { StatusIcon, getTokens } from '@useshortcut/shapes-ds';
import { WORKFLOW_STATE_TYPES } from '../../constants';
import { DeprecatedIconAdapter } from '../Icons';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const ICON = {
  [WORKFLOW_STATE_TYPES.BACKLOG]: 'Backlog',
  [WORKFLOW_STATE_TYPES.DONE]: 'Done',
  [WORKFLOW_STATE_TYPES.STARTED]: 'Started',
  [WORKFLOW_STATE_TYPES.UNSTARTED]: 'Unstarted'
};
const DEFAULT_ICON_WIDTH = getTokens().space.space4;
export const StateIcon = ({
  type,
  width = DEFAULT_ICON_WIDTH
}) => _jsx(DeprecatedIconAdapter, {
  width: width,
  children: _jsx(StatusIcon, {
    icon: ICON[type]
  })
});
StateIcon.displayName = "StateIcon";