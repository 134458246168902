
import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _CfS = Caveman.forceStr; var str = '';str += '<div class="value">'+'  ';if (d.name) {str += '    <strong>';str += (d.name);str += '</strong>'+'  ';}str += '  ';if (d.added) {str += '    ';if (d.isMention) {str += '      mentioned you in a comment'+'    ';} else if (d.isGroupMention) {str += '      mentioned your group in a comment'+'    ';} else {str += '      commented on'+'    ';}str += '  ';} else {str += '    deleted a comment from'+'  ';}str += '  ';str += (d.story);str += '</div>'+''+'<div class="comment ';if (!d.added) {str += 'deleted-comment ';}str += 'markdown-formatted">'+'  ';str += '  ';if (d.added) {str += '    ';str += (d.comment);str += '  ';}str += '</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
