import { useEffect, useReducer, useState } from 'react';
import { fetchStoriesForEpic, getById as getEpicById, getStories } from 'data/entity/epic';
import { all as getAllStories, getById as getStoryById } from 'data/entity/story';
import { EVENT_TYPES, useEventListener } from './collectionizeToApolloMessageBus';
const getIterationStories = iterationId => {
  return getAllStories().filter(story => {
    return story.iteration_id === iterationId;
  });
};

/**
 * Fetches the stories for the given entity and returns the number of stories that are blockers and blocked.
 *
 * This is not supposed to be used for a long time, ideally, we would have those as part of the Epic's data in GraphQL.
 */
export function useStoriesBlockingStats(entity, entityId) {
  const [isReady, setIsReady] = useState(false);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  useEventListener(EVENT_TYPES.STORY_CREATED, forceUpdate);
  useEventListener(EVENT_TYPES.STORY_UPDATED, forceUpdate);
  useEffect(() => {
    if (entity !== 'epic') {
      setIsReady(true);
      return;
    }
    async function fetchStories() {
      if (!entityId) {
        return;
      }
      await fetchStoriesForEpic(entityId);
      setIsReady(true);
    }
    fetchStories();
  }, [entity, entityId]);
  if (!isReady) {
    return {
      loading: true,
      blockerCount: 0,
      blockedCount: 0,
      forceUpdate
    };
  }
  const stories = (() => {
    if (entity === 'epic') {
      return getStories({
        epic: getEpicById(entityId)
      });
    }
    if (entity === 'iteration') {
      return getIterationStories(entityId);
    }
    return [];
  })();
  return stories.reduce((acc, story) => {
    if (!story.archived && !story.completed) {
      if (story.story_links.some(link => {
        if (link.type === 'subject' && link.verb === 'blocks') {
          const objectStory = getStoryById(link.object_id);
          if (entity === 'epic' && objectStory?.epic_id === entityId) {
            return true;
          }
          if (entity === 'iteration' && objectStory?.iteration_id === entityId) {
            return true;
          }
        }
        return false;
      })) {
        acc.blockerCount += 1;
      }
      if (story.story_links.some(link => {
        if (link.type === 'object' && link.verb === 'blocks') {
          const subjectStory = getStoryById(link.subject_id);
          if (!subjectStory) {
            return false;
          }
          if (entity === 'epic' && subjectStory.epic_id !== entityId) {
            return false;
          }
          if (entity === 'iteration' && subjectStory.iteration_id !== entityId) {
            return false;
          }
          return subjectStory.archived === false && subjectStory.completed === false;
        }
        return false;
      })) {
        acc.blockedCount += 1;
      }
    }
    return acc;
  }, {
    loading: false,
    blockerCount: 0,
    blockedCount: 0,
    forceUpdate
  });
}