import { FEATURE_TOGGLES, useVariation } from 'utils/launch-darkly';
import { useOptInToggleState } from 'components/stories/OptInToggleButton/useOptInToggleState';
export const useShouldReplaceLegacyStoriesPage = () => {
  const toggleType = useVariation(FEATURE_TOGGLES.STORIES_PAGE_V2_REPLACE_STORIES_PAGE).value;
  const {
    value,
    loading
  } = useOptInToggleState();
  if (toggleType === 'user-enabled') return {
    value,
    loading
  };
  return {
    value: toggleType === 'enabled',
    loading: false
  };
};