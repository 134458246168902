import { useCallback } from 'react';
import { useDocumentVisibility } from '@clubhouse/shared/hooks/useDocumentVisibility';
import { triggerDataLayerResetEvent, triggerDataLayerUpdateEvent } from 'utils/updates';
import { useBroadcastChannel } from './useBroadcastChannel';
import { useDataLayerLegacyUpdateEvent } from './useDataLayerLegacyUpdateEvent';
import { Fragment as _Fragment, jsx as _jsx } from "@emotion/react/jsx-runtime";
export const LegacyUpdatesBroadcaster = ({
  children
}) => {
  const isActive = useDocumentVisibility() === 'visible';
  const onMessage = useCallback(({
    type,
    data,
    dbTime
  }) => {
    if (type === 'update') triggerDataLayerUpdateEvent({
      data,
      dbTime
    });else if (type === 'reset') triggerDataLayerResetEvent();
  }, []);
  const sendToBackgroundTabs = useBroadcastChannel({
    name: 'shortcut-sse-legacy',
    isBroadcaster: isActive,
    onMessage
  });
  useDataLayerLegacyUpdateEvent(data => {
    sendToBackgroundTabs(data);
  });
  return _jsx(_Fragment, {
    children: children
  });
};