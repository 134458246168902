import { PAGE_NAMES, getCurrentPathname, getMatchForPath } from 'utils/navigation';
import { useShouldReplaceLegacyStoriesPage } from 'utils/useShouldReplaceLegacyStoriesPage';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const StoriesV3Router = ({
  enabled,
  disabled
}) => {
  const {
    value: shouldReplaceLegacyStoriesPage,
    loading
  } = useShouldReplaceLegacyStoriesPage();
  const pageName = getMatchForPath({
    path: getCurrentPathname()
  }).pageName;
  if (loading) return null;
  switch (pageName) {
    case PAGE_NAMES.STORIES:
      {
        const isEnabled = Boolean(shouldReplaceLegacyStoriesPage);
        return _jsx(Condition, {
          isEnabled: isEnabled,
          enabled: enabled,
          disabled: disabled
        });
      }
    case PAGE_NAMES.STORY:
      {
        const isEnabled = Boolean(shouldReplaceLegacyStoriesPage);
        return _jsx(Condition, {
          isEnabled: isEnabled,
          enabled: enabled,
          disabled: disabled
        });
      }
  }
  return null;
};
const Condition = ({
  isEnabled,
  enabled,
  disabled
}) => {
  return isEnabled ? enabled : disabled;
};