import * as StoryHistoryBranchTemplate from 'app/client/core/views/templates/storyHistory/storyHistoryBranch.html';
import * as StoryHistoryCommitTemplate from 'app/client/core/views/templates/storyHistory/storyHistoryCommit.html';
import * as StoryHistoryItemTemplate from 'app/client/core/views/templates/storyHistory/storyHistoryItem.html';
import * as StoryHistoryLabelsTemplate from 'app/client/core/views/templates/storyHistory/storyHistoryLabels.html';
import * as StoryHistoryPullRequestTemplate from 'app/client/core/views/templates/storyHistory/storyHistoryPullRequest.html';
import * as StoryHistoryRequesterTemplate from 'app/client/core/views/templates/storyHistory/storyHistoryRequester.html';
import * as StoryHistoryStoryTypeTemplate from 'app/client/core/views/templates/storyHistory/storyHistoryStoryType.html';
import { getLabelById } from 'data/entity/label';
import NotificationsController from './notifications';
import ActivityModel from '../models/activity';
import LabelModel from '../models/label';
import ProfileModel from '../models/profile';
import StoryModel from '../models/story';
import StoryHistoryModel from '../models/storyHistory';
import Constants from '../modules/constants';
import Format from '../modules/format';
import Utils from '../modules/utils';
import { renderComponentToString } from 'utils/helpers';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon/SizedIcon';
import _ from 'lodash';
import moment from 'moment';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
exports.mapActivitytoTemplates = story => {
  return _.compact(_.map(StoryHistoryModel.getActivity(story), change => {
    const profile = ProfileModel.getAllDetailsById(change.member_id) || {};
    const name = Format.fullName(profile, undefined, change.automation_id ? 'Automations' : undefined);
    const content = _formatTemplateContent(change);
    const d = {
      changed_at: moment(change.changed_at).format(Constants.SHORT_DATE_TIME_FORMAT),
      profile,
      name,
      html: content
    };
    if (change.automation_id) {
      d.automation = true;
    }
    return content ? StoryHistoryItemTemplate.render(d) : '';
  }));
};
exports.renderChevronIcon = () => {
  return renderComponentToString(_jsx(SizedIcon, {
    icon: "ChevronDown",
    size: 16
  }));
};
function _formatTemplateContent(change) {
  const htmlSegments = _.compact(_.map(change.actions, action => {
    const type = action.action;
    const changes = ActivityModel.getChanges(change, action);
    const ref = ActivityModel.getReference(change, action.id, StoryHistoryModel);
    const refType = ref.entity_type;
    let text = '';
    if (type === 'update') {
      if (changes.workflow_state_id) {
        text = NotificationsController.formatStoryWorkflowStateMove({
          change,
          action,
          historyModel: StoryHistoryModel
        });
      } else if (changes.epic_id) {
        text = NotificationsController.formatStoryEpicUpdate({
          change,
          action,
          historyModel: StoryHistoryModel
        });
      } else if (changes.project_id) {
        text = NotificationsController.formatStoryProjectUpdate({
          change,
          action,
          historyModel: StoryHistoryModel
        });
      } else if (changes.custom_field_value_ids) {
        text = NotificationsController.formatStoryCustomFieldValuesUpdate({
          change,
          action
        });
      } else if (changes.name) {
        text = NotificationsController.formatStoryName({
          change,
          action,
          modelName: 'StoryHistory'
        });
      } else if (changes.description) {
        if (refType === 'story') {
          text = NotificationsController.formatStoryDescription({
            change,
            action,
            modelName: 'StoryHistory'
          });
        } else if (refType === 'story-task') {
          text = NotificationsController.formatTaskDescription(change, changes.description);
        }
      } else if (changes.type) {
        text = _formatStoryType(changes.type);
      } else if (changes.deadline) {
        text = NotificationsController.formatStoryDeadline(changes.deadline);
      } else if (changes.requested_by_id) {
        text = _formatRequester(change, action);
      } else if (changes.archived) {
        text = NotificationsController.formatStoryArchived(changes.archived);
      } else if (changes.estimate) {
        text = NotificationsController.formatStoryEstimate(changes.estimate);
      } else if (changes.complete) {
        if (refType === 'story-task') {
          text = NotificationsController.formatTaskComplete({
            change,
            action,
            historyModel: StoryHistoryModel
          });
        }
      } else if (changes.blocked) {
        // has new/old keys
        // text = changes.blocked.new ? 'blocked' : 'unblocked';
        // TODO dropping for the moment as a new addition (seems redundant with other actions)
      } else if (changes.task_ids) {
        // Dropping as handled in task create/delete
      } else if (changes.label_ids) {
        text = _formatLabels(change, action);
      } else if (changes.owner_ids) {
        if (refType === 'story-task') {
          text = NotificationsController.formatTaskOwners({
            change,
            action,
            historyModel: StoryHistoryModel
          });
        } else {
          text = NotificationsController.formatStoryUsers({
            change,
            changeMap: changes.owner_ids,
            pluralMap: {
              multiple: 'owners',
              singular: 'an owner'
            }
          });
        }
      } else if (changes.follower_ids) {
        text = NotificationsController.formatStoryUsers({
          change,
          changeMap: changes.follower_ids,
          pluralMap: {
            multiple: 'followers',
            singular: 'a follower'
          }
        });
      } else if (changes.branch_ids) {
        // TODO dropping for the moment as a new addition (seems redundant with other actions)
      } else if (changes.commit_ids) {
        text = _formatCommitAddRemove(change, action);
      } else if (changes.subject_story_link_ids) {
        // has adds/removes arrays
        // TODO dropping for the moment as a new addition (seems redundant with other actions)
      } else if (changes.object_story_link_ids) {
        // has adds/removes arrays
        // TODO dropping for the moment as a new addition (seems redundant with other actions)
      } else if (changes.iteration_id) {
        text = NotificationsController.formatStoryIterationUpdate({
          change,
          action,
          historyModel: StoryHistoryModel
        });
      } else if (changes.group_id) {
        text = NotificationsController.formatStoryGroupUpdate({
          change: changes.group_id,
          action
        });
      }
    } else if (type === 'create') {
      if (action.id === change.story_id) {
        text = NotificationsController.formatStoryCreateDelete(change, ref, type);
      } else if (refType === 'story-task') {
        text = NotificationsController.formatTaskCreateDelete({
          change,
          task: ref,
          type
        });
      } else if (refType === 'story-link') {
        text = NotificationsController.formatStoryLinkCreateDelete({
          change,
          storyLink: ref,
          type,
          historyModel: StoryHistoryModel
        });
      } else if (refType === 'branch') {
        text = _formatBranchCreateDelete(change, ref, type);
      } else if (refType === 'story') {
        // TODO: Add a more detailed history such as creating a new story as blocked to this story,
        // for the moment we're just dropping this until state is carried on or we implement a lookback.
      }
    } else if (type === 'open' || type === 'close') {
      text = _formatPullRequestOpenClose(ref, type);
    } else if (type === 'merge') {
      // text = _formatMerge(ref);
    } else if (type === 'delete') {
      if (!refType) {
        // TODO: Check if this is possible after change to v1
        // dropping if a reference doesn't exist
      } else if (refType === 'story-link') {
        text = NotificationsController.formatStoryLinkCreateDelete({
          change,
          storyLink: ref,
          type,
          historyModel: StoryHistoryModel
        });
      } else if (refType === 'story-task') {
        text = NotificationsController.formatTaskCreateDelete({
          change,
          task: ref,
          type
        });
      }
    }
    return text;
  }));
  return Utils.grammaticalJoin(htmlSegments);
}

// Templates

function _formatBranchCreateDelete(change, branch, action) {
  const isAdded = action === 'create';
  const isAssociated = !!_.find(_.get(StoryModel.getById(change.story_id), 'branches', []), {
    id: branch.id
  });
  return StoryHistoryBranchTemplate.render({
    added: isAdded,
    branch,
    showRestore: !isAdded && !isAssociated
  });
}
function _formatPullRequestOpenClose(pullRequest, action) {
  return StoryHistoryPullRequestTemplate.render({
    open: action === 'open',
    pullRequest
  });
}
function _formatCommitAddRemove(change, action) {
  const commits = ActivityModel.getChanges(change, action).commit_ids;
  const isAdded = !!commits.adds;
  const commitID = _.head(commits.adds || commits.removes);
  const commit = ActivityModel.getReference(change, commitID, StoryHistoryModel);
  const isAssociated = !!_.find(_.get(StoryModel.getById(change.story_id), 'commits', []), {
    id: commitID
  });
  return StoryHistoryCommitTemplate.render({
    added: isAdded,
    commit,
    showRestore: !isAdded && !isAssociated
  });
}

// function _formatMerge(changeRef) {
//   // TODO Add branch name that was merged once available.
//   return StoryHistoryMergeTemplate.render(changeRef);
// }

function _formatLabels(change, action) {
  const labels = ActivityModel.getChanges(change, action).label_ids;
  const isAdded = !!labels.adds;
  const labelID = _.head(labels.adds || labels.removes);
  const label = getLabelById(labelID) || ActivityModel.getReference(change, labelID, StoryHistoryModel);
  return StoryHistoryLabelsTemplate.render({
    added: isAdded,
    label: {
      color: label.color || LabelModel.getDefaultColor(),
      name: Format.sanitizeAndEmojify(label.name)
    }
  });
}
function _formatStoryType(types) {
  return StoryHistoryStoryTypeTemplate.render({
    old: _.upperFirst(types.old),
    new: _.upperFirst(types.new)
  });
}
function _formatRequester(change, action) {
  const requester = ActivityModel.getChanges(change, action).requested_by_id;
  const user = ProfileModel.getAllDetailsById(requester.new || requester.old);
  const currentUser = ProfileModel.getAllDetailsById(change.member_id);
  return StoryHistoryRequesterTemplate.render({
    name: Format.fullName(user, currentUser)
  });
}
export { exports as default };