import { useRef } from 'react';
import { useEffectOnce } from 'react-use';
import { isNew } from 'data/entity/story';
import { renderStoryLinks } from 'utils/addNew';
import { useContextMenuForCaveman } from 'utils/contextMenus/useContextMenuForCaveman';
import { updateStoryLinkComponent } from 'utils/storyLink';
import { SEARCH_ENTITY } from '../../searchResults/types';
import { AddRelationshipPopover } from '../AddRelationshipDialog';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import { Button } from '@clubhouse/shared/components/Button';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export function StoryRelationships({
  story
}) {
  // Toggle visibility using CSS for now since some tests rely on there
  // always being a `.story-relationships` selector available.
  // TODO: Replace with conditional null render
  const display = !story.story_links.length ? 'none' : '';
  const storyAttachmentsRef = useRef(null);
  useEffectOnce(() => {
    if (isNew(story)) {
      renderStoryLinks();
    } else {
      updateStoryLinkComponent(story, storyAttachmentsRef.current);
    }
  });
  const contextMenuProps = useContextMenuForCaveman();
  return _jsxs("div", {
    ref: storyAttachmentsRef,
    className: "story-attachment",
    "data-type": "relationship",
    style: {
      display
    },
    children: [_jsx("div", {
      className: "section-head",
      children: "Story Relationships"
    }), _jsx("div", {
      ...contextMenuProps,
      className: "story-relationships",
      "data-controller": story.id ? 'StoryLink' : 'AddNewStory'
    }), _jsx(AddRelationshipPopover, {
      resultTypes: [SEARCH_ENTITY.STORY],
      story: story,
      children: ({
        setVisible
      }) => {
        return _jsx("div", {
          className: "add-story-link-button",
          children: _jsx(Button, {
            kind: Button.KIND.TERTIARY,
            size: Button.SIZE.SMALL,
            fit: Button.FIT.MEDIUM,
            className: "add-story-link",
            onClick: () => setVisible(true),
            IconLeft: () => _jsx(SizedIcon, {
              icon: "Add",
              size: 16
            }),
            "data-tabindex": true,
            children: "Add Relationship"
          })
        });
      }
    })]
  });
}
StoryRelationships.displayName = "StoryRelationships";