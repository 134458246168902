import { useEffect } from 'react';
import { insertControls } from 'utils/codeBlock';
import { renderMermaid } from 'utils/mermaid';
import { highlightElement } from 'utils/syntaxHighlight';
async function renderMermaidWithCallback(element, callback, config) {
  await renderMermaid(element, config);
  callback?.();
}
export const useCodeBlock = (el, {
  hideControls,
  noHighlight,
  mermaidCallback,
  mermaidConfig
} = {}) => {
  useEffect(() => {
    if (el) {
      if (!hideControls) {
        insertControls(el);
      }
      if (!noHighlight) {
        highlightElement(el);
      }
      renderMermaidWithCallback(el, mermaidCallback, mermaidConfig);
    }
  }, [el, hideControls, noHighlight, mermaidCallback, mermaidConfig]);
};