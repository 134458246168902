import { isParseableUrl } from '@clubhouse/shared/utils/url';
import { LinkMenu } from '../LinkMenu';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const URL_PREFIX = /^https?:\/\//;
export const LinksAutocomplete = {
  hasPrefix: false,
  test: text => false && !!text && URL_PREFIX.test(text) && isParseableUrl(text),
  // Disable until we can improve
  Component: ({
    triggerRef,
    fragment
  }) => _jsx(LinkMenu, {
    triggerRef: triggerRef,
    fragment: fragment
  })
};