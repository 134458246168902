import { nanoid } from 'nanoid';
import { assign, setup } from 'xstate';
import { convertActiveTabForMonitoring } from '../monitoring/convertActiveTabForMonitoring';
import { convertSearchOperatorForMonitoring } from '../monitoring/convertSearchOperatorForMonitoring';
import { convertTypenameToEntityType } from '../monitoring/convertTypenameToEntityType';
import { logQuickSearchInteraction } from '../monitoring/logQuickSearchInteraction';
export const quickSearchSessionMachine = setup({
  types: {
    context: {},
    events: {}
  },
  guards: {
    isTextEmpty: ({
      context,
      event
    }) => {
      if (event.type === 'INPUT_TEXT') {
        return event.text.trim().length === 0;
      }
      return !context.inputText || context.inputText.trim().length === 0;
    },
    isTextNotEmpty: ({
      event
    }) => event.type === 'INPUT_TEXT' && event.text.trim().length > 0
  },
  actions: {
    startNewSession: assign({
      sessionStartTimestamp: () => new Date(),
      sessionId: () => nanoid(),
      selectedEntitiesCount: 0,
      results: null
    }),
    updateInputText: assign({
      inputText: ({
        event
      }) => {
        if (event.type !== 'INPUT_TEXT') {
          return null;
        }
        return event.text;
      },
      results: null
    }),
    logInteraction: ({
      context,
      event,
      self
    }) => {
      const sessionData = {
        search_session_id: context.sessionId,
        search_session_start: context.sessionStartTimestamp,
        search_session_seconds: Math.round((new Date().getTime() - (context.sessionStartTimestamp ?? new Date()).getTime()) / 1000)
      };
      if (event.type === 'OPEN_SEARCH') {
        logQuickSearchInteraction({
          action_context: 'quick search opened',
          interaction_type: 'search-panel-opened',
          ...sessionData,
          is_recently_viewed_selected: !context.inputText || context.inputText.trim().length === 0
        });
      }
      if (event.type === 'CLOSE_SEARCH') {
        logQuickSearchInteraction({
          action_context: 'quick search closed',
          interaction_type: 'search-panel-closed',
          ...sessionData,
          tab_selected: convertActiveTabForMonitoring(context.selectedTab),
          user_query_text_input: context.inputText ?? undefined,
          is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
        });
      }
      if (event.type === 'INTERACTION') {
        if (event.interactionType === 'entity-selected') {
          logQuickSearchInteraction({
            action_context: 'quick search entity selected',
            interaction_type: event.interactionType,
            ...sessionData,
            selection_entity_type: convertTypenameToEntityType(event.entity.__typename),
            selection_entity_public_id: event.entity.__typename === 'Doc' ? event.entity.urlId : event.entity.publicId?.toString(),
            selection_results_index: event.index,
            selection_click_order: context.selectedEntitiesCount,
            total_results_returned: context.results?.total ?? 0,
            total_stories_returned: context.results?.stories ?? 0,
            total_epics_returned: context.results?.epics ?? 0,
            total_objectives_returned: context.results?.objectives ?? 0,
            total_iterations_returned: context.results?.iterations ?? 0,
            total_docs_returned: context.results?.docs ?? 0,
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'recent-searches-viewed') {
          logQuickSearchInteraction({
            action_context: 'quick search recent searches viewed',
            interaction_type: event.interactionType,
            ...sessionData,
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'recent-searches-applied') {
          logQuickSearchInteraction({
            action_context: 'quick search recent searches applied',
            interaction_type: event.interactionType,
            ...sessionData,
            recent_search_queries: event.recentSearches,
            user_query_text_input: event.selection ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'expand-operators') {
          logQuickSearchInteraction({
            action_context: 'quick search operators expanded',
            interaction_type: event.interactionType,
            ...sessionData,
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'view-all-search-operators') {
          logQuickSearchInteraction({
            action_context: 'quick search view all search operators',
            interaction_type: event.interactionType,
            ...sessionData,
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'search-operator-user-input') {
          logQuickSearchInteraction({
            action_context: 'quick search search operator user input',
            interaction_type: event.interactionType,
            ...sessionData,
            search_operator_user_input: event.operatorUserInput,
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'search-operator-selected') {
          logQuickSearchInteraction({
            action_context: 'quick search search operator selected',
            interaction_type: event.interactionType,
            ...sessionData,
            search_operator_selected: convertSearchOperatorForMonitoring(event.operator),
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
        if (event.interactionType === 'advanced-search-view-stories-in-page') {
          logQuickSearchInteraction({
            action_context: 'quick search advanced search view stories in page',
            interaction_type: event.interactionType,
            ...sessionData,
            user_query_text_input: context.inputText ?? undefined,
            is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
          });
        }
      }
      if (event.type === 'SELECT_TAB') {
        logQuickSearchInteraction({
          action_context: 'quick search entity tab selected',
          interaction_type: 'entity-tab-selected',
          ...sessionData,
          tab_selected: convertActiveTabForMonitoring(event.tab),
          user_query_text_input: context.inputText ?? undefined,
          is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
        });
      }
      if (event.type === 'INPUT_TEXT' && !self.getSnapshot().matches('closed')) {
        logQuickSearchInteraction({
          action_context: 'quick search text input',
          interaction_type: 'user-query-text-input',
          ...sessionData,
          user_query_text_input: event.text,
          is_recently_viewed_selected: self.getSnapshot().matches('recentlyViewed')
        });
      }
    },
    maybeBumpSelectedEntitiesCount: assign({
      selectedEntitiesCount: ({
        context,
        event
      }) => {
        if (event.type === 'INTERACTION' && event.interactionType === 'entity-selected') {
          return context.selectedEntitiesCount + 1;
        }
        return context.selectedEntitiesCount;
      }
    }),
    setResults: assign({
      results: ({
        event
      }) => {
        if (event.type !== 'RESULTS') {
          return null;
        }
        return event.results;
      }
    }),
    selectTab: assign({
      selectedTab: ({
        event
      }) => {
        // We want to reset the tab if we switch between Recently Viewed <> Search Results
        if (['OPEN_SEARCH', 'INPUT_TEXT'].includes(event.type)) {
          return 'story';
        }
        if (event.type !== 'SELECT_TAB') {
          return null;
        }
        return event.tab;
      }
    })
  }
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QEcCuBLAxgawLSzAEMAnTAC3zlnQHsA7AYgEkA5ABQFUAVAfS4FEAGlwDaABgC6iUAAca1AC6060kAA9EAVgCcYgHTaAbABYAHIYBMAZgsBGW6atWANCACeiWxYC+312iw8AhJySlhqej1MABt5SAYAeTZ+Fh4AZX4AQQAlAGEACXEpJBA5RWVVDQRbAHZjPQsaq2NjK20nRwtDTVcPBFNWvVszTU1DbTs7Nt9-DBxKEIoCcOUo2IIIROTUjJyCkVti2Xl0JXpKzzqGppa2jptu3sQrTSs9Qxsaw0MaizErBy2GYgALzYKkJZUVbEMCYMB0BTRNwANXQYAA7vFWJxeAJhEVVGVThUSlU6vVjIZTDVdJovhYxhYnghfhYhqYLMY-mIamJjLYTMDQUEiBCwhE6HoYXCEUjURj4rkADIJDLpLJ5QqSQknM4qUmICwTBpciwWRzabQcrnMuqGd7aTT80amCYM0xCuYixbi6Gw+GIlFozGbVgCbKZXJcJgJFgEkpEvUXBCU0x6GqaMRibkMzQDW1WfTjV41GqmWwArqaT2BBZi5YSqX+2VBhWbbL8NIcJVcNLx47lc4GhCvNNO8ZidpZ2w87TMj71Tl2dofQxeCs1sGi0INv0ywPykMMDJK-hRviZABC-dKupJoCqmnNelMmlsOkMPNfLVMzLf+kcPNtGMOo2gras-BBL06x3KFInBchsjgVBogUWBmHYbg+CEURtQTO8hwfRAvk0PQXgFFpxksTkencRABlIvNqQcIwuiXTdvXrODJQQsgkNgFC0IYZVVX4dU9i1I5b0HfUiP6LkXx0VomnLdovmZP5tAMMRLFqECqRsPMOJgyEVng7c+OQ1D0LDfgIyjGM4zwgdiUI9RPGzLSrB+Bk+ULP4xnnHkGiNbzgO0FTjN431zMWfjBPQjsux7PtnOk1zZPc6pJy0iivFddoQNeP8Z3TekahnGdjEnKKLJiniLPi6zj34U9zy4K8b0Te8spnV09FTcs6Qq7QvCZOiECsakyPGCK7AcP4vF8SC6BoCA4FUYUTPqnUZOTXBbGZMaBp0qY-jsJoaVqn1d0iGI4ggXaMuTEDmXMe1RgFUby1sCYfmurizMlaUAzlYNICepNhzfN5vn5MRzCdYxgPGvpX1ysQBS+XlXmaCDZlraLboauKrLQyGeqqGcrnNCsdImV5iomppSI+F0uktUZpmWoA */
  id: 'quick-search-session',
  initial: 'closed',
  context: {
    sessionStartTimestamp: null,
    sessionId: null,
    inputText: null,
    selectedEntitiesCount: 0,
    results: null,
    selectedTab: null
  },
  states: {
    closed: {
      on: {
        OPEN_SEARCH: [{
          guard: 'isTextEmpty',
          target: 'recentlyViewed',
          actions: ['startNewSession', 'logInteraction']
        }, {
          target: 'searchResults',
          actions: ['startNewSession', 'logInteraction']
        }]
      }
    },
    recentlyViewed: {
      entry: ['selectTab'],
      on: {
        INPUT_TEXT: {
          guard: 'isTextNotEmpty',
          target: 'searchResults',
          actions: ['logInteraction', 'updateInputText']
        },
        CLOSE_SEARCH: {
          target: 'closed',
          actions: ['logInteraction']
        },
        INTERACTION: {
          actions: ['logInteraction', 'maybeBumpSelectedEntitiesCount']
        },
        RESULTS: {
          actions: ['setResults']
        },
        SELECT_TAB: {
          actions: ['selectTab', 'logInteraction']
        }
      }
    },
    searchResults: {
      entry: ['selectTab'],
      on: {
        INPUT_TEXT: {
          guard: 'isTextEmpty',
          target: 'recentlyViewed',
          actions: ['logInteraction', 'updateInputText', 'selectTab']
        },
        CLOSE_SEARCH: {
          target: 'closed',
          actions: ['logInteraction']
        },
        INTERACTION: {
          actions: ['logInteraction', 'maybeBumpSelectedEntitiesCount']
        },
        RESULTS: {
          actions: ['setResults']
        },
        SELECT_TAB: {
          actions: ['selectTab', 'logInteraction']
        }
      }
    }
  },
  on: {
    INPUT_TEXT: {
      actions: ['logInteraction', 'updateInputText']
    }
  }
});