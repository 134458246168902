import "core-js/modules/es.array.push.js";
import GroupController from 'app/client/core/js/controllers/group.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Group'], GroupController], [['Controller', 'Group'], GroupController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { useEffect } from 'react';
import { useField, useForm } from 'react-final-form';
import { Button } from '@clubhouse/shared/components/Button';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { cannotEndWithAPeriod, combineValidators, isUsername, maxLength, notEmpty, sanitizeAndEmojify, slugify } from '@clubhouse/shared/utils';
import Icons from 'components/shared/Icon';
import { CreateEntityDialogForm } from 'components/shared/forms/CreateEntityDialogForm';
import { ColorField } from 'components/shared/forms/fields/ColorField';
import { DescriptionField } from 'components/shared/forms/fields/DescriptionField';
import { MentionField } from 'components/shared/forms/fields/MentionField';
import { ProfileIconField } from 'components/shared/forms/fields/ProfileIconField';
import { StringField } from 'components/shared/forms/fields/StringField';
import { UserField, UserListDropdownTrigger } from 'components/shared/forms/fields/UserField';
import { WorkflowField } from 'components/shared/forms/fields/WorkflowField';
import { all, create as createGroup, getEmptyGroup } from 'data/entity/group';
import { isLoggedInUserAdmin } from 'data/entity/user';
import { verifyWorkflowIds } from 'data/entity/workflow';
import { GroupDisplay } from 'data/typeConfigs';
import { getAppState } from 'utils/appState';
import { info as infoToast } from 'utils/message';
import { PAGE_NAMES, generatePathWithSlug, getPathForPage, navigateOnClick } from 'utils/navigation';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const uniqueTeamName = data => {
  const name = data?.trim();
  if (!name) return undefined;
  const isUnique = all().every(group => group.name !== name);
  return isUnique ? undefined : `Team "${name}" already exists.`;
};
const UserFieldWithListTrigger = props => {
  return _jsx(UserField, {
    ...props,
    DropdownTrigger: UserListDropdownTrigger,
    title: "Select Members"
  });
};
UserFieldWithListTrigger.displayName = "UserFieldWithListTrigger";
const WorkflowFieldWithTrigger = ({
  onClose,
  ...props
}) => {
  const handleOnCreateClick = () => {
    onClose();
    infoToast('Your new Team has not been lost. Open the Team to continue.', {
      actions: `<button class="action mini elevated" data-controller="Group" data-on-click="openCreateDialog">
        Open Team
      </button>`,
      id: 'create-team-saved'
    });
  };
  const isAdmin = isLoggedInUserAdmin();
  return _jsx(WorkflowField, {
    label: isAdmin ? 'Select or Create Workflow' : 'Select Workflow',
    maxSelected: null,
    footerType: isAdmin ? 'create' : 'none',
    onCreateClick: handleOnCreateClick,
    ...props
  });
};
WorkflowFieldWithTrigger.displayName = "WorkflowFieldWithTrigger";
const SuccessMessage = ({
  value
}) => {
  return _jsxs(Spaced, {
    vertically: true,
    amount: "space2",
    children: [_jsxs("div", {
      children: ["Team ", _jsx("strong", {
        children: sanitizeAndEmojify(value.name)
      }), " created."]
    }), _jsx(Button, {
      onClick: navigateOnClick(generatePathWithSlug(getPathForPage(PAGE_NAMES.SETTINGS_TEAM), {
        id: value.id
      })),
      size: Button.SIZE.MEDIUM,
      kind: Button.KIND.SECONDARY,
      IconLeft: Icons.Eye,
      children: "View Team"
    })]
  });
};
SuccessMessage.displayName = "SuccessMessage";
const CACHE_KEY = 'CreateGroup.Partial';
export const CreateTeamForm = ({
  onClose,
  onCreate,
  triggerNode,
  save = createGroup
}) => {
  const saveWrapper = ({
    changes
  }) => save(changes);
  const incompleteGroup = getAppState(CACHE_KEY) ?? {};
  if (incompleteGroup?.workflow_ids?.length > 0 && verifyWorkflowIds(incompleteGroup.workflow_ids)) {
    incompleteGroup.workflow_ids = [];
  }
  const initialGroup = {
    ...getEmptyGroup(),
    ...incompleteGroup
  };
  const rightFields = [{
    name: 'color_key',
    Component: ColorField
  }, {
    name: 'workflow_ids',
    Component: props => _jsx(WorkflowFieldWithTrigger, {
      ...props,
      onClose: onClose
    }),
    validate: combineValidators([notEmpty]),
    labelProps: {
      children: '',
      helpText: 'Connect a Team to a Workflow to ensure all Stories follow a consistent process.'
    }
  }, {
    name: 'member_ids',
    Component: UserFieldWithListTrigger
  }].filter(Boolean);
  return _jsx(CreateEntityDialogForm, {
    cacheKey: CACHE_KEY,
    dialogProps: {
      close: onClose,
      triggerNode
    },
    toastProps: {
      success: ({
        value
      }) => _jsx(SuccessMessage, {
        value: value
      }),
      failure: ({
        e
      }) => {
        if (e) {
          return e.responseIntercepted ? null : _jsx("div", {
            children: typeof e === 'string' ? e : e.message
          });
        }
        return null;
      }
    },
    entityFormProps: {
      onCreate,
      initialEntity: initialGroup,
      leftFields: [{
        name: 'display_icon',
        labelProps: {
          children: 'Profile Icon'
        },
        Component: ProfileIconField
      }, {
        name: 'name',
        labelProps: {
          children: 'Name'
        },
        validate: combineValidators([notEmpty, maxLength(63), uniqueTeamName]),
        Component: StringField
      }, {
        name: 'mention_name',
        labelProps: {
          children: 'Mention Name',
          helpText: 'Used for @-mentions within this workspace only.'
        },
        validate: combineValidators([notEmpty, isUsername, cannotEndWithAPeriod, maxLength(63)]),
        Component: props => {
          const form = useForm();
          const name = useField('name');
          useEffect(() => {
            form.change('mention_name', slugify(name.input.value, {
              limit: 63
            }));
          }, [form, name.input.value]);
          return _jsx(MentionField, {
            ...props
          });
        }
      }, {
        name: 'description',
        labelProps: {
          children: 'Description',
          helpText: 'Optional'
        },
        Component: DescriptionField
      }],
      rightFields,
      save: saveWrapper,
      typeConfig: GroupDisplay
    }
  });
};
CreateTeamForm.displayName = "CreateTeamForm";