import "core-js/modules/es.array.push.js";
import User from 'app/client/core/js/modules/user.js';
import InviteUsersController from 'app/client/settingsShared/js/controllers/inviteUsers.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  const InviteUsersController = exports;
  [[['User'], User], [['Utils'], Utils], [['Controller', 'InviteUsers'], InviteUsersController], [['Controller', 'InviteUsers'], InviteUsersController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import { Icon } from '@useshortcut/shapes-ds';
import { DeprecatedIconAdapter } from '@clubhouse/shared/components/Icons';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon';
import * as IconNoUserTemplate from 'app/client/core/views/templates/icons/icon-no-user.html';
import * as ServerMessageTemplate from 'app/client/core/views/templates/serverMessage.html';
import ManageUsersController from 'app/client/settingsShared/js/controllers/manageUsers';
import * as ExistingInvitesTemplate from 'app/client/settingsShared/views/templates/existingInvites.html';
import * as InviteUsersTemplate from 'app/client/settingsShared/views/templates/inviteUsers.html';
import * as InviteUsersByEmailTemplate from 'app/client/settingsShared/views/templates/inviteUsersByEmail.html';
import * as InviteUsersByLinkTemplate from 'app/client/settingsShared/views/templates/inviteUsersByLink.html';
import { InviteByDomainMatching } from 'components/invite/InviteByDomainMatching';
import { InviteGoogleContacts } from 'components/invite/InviteGoogleContacts';
import { AskAnOwnerAlert } from 'components/settings/AskAnOwnerAlert';
import { InviteToTeamDropdown } from 'components/settings/invite-users/InviteToTeamDropdown';
import { renderComponentToString } from 'utils/helpers';
import { RoleDropdown } from 'components/shared/RoleDropdown';
import { ENTITLEMENTS } from 'data/entity/entitlements';
import { simpleCompleteTask } from 'utils/quickstart';
import { EVENTS, logEvent } from '../../../../../utils/monitoring';
import * as Event from '../../../core/js/_frontloader/event';
import Router from '../../../core/js/_frontloader/router';
import AutocompleteController from '../../../core/js/controllers/autocomplete';
import DropdownController from '../../../core/js/controllers/dropdown';
import MessageController from '../../../core/js/controllers/message';
import CompanyModel from '../../../core/js/models/company';
import ApplicationState from '../../../core/js/modules/applicationState';
import Backend from '../../../core/js/modules/backend';
import Errors from '../../../core/js/modules/errors';
import Format from '../../../core/js/modules/format';
import InviteModel from '../../../core/js/models/invite';
import InviteLinkModel from '../../../core/js/models/inviteLink';
import Instrument from '../../../core/js/modules/instrument';
import Is from '../../../core/js/modules/is';
import Iterate from '../../../core/js/modules/iterate';
import Log from '../../../core/js/modules/log';
import MultiOrgModel from '../../../core/js/models/multiOrg';
import OrganizationModel from '../../../core/js/models/organization';
import PaymentPlan2Model from '../../../core/js/models/paymentPlan2';
import ProductModel from '../../../core/js/models/product';
import ProfileModel from '../../../core/js/models/profile';
import Url from '../../../core/js/modules/url';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import GoogleContactsController from '../../../oauthPermissionRedirect/js/controllers/googleContacts';
import UserModel from '../../../core/js/models/user';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import _ from 'lodash';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
exports.title = `Invite Users | ${BRAND.NAME}`;
exports.route = () => {
  const slug = Url.getSlugPath() || '/organizations';
  return slug + '/settings/users/invite';
};
const PARENT_SELECTOR = '#invite-users';
const INVITE_EMAILS_ACTION_SELECTOR = '.invite-emails-form-container .form-action button';
const INVITE_LINK_FORM_CONTAINER_SELECTOR = '.invite-link-form-container';
const INVITE_LINK_ACTION_SELECTOR = '.invite-link-form-container button';
exports.INVITE_BY_EMAIL_TAB_TYPE = 'inviteByEmail';
exports.INVITE_BY_LINK_TAB_TYPE = 'inviteByLink';
exports.INVITE_GOOGLE_CONTACTS_TAB_TYPE = 'inviteGoogleContacts';
exports.INVITE_BY_DOMAIN_MATCHING_TAB_TYPE = 'inviteByDomainMatching';
const _tabs = {
  [exports.INVITE_BY_EMAIL_TAB_TYPE]: {
    id: 'inviteUsersByEmail',
    text: 'Invite Emails',
    containerClassName: 'invite-users-by-email-container'
  },
  [exports.INVITE_BY_LINK_TAB_TYPE]: {
    id: 'inviteUsersByLink',
    text: 'Invite Link',
    containerClassName: 'invite-users-by-link-container'
  },
  [exports.INVITE_GOOGLE_CONTACTS_TAB_TYPE]: {
    id: 'inviteUsersGoogleContacts',
    text: 'Invite Contacts',
    containerClassName: 'invite-users-google-contacts-container'
  },
  [exports.INVITE_BY_DOMAIN_MATCHING_TAB_TYPE]: {
    id: 'inviteByDomainMatching',
    text: 'Settings',
    containerClassName: 'invite-users-domain-matching-container'
  }
};
const INVITE_BY_EMAIL_SELECTOR = `.${_tabs.inviteByEmail.containerClassName}`;
const INVITE_BY_LINK_SELECTOR = `.${_tabs.inviteByLink.containerClassName}`;
const INVITE_GOOGLE_CONTACTS_SELECTOR = `.${_tabs.inviteGoogleContacts.containerClassName}`;
const INVITE_BY_DOMAIN_MATCHING_SELECTOR = `.${_tabs.inviteByDomainMatching.containerClassName}`;
exports.INVALID_EMAIL_ERROR_TYPE = 'invalidEmail';
exports.NO_CONTACTS_ERROR_TYPE = 'noContacts';
exports.getParametrizedRoute = ({
  tabType,
  errorType
} = {}) => `${exports.route()}${Url.formatQueryParams({
  tab: tabType,
  error: errorType
})}`;
exports.open = () => {
  SettingsPage.loadLegacySettingsPage('InviteUsersController');
};
exports.render = async () => {
  const orgId = OrganizationModel.getCurrentID();
  const me = UserModel.getLoggedInUserPermission(orgId);
  const isAdmin = Is.admin(me);

  // The user may have the Create Story menu open at this point.
  DropdownController.closeAll();
  AutocompleteController.close();
  if (Is.companyPage()) {
    MultiOrgModel.updateState({
      id: orgId
    });
  }
  const {
    tab,
    error: errorType
  } = Url.parseLocationSearch();
  Router.replaceState(Url.currentUrlWithoutQueryParams(['tab', 'error']));
  const tabs = {
    ..._tabs
  };
  const org = OrganizationModel.getById(orgId);
  const html = InviteUsersTemplate.render({
    isAdmin,
    isOwner: Is.ownerOnly(me),
    isUsersPage: Url.isCurrentPath(ManageUsersController.route()),
    hasObservers: ProfileModel.getAllActiveObservers(orgId).length > 0 || InviteModel.allPendingObserversForOrg(orgId).length > 0,
    me,
    organization: org,
    scim_enabled: org.scim_enabled,
    hasExistingInvitesList: ({
      id
    }) => id !== exports.INVITE_BY_DOMAIN_MATCHING_TAB_TYPE,
    active: 'inviteUsers',
    tab: _.get(tabs, tab, tabs[exports.INVITE_BY_EMAIL_TAB_TYPE]),
    tabs: Object.values(tabs),
    readOnly: !isAdmin
  });
  $(SettingsPage.PARENT_SELECTOR).html(html);
  const dataPromises = [InviteLinkModel.fetch(), ProductModel.Promises.fetchAll(), PaymentPlan2Model.Promises.fetch()];
  try {
    await Promise.all(dataPromises);
    if (Url.isCurrentPath(exports.route())) {
      _renderAllTabs({
        error: errorType
      });
    }
    Event.on('CurrentUserRoleChanged.InviteUsers', () => {
      exports.render();
    });
  } catch (e) {
    MessageController.error(e, {
      timeout: 10000
    });
  }
  SettingsPage.onDestroy('InviteUsers', () => {
    View.unmountComponents({
      templateMountNode: document.querySelector(INVITE_BY_EMAIL_SELECTOR)
    });
    Event.off('CurrentUserRoleChanged.InviteUsers');
    Validator.destroy(PARENT_SELECTOR);
  });
};
const _renderAllTabs = (...args) => {
  exports.renderInvitedUsers(...args);
  exports.renderInviteByEmailTab(...args);
  exports.renderInviteByLinkTab(...args);
  if (typeof unmountComponentGoogleContactsTab === 'function') {
    unmountComponentGoogleContactsTab();
  }
  exports.renderInviteGoogleContactsTab(...args);
  if (typeof unmountComponentDomainMatchingTab === 'function') {
    unmountComponentDomainMatchingTab();
  }
  exports.renderInviteByDomainMatchingTab(...args);
};
exports.renderSyncIcon = (id, tabId = '') => View.renderComponentDelayed({
  componentKey: tabId + 'syncIcon-' + id,
  component: _jsx(SizedIcon, {
    icon: "Sync",
    size: "18"
  })
}).html;
exports.renderTrashIcon = (id, tabId = '') => View.renderComponentDelayed({
  componentKey: tabId + 'trashIcon-' + id,
  component: _jsx(SizedIcon, {
    icon: "Trash",
    size: "18"
  })
}).html;
exports.renderUserIcon = (id, tabId = '') => View.renderComponentDelayed({
  componentKey: tabId + 'userIcon-' + id,
  component: _jsx(SizedIcon, {
    icon: "User",
    size: "18"
  })
}).html;
exports.renderInvitedUsers = () => {
  const org = _getOrgFromPageContext();
  const invites = InviteModel.allPendingForOrganization(org.id);
  $('.existing-invites').each((i, el) => {
    const tabId = el.getAttribute('data-tab-id');
    const html = ExistingInvitesTemplate.render({
      tabId,
      invites: _.sortBy(invites, 'created_at').reverse(),
      showResendButton: OrganizationModel.isDisabled(org.id) !== true,
      readOnly: !Is.admin(UserModel.getLoggedInUserPermission(org.id))
    });
    el.innerHTML = html;
  });
};
exports.renderInviteByEmailTab = () => {
  // This function is also called on the Org dashboard > Manage Users screen,
  // so we want to do nothing in that case.
  if ($(INVITE_BY_EMAIL_SELECTOR).length === 0) {
    return;
  }
  const org = _getOrgFromPageContext();
  const paymentPlan2 = PaymentPlan2Model.getPlanForCurrentOrg();
  const readOnly = _isReadOnly();
  View.renderWithComponents({
    template: InviteUsersByEmailTemplate,
    templateData: {
      organization: org,
      paymentPlan2,
      readOnly
    },
    templateMountNode: document.querySelector(INVITE_BY_EMAIL_SELECTOR),
    components: {
      roleDropdown: {
        component: RoleDropdown,
        getProps: ({
          rerender
        }) => {
          const props = {
            isDisabled: readOnly,
            name: 'role',
            onItemSelected: role => {
              rerender({
                props: {
                  ...props,
                  value: role
                }
              });
            },
            value: PaymentPlan2Model.isTrialing(paymentPlan2) || !PaymentPlan2Model.hasEntitlement(paymentPlan2, ENTITLEMENTS.ROLE_CREATE_MEMBER) ? 'admin' : 'member'
          };
          return props;
        }
      },
      teamDropdown: {
        component: InviteToTeamDropdown,
        props: {}
      },
      readOnlyMessage: {
        component: AskAnOwnerAlert,
        props: {
          description: 'Only owners and admins can invite users.'
        }
      }
    }
  });
  Validator.destroy(PARENT_SELECTOR);
  Validator.init(PARENT_SELECTOR);
};
exports.renderInviteByLinkTab = () => {
  const container = $(INVITE_BY_LINK_SELECTOR);
  if (container.length === 0) {
    return;
  }
  const html = InviteUsersByLinkTemplate.render({
    inviteLink: InviteLinkModel.getForOrganization()
  });
  container.html(html);
};
let unmountComponentGoogleContactsTab;
const setTab = ({
  tabType
}) => {
  document.querySelector(`[data-tab="${_tabs[tabType].id}"]`).click();
};
exports.renderInviteGoogleContactsTab = ({
  error
} = {}) => {
  const paymentPlan2 = PaymentPlan2Model.getPlanForCurrentOrg();
  const canInviteObservers = PaymentPlan2Model.canInviteObservers(paymentPlan2);
  const {
    unmountComponent
  } = View.renderComponent({
    component: InviteGoogleContacts,
    containerSelector: INVITE_GOOGLE_CONTACTS_SELECTOR,
    props: {
      getContacts: GoogleContactsController.getCurrentOrgContactsWithEmail,
      clearContacts: GoogleContactsController.clearContacts,
      onInvited: _renderAllTabs,
      canInviteObservers,
      redirectToInviteByEmailTab: () => {
        setTab({
          tabType: exports.INVITE_BY_EMAIL_TAB_TYPE
        });
      },
      errorTypes: {
        INVALID_EMAIL_ERROR_TYPE: exports.INVALID_EMAIL_ERROR_TYPE,
        NO_CONTACTS_ERROR_TYPE: exports.NO_CONTACTS_ERROR_TYPE
      },
      defaultError: error,
      contextProps: {
        ApplicationState,
        Backend,
        Url,
        Model: {
          Profile: ProfileModel,
          Organization: OrganizationModel,
          Invite: InviteModel
        }
      },
      ...{
        paymentPlan2: paymentPlan2
      }
    }
  });

  // Component must be manually unmounted in _renderAllTabs
  // or it will not render correctly after inviting a Google contact
  unmountComponentGoogleContactsTab = unmountComponent;
};
let unmountComponentDomainMatchingTab;
exports.renderInviteByDomainMatchingTab = () => {
  const {
    unmountComponent
  } = View.renderComponent({
    component: InviteByDomainMatching,
    containerSelector: INVITE_BY_DOMAIN_MATCHING_SELECTOR,
    props: {
      contextProps: {
        ApplicationState,
        Backend,
        Url
      }
    }
  });

  // Component must be manually unmounted in _renderAllTabs
  unmountComponentDomainMatchingTab = unmountComponent;
};
const _isReadOnly = () => {
  const org = _getOrgFromPageContext();
  const me = UserModel.getLoggedInUserPermission(org.id);
  return !Is.adminOrOwner(me);
};

// https://app.shortcut.com/internal/story/173963/member-invitation-limit-error-inquiry
const logIfRoleAndPaymentPlanMismatch = ({
  formData
}) => {
  try {
    const org = _getOrgFromPageContext();
    const paymentPlan2 = PaymentPlan2Model.getPlanForOrg(org.id);
    if (!PaymentPlan2Model.canInviteObservers(paymentPlan2) && formData.role === 'member') {
      Instrument.log({
        data: {
          roleAndFreePlanMismatch: true,
          roleAndFreePlanMismatch_roleCount: document.querySelectorAll('*[name="role"]').length // maybe there are multiple role inputs?
        }
      });
    }
  } catch (e) {
    // no-op
  }
};
const logInvitesRequest = invites => {
  logEvent(EVENTS.Invites_Requested, {
    emails_submitted: invites.length,
    teams_submitted: invites[0].group_ids.length
  });
};
exports.submitEmails = () => {
  const context = $(PARENT_SELECTOR);
  if (Validator.isValid(context)) {
    const formData = Utils.formData(context);
    logIfRoleAndPaymentPlanMismatch({
      formData
    });
    const requestData = InviteModel.formatDataForRequest(formData);
    logInvitesRequest(requestData.invites);
    _sendInviteRequest.call(context, requestData);
  }
  return false;
};
function _sendInviteRequest(invitesData) {
  View.changeButtonToSaving(INVITE_EMAILS_ACTION_SELECTOR, 'Sending...');
  InviteModel.createInvites(invitesData, (err, res) => {
    if (err) {
      exports.handleInviteError(err);
    } else {
      exports.handleInviteResponse(res);
    }
  }, {
    ..._getOrgHeaders(this),
    actionContext: 'settings'
  });
}
exports.handleInviteError = message => {
  message = message || 'We got an invalid response from the server.';
  const html = ServerMessageTemplate.render({
    className: 'error',
    iconLeft: 'fa-exclamation-circle',
    title: 'Unable to send invites.',
    message: message + '<br /><a href="#" data-on-click="open" class="action mini elevated">Try again</a>'
  });
  exports.displayInviteResponse(html);
};
exports.handleInviteResponse = res => {
  res.sort((a, b) => {
    return a.created && !b.created ? -1 : 1;
  });
  const created = _.filter(res, {
    created: true
  }).length;
  const errors = res.length - created;
  const title = [];
  let message = '';
  if (created) {
    title.push(Format.pluralize(created, 'invite', 'invites') + ' sent');
    simpleCompleteTask('create-invite');
  }
  if (errors) {
    title.push(Format.pluralize(errors, 'invite', 'invites') + ' failed');
  }
  Iterate.each(res, item => {
    item.reason = item.reason || 'A user already exists with this email.';
    if (item.reason === 'Removed user must have profile re-enabled by an owner.') {
      const dashboardUrl = CompanyModel.getDashboardUrl(CompanyModel.getCurrent());
      item.reason += ` <br />You can do this under <strong>Removed Users</strong>
 on the <a href="${dashboardUrl}">Company Dashboard</a>.`;
    }
    if (item.created) {
      message += '<div class="invite-result successful-invite"><span class="fa fa-check"></span> ' + Format.sanitize(item.email) + '</div>';
    } else {
      item.reason = Errors.wrapWithCardLink(item.reason);
      message += '<div class="invite-result failed-invite"><span class="fa fa-times"></span> ' + Format.sanitize(item.email) + '<div class="failed-invite-reason">' + item.reason + '</div></div>';
    }
  });
  const html = ServerMessageTemplate.render({
    iconLeft: 'fa-envelope',
    title: title.join(', ') + ':',
    message: '<div class="invite-results">' + message + '</div><a href="#" data-on-click="open" class="action mini elevated">Send more</a>',
    closeController: 'InviteUsers',
    closeOnClick: 'open'
  });
  exports.displayInviteResponse(html);
};
exports.displayInviteResponse = html => {
  const context = $(PARENT_SELECTOR);
  context.find('.server-messages').hide().html(html);
  context.find('.hide-on-success, .existing-invites').fadeOut(200, () => {
    exports.renderInvitedUsers();
    exports.renderInviteGoogleContactsTab();
    exports.renderInviteByDomainMatchingTab();
    context.find('.server-messages').fadeIn(200);
    $('.existing-invites').fadeIn(200);
  });
};
exports.deleteInvite = function () {
  const inviteElement = $(this).closest('.existing-invite');
  const invite = Utils.getModelFromContext(this);
  $(this).html('<span class="fa fa-star fa-spin"></span>').removeAttr('data-on-click');
  InviteModel.deleteInvite(invite, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to delete invite.'
      });
    } else {
      View.fadeAndSlideUp(inviteElement, _renderAllTabs);
      MessageController.success('Invite deleted.');
      Event.trigger('inviteDeleted');
    }
  }, _getOrgHeaders(this));
  return false;
};
exports.resendInvite = function () {
  const invite = Utils.getModelFromContext(this);
  const context = $(this);
  context.html('<span class="fa fa-star fa-spin"></span>');
  InviteModel.resend(invite, err => {
    if (err) {
      MessageController.error(err, {
        secondary: 'Unable to resend invite.'
      });
    } else {
      MessageController.success('Invite re-sent.');
    }
    context.html('<span class="fa fa-refresh"></span>');
  }, _getOrgHeaders(this));
  return false;
};
function _getOrgFromPageContext(context) {
  const inviteScreenContext = Utils.getModelFromContext($(PARENT_SELECTOR), 'Organization');
  return inviteScreenContext || Utils.getModelFromContext(context, 'Organization');
}

// The headers should be correct via the id being set in the multi org model,
// but i'm being explicit in sending these to make sure they're always in sync
function _getOrgHeaders(context) {
  const org = _getOrgFromPageContext(context);
  const company = CompanyModel.getFromOrg({
    id: org.id
  });
  return {
    organization_id: org.id,
    company_id: company.id
  };
}
const _shouldShowInviteUsersFooter = () => {
  const me = UserModel.getLoggedInUserPermission();
  return Is.admin(me) && OrganizationModel.getActiveUserCount() < 8;
};
exports.getInviteUsersFooter = () => _shouldShowInviteUsersFooter() ? '<div class="autocomplete-invite-users" data-controller="InviteUsers" data-on-click="open">' + renderComponentToString(_jsx(DeprecatedIconAdapter, {
  width: 16,
  children: _jsx(Icon, {
    icon: "User",
    fill: "currentColor"
  })
})) + '<div class="autocomplete-invite-users-text">Invite New User</div>' + renderComponentToString(_jsx(Icon, {
  icon: "ChevronRight"
})) + '</div>' : undefined;
const _getUsersNotFound = () => {
  const me = UserModel.getLoggedInUserPermission();
  return `
    <div class="autocomplete-user-not-found">
      ${IconNoUserTemplate.render()}
      No results found.
      ${Is.admin(me) ? '<br/>User not listed? <a href="#" data-controller="InviteUsers" data-on-click="open">Invite New Users</a>' : ''}
    </div>
  `;
};
exports.getAutocompleteWithInviteUpdateHandler = (() => {
  let prevFooterHtml;
  return ({
    hasResults,
    setNoResults,
    noResults,
    setFooter,
    footer
  }) => {
    if (!hasResults) {
      if (typeof prevFooterHtml === 'undefined') {
        prevFooterHtml = footer.html();
      }
      setNoResults(_getUsersNotFound());
      noResults.removeClass('incomplete-results');
      setFooter('');
    } else {
      setFooter(prevFooterHtml);
      prevFooterHtml = undefined;
    }
  };
})();
exports.createInviteLink = async () => {
  try {
    View.changeButtonToSaving(INVITE_LINK_ACTION_SELECTOR, 'Generating...');
    const inviteLink = await InviteLinkModel.create();
    $(INVITE_LINK_FORM_CONTAINER_SELECTOR).html(exports.getInviteLinkForm(inviteLink));
  } catch (e) {
    Log.error(e);
    $(INVITE_LINK_FORM_CONTAINER_SELECTOR).html(exports.getInviteLinkForm());
    _handleInviteLinkCreationError(e.toString());
  }
  return false;
};
exports.deleteInviteLink = async function (e) {
  e.preventDefault();
  const id = Utils.data(this, 'id');
  try {
    View.changeButtonToSaving(INVITE_LINK_ACTION_SELECTOR, 'Disabling');
    await InviteLinkModel.delete(id);
    $(INVITE_LINK_FORM_CONTAINER_SELECTOR).html(exports.getInviteLinkForm());
  } catch (e) {
    Log.error(e);
    _handleInviteLinkCreationError(e.toString());
  }
};
const _handleInviteLinkCreationError = (message, title) => {
  message = message || 'We got an invalid response from the server.';
  const html = ServerMessageTemplate.render({
    className: 'error',
    iconLeft: 'fa-exclamation-circle',
    title: title || 'Unable to create an invite link.',
    message
  });
  $('.server-messages').fadeIn().html(html);
};
exports.getInviteLinkButton = inviteLink => {
  const isReadOnly = _isReadOnly();
  if (!inviteLink) {
    return `<button ${isReadOnly ? 'class="action create-invite-link disabled" disabled' : 'class="action create-invite-link green" data-on-click="createInviteLink" data-tabindex'}>Generate Link</button>`;
  }
  return `<button ${isReadOnly ? 'class="action disabled" disabled' : 'class="action green clipboard" data-clipboard-target="#inviteLink" data-tabindex'}>Copy</button>`;
};
exports.getInviteLinkForm = inviteLink => {
  const isReadOnly = _isReadOnly();
  const button = exports.getInviteLinkButton(inviteLink);
  if (isReadOnly) {
    return View.renderComponentDelayed({
      component: _jsx(AskAnOwnerAlert, {
        description: "Only owners and admins can invite users."
      })
    }).html;
  }
  if (!inviteLink) {
    return `${button}<div class="formatting-note">Create a link to quickly invite your team to ${BRAND.NAME} as Members.</div>`;
  }
  return `
    <div class="inline-form centered">
      <div class="form-input">
        <a href="#" ${isReadOnly ? 'class="icon-prepend fa fa-clipboard" disabled' : 'class="icon-prepend fa fa-clipboard clipboard" data-clipboard-target="#inviteLink"'}></a>
        <input class="input textfield" data-on-click="App.Utils.selectText" value="${inviteLink.url}" readonly placeholder="Invite your team by sharing a link" id="inviteLink" />
      </div>
      <div class="form-action">
        ${button}
      </div>
    </div>

    <a href="#" class="formatting-note" data-id="${inviteLink.id}"${isReadOnly ? '' : ' data-on-click="deleteInviteLink"'}><span class="fa fa-trash"></span> Disable invite link</a>
  `;
};
export { exports as default };