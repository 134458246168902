import { PAGE_NAMES } from 'utils/navigation';
import { useShouldReplaceLegacyStoriesPage } from 'utils/useShouldReplaceLegacyStoriesPage';
export const PROFILE_POPOVER_CONTENT_ID = 'profile-popover-content';
export const WORKSPACE_POPOVER_CONTENT_ID = 'workspace-popover-content';
export const shouldShowBigLogoLayoutForPage = page => [PAGE_NAMES.LOGIN, 'company', 'appauth', 'auth', PAGE_NAMES.RECOVERY, PAGE_NAMES.CONFIRM_EMAIL, PAGE_NAMES.SSO, PAGE_NAMES.INVITE_LINK, PAGE_NAMES.RESET, PAGE_NAMES.INVITES].includes(page);
export const shouldShowEmptyLayoutForPage = page => typeof page === 'undefined' || [PAGE_NAMES.SIGNUP, 'signup-new-org', PAGE_NAMES.VERIFY_EMAIL, PAGE_NAMES.CREATE_ORGANIZATION].includes(page);

/*
   This layout was previously only being used by the onboarding page, which has been removed,
   left the fn def. in place to add any future page that needs the custom layout.
*/
export const shouldShowCustomLayoutForPage = _ => false;
export const useShouldShowLoadingSpinnerForPage = page => {
  const {
    value: shouldReplaceLegacyStoriesPage
  } = useShouldReplaceLegacyStoriesPage();
  return false === [PAGE_NAMES.EPICS, PAGE_NAMES.BACKLOG, PAGE_NAMES.WRITE, PAGE_NAMES.VERIFY_EMAIL, PAGE_NAMES.LABELS, PAGE_NAMES.ROADMAP, PAGE_NAMES.OBJECTIVE, PAGE_NAMES.OBJECTIVES, PAGE_NAMES.SETTINGS, ...(shouldReplaceLegacyStoriesPage ? [PAGE_NAMES.STORY] : []), ...(shouldReplaceLegacyStoriesPage ? [PAGE_NAMES.STORIES] : [])].includes(page);
};