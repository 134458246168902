import * as AddNewProjectActionsTemplate from 'app/client/core/views/templates/addNewProjectActions.html';
import * as NewProjectColorPickerTemplate from 'app/client/core/views/templates/newProjectColorPicker.html';
import * as NewProjectTeamControlTemplate from 'app/client/core/views/templates/newProjectTeamControl.html';import AddNewController from 'app/client/core/js/controllers/addNew.js';
import AddNewProjectController from 'app/client/core/js/controllers/addNewProject.js';
import TeamModel from 'app/client/core/js/models/team.js';

window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || { Controller: {}, Model: {} };
  
  [
    [['Controller', 'AddNew'], AddNewController],
  [['Controller', 'AddNewProject'], AddNewProjectController],
  [['Model', 'Team'], TeamModel],
  [['Controller', 'AddNew'], AddNewController],
  [['Controller', 'AddNewProject'], AddNewProjectController],
  [['Model', 'Team'], TeamModel]
  ].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});



import CMRuntime from 'app/client/core/js/modules/cavemanRuntime.js';



function renderString(Caveman, d) {
  var _Cr = Caveman.render;var _CfS = Caveman.forceStr; var str = '';str += '<div class="add-new-dialog add-new-project clearfix" data-controller="AddNewProject">'+'  <div class="left-column">'+'    <div class="section-head title no-border">Project Title</div>'+'    <div class="title-container" data-validate="notEmpty">'+'      <div class="in-place-textarea">'+'        <textarea data-on-keydown="onKeyDownTitle" data-binding="name" placeholder="e.g. Web App, API Server">';str += _CfS(d.name);str += '</textarea>'+'      </div>'+'    </div>'+'    <div class="section-head no-border">Description <span class="label-optional">Optional</span></div>'+'    '+'    <div id="project-description-v2"></div>'+'  </div>'+'  <div class="right-column">'+'    <div class="form-help-message">'+'      <p><strong>Projects</strong> represent technical components (such as Web App and API) or feature areas within your product.</p>'+'    </div>'+''+'    <div class="attribute-spacer"></div>'+''+'    ';if (App.Model.Team.size() > 1) {str += '      <div id="new-project-team">'+'        ';str += NewProjectTeamControlTemplate.render( d);str += '      </div>'+'    ';}str += '    <div tabindex="0" class="attribute editable-attribute condensed project-color-picker" data-on-click="openColorPicker">'+'      ';str += NewProjectColorPickerTemplate.render( d);str += '    </div>'+''+'    <div class="add-new-action">'+'      ';str += AddNewProjectActionsTemplate.render( d);str += '    </div>'+''+'  </div>'+'</div>';return str;
}

export function render(d = {}) {
  let html = renderString(CMRuntime, d);
  
const p = document.createElement('DIV');
p.innerHTML = html;
Array.from(p.children).forEach(n => {
  if ('classList' in n) {
    n.classList.add('r_caveman');
  }
});
html = p.innerHTML;  

  return html;
};
