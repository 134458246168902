import "core-js/modules/es.array.push.js";
import Loading from 'app/client/core/js/modules/loading.js';
import User from 'app/client/core/js/modules/user.js';
import ImportDataPageController from 'app/client/settingsShared/js/controllers/importDataPage.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Loading'], Loading], [['User'], User], [['Controller', 'ChangeEmail'], ChangeEmailController], [['Controller', 'EditProfile'], EditProfileController], [['Controller', 'ImportDataPage'], ImportDataPageController], [['Controller', 'InviteUsers'], InviteUsersController], [['Controller', 'ManageBilling'], ManageBillingController], [['Controller', 'ManageEstimateScale'], ManageEstimateScaleController], [['Controller', 'ManageLinkedAccounts'], ManageLinkedAccountsController], [['Controller', 'ManageLoadingIcons'], ManageLoadingIconsController], [['Controller', 'ManageNotifications'], ManageNotificationsController], [['Controller', 'ManageSecurity'], ManageSecurityController], [['Controller', 'ManageTemplates'], ManageTemplatesController], [['Controller', 'ManageTokens'], ManageTokensController], [['Controller', 'ManageUsers'], ManageUsersController]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import isFunction from 'lodash/isFunction';
import * as TeamLabelTemplate from 'app/client/core/views/templates/teamLabel.html';
import * as WorkflowLabelTemplate from 'app/client/core/views/templates/workflowLabel.html';
import * as Event from '../../../core/js/_frontloader/event';
import Globals from '../../../core/js/_frontloader/globals';
import Router from '../../../core/js/_frontloader/router';
import DropdownController from '../../../core/js/controllers/dropdown';
import HotReloadController from '../../../core/js/controllers/hotReload';
import SampleWorkspaceController from '../../../core/js/controllers/sampleWorkspace';
import OrganizationModel from '../../../core/js/models/organization';
import UserModel from '../../../core/js/models/user';
import Dialog from '../../../core/js/modules/dialog';
import Is from '../../../core/js/modules/is';
import Url from '../../../core/js/modules/url';
import Utils from '../../../core/js/modules/utils';
import ChangeEmailController from '../../../settingsShared/js/controllers/changeEmail';
import EditProfileController from '../../../settingsShared/js/controllers/editProfile';
import ImportDataController from '../../../settingsShared/js/controllers/importDataPage';
import InviteUsersController from '../../../settingsShared/js/controllers/inviteUsers';
import ManageBillingController from '../../../settingsShared/js/controllers/manageBilling';
import ManageEstimateScaleController from '../../../settingsShared/js/controllers/manageEstimateScale';
import ManageLinkedAccountsController from '../../../settingsShared/js/controllers/manageLinkedAccounts';
import ManageLoadingIconsController from '../../../settingsShared/js/controllers/manageLoadingIcons';
import ManageNotificationsController from '../../../settingsShared/js/controllers/manageNotifications';
import ManageSecurityController from '../../../settingsShared/js/controllers/manageSecurity';
import ManageTemplatesController from '../../../settingsShared/js/controllers/manageTemplates';
import ManageTokensController from '../../../settingsShared/js/controllers/manageTokens';
import ManageUsersController from '../../../settingsShared/js/controllers/manageUsers';
const PARENT_SELECTOR = '#settings-page';
const title = `Settings | ${BRAND.NAME}`;
const route = () => {
  return Url.getSlugPath() + '/settings';
};
const init = () => {
  Router.addRoutesFromControllers([...getSubnav()].filter(item => 'controller' in item).map(config => {
    return config.controller;
  }));
};
const loadPane = ({
  title,
  route,
  render
}) => {
  if (SampleWorkspaceController.isSampleObserver()) {
    return false;
  }
  DropdownController.closeAll();
  if ($(PARENT_SELECTOR).length > 0) {
    Dialog.closeIfOpen({
      force: true
    });
    Event.trigger('settingsSwitchPane');
    Router.pushState(route(), isFunction(title) ? title(route()) : title);
    Router.handleCurrentRoute();
    if (render.then) {
      return render();
    } else {
      render();
    }
  } else {
    HotReloadController.loadURL(route(), 'settings');
  }
};
const onDestroy = (NS, handler) => {
  const eventNames = `pageDestroy.${NS} settingsSwitchPane.${NS}`;
  Event.off(eventNames, handler);
  Event.once(eventNames, handler);
};
const render = () => {
  if (!Router.hasRouteForCurrentUrl()) {
    return Utils.redirect(`${Url.getSlugPath()}/settings/account`);
  }
  Router.handleCurrentRoute();
  Event.trigger('resize');
  Event.trigger('pageRendered', 'Settings');
};
const getSubnav = () => {
  let subnav = getProfileNav();
  if (Is.withinOrg() || !Is.withinOrg() && Globals.get('organizationID')) {
    subnav = subnav.concat(getOrgNav());
  }
  return subnav;
};
const getProfileNav = () => {
  const subnav = [{
    id: 'yourAccountTitle',
    name: 'My Account',
    title: true
  }, {
    id: 'editProfile',
    name: 'My Profile',
    onClick: 'App.Controller.EditProfile.open',
    controller: EditProfileController
  }, {
    id: 'changeEmail',
    name: 'Email Addresses',
    onClick: 'App.Controller.ChangeEmail.open',
    controller: ChangeEmailController
  }];
  if (Is.withinOrg()) {
    subnav.push({
      id: 'manageNotifications',
      name: 'Notifications',
      onClick: 'App.Controller.ManageNotifications.open',
      controller: ManageNotificationsController
    }, {
      id: 'manageTokens',
      name: 'API Tokens',
      onClick: 'App.Controller.ManageTokens.open',
      controller: ManageTokensController
    }, {
      id: 'manageSecurity',
      name: 'Security',
      onClick: 'App.Controller.ManageSecurity.open',
      controller: ManageSecurityController
    }, {
      id: 'manageLinkedAccounts',
      name: 'Linked Accounts',
      onClick: 'App.Controller.ManageLinkedAccounts.open',
      controller: ManageLinkedAccountsController
    });
  }
  return subnav;
};
const getOrgNav = () => {
  const orgId = OrganizationModel.getCurrentID();
  const me = UserModel.getLoggedInUserPermission(orgId);
  let subnav = [{
    id: 'workspaceTitle',
    name: Is.withinOrg() ? 'Workspace Settings' : 'Organization Settings',
    title: true,
    hr: true
  }];
  if (Is.withinOrg()) {
    subnav = subnav.concat([{
      id: 'manageUsers',
      name: 'User Directory',
      onClick: 'App.Controller.ManageUsers.open',
      controller: ManageUsersController
    }, {
      id: 'inviteUsers',
      name: 'Invite New Users',
      onClick: 'App.Controller.InviteUsers.open',
      controller: InviteUsersController
    }, {
      id: 'manageBilling',
      name: 'Billing',
      onClick: 'App.Controller.ManageBilling.open',
      controller: ManageBillingController
    }, {
      id: 'manageLoadingIcons',
      name: 'Loading Icons',
      onClick: 'App.Controller.ManageLoadingIcons.open',
      controller: ManageLoadingIconsController
    }, {
      id: 'workspaceFeaturesTitle',
      name: 'Workspace Features',
      title: true,
      hr: true
    }, {
      id: 'manageTeams',
      name: TeamLabelTemplate.render({
        isPlural: true
      }),
      onClick: 'App.Controller.ManageTeams.open'
    }, {
      id: 'manageWorkflow',
      name: WorkflowLabelTemplate.render({
        isPlural: true
      }),
      onClick: 'App.Controller.ManageWorkflow.open'
    }, {
      id: 'manageEstimateScale',
      name: 'Estimate Scale',
      onClick: 'App.Controller.ManageEstimateScale.open',
      controller: ManageEstimateScaleController
    }, {
      id: 'manageTemplates',
      name: 'Story Templates',
      onClick: 'App.Controller.ManageTemplates.open',
      controller: ManageTemplatesController
    }]);
    if (Is.admin(me)) {
      subnav.splice(3, 0, {
        id: 'importData',
        name: 'Import / Sync',
        onClick: 'App.Controller.ImportDataPage.open',
        controller: ImportDataController
      });
    }
  } else {
    subnav.push({
      id: 'manageBilling',
      name: 'Billing',
      onClick: 'App.Controller.ManageBilling.open',
      controller: ManageBillingController
    });
  }
  return subnav;
};
const controllers = {
  /* eslint-disable import/dynamic-import-chunkname */
  DropdownController: () => import(/* webpackMode: "eager" */'app/client/core/js/controllers/dropdown'),
  ChangeEmailController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/changeEmail'),
  EditProfileController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/editProfile'),
  InviteUsersController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/inviteUsers'),
  ImportDataController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/importDataPage'),
  ManageBillingController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/manageBilling'),
  ManageEstimateScaleController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/manageEstimateScale'),
  ManageLinkedAccountsController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/manageLinkedAccounts'),
  ManageLoadingIconsController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/manageLoadingIcons'),
  ManageNotificationsController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/manageNotifications'),
  ManageSecurityController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/manageSecurity'),
  ManageTemplatesController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/manageTemplates'),
  ManageTokensController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/manageTokens'),
  ManageUsersController: () => import(/* webpackMode: "eager" */'app/client/settingsShared/js/controllers/manageUsers')
  /* eslint-enable import/dynamic-import-chunkname */
};
const getSettingsController = async controllerName => {
  try {
    const getSettingsControllerFunction = controllers[controllerName];
    const {
      default: settingsController
    } = await getSettingsControllerFunction();
    return settingsController;
  } catch (err) {
    throw new Error(`There is no controller defined for: ${controllerName}`, {
      cause: err
    });
  }
};
const loadLegacySettingsPage = async pageController => {
  const Controller = await getSettingsController(pageController);
  const {
    createRoute,
    route,
    title,
    render,
    initData
  } = Controller;
  if (SampleWorkspaceController.isSampleObserver()) {
    return false;
  }
  Router.add(createRoute ?? route, title, render);
  DropdownController.closeAll();
  Dialog.closeIfOpen({
    force: true
  });

  // Edge case for import data page where we fetch import data before rendering the page
  if (initData && isFunction(initData)) {
    await initData();
  }
  if (render) {
    render();
  }
};
export default {
  PARENT_SELECTOR,
  loadLegacySettingsPage,
  title,
  route,
  init,
  loadPane,
  onDestroy,
  render,
  getSubnav,
  getProfileNav,
  getOrgNav
};