import "core-js/modules/es.array.push.js";
// This module could probably be split out into separate modules,
// the the core utilities building the specific autocomplete's.
import { MentionDropdown } from 'components/shared/MentionDropdown';
import { convertAccentedCharacters } from '@clubhouse/shared/utils';
import AutocompleteController from './autocomplete';
import Caret from '../modules/caret';
import { getEmojis } from '@clubhouse/shared/utils/emoji';
import DropdownController from './dropdown';
import Format from '../modules/format';
import InPlaceTextareaController from './inPlaceTextarea';
import StorySearchController from './storySearch';
import Utils from '../modules/utils';
import View from '../modules/view';
import _ from 'lodash';
const exports = {};
let dropdown;
let selectionStart;
let elasticSearchTimeout;
exports.handleInput = (context, e) => {
  selectionStart = Caret.getSelectionStart(context);
  const fragment = _getFragment(context, selectionStart, [USER_CHAR, EMOJI_CHAR, ...Format.STORY_PREFIXES]);
  if (_isTypingUsername(fragment) && !isKeyDownAndEscape(e)) {
    openReactAutocomplete(context, fragment);
  } else if (_isTypingEmoji(fragment)) {
    exports.openEmojiAutocomplete(context, fragment);
  } else if (_isTypingStory(fragment)) {
    exports.openStoryAutocomplete(context, fragment);
  } else if (exports.isOpen()) {
    _closeCurrentAutocomplete();
  } else if (unmountMentionDropdown) {
    unmountMentionDropdown();
  }
};
const isKeyDownAndEscape = e => {
  return e.type === 'keyup' && e.key === 'Escape';
};
function _sortByRank(items) {
  return _.sortBy(items, ['rank', 'value']);
}
let unmountMentionDropdown;
const openReactAutocomplete = (context, fragment) => {
  const formattedFragment = convertAccentedCharacters(fragment.substr(1).toLowerCase());
  let mountNode = context.parentNode.querySelector('#mention-dropdown');
  if (!mountNode) {
    mountNode = document.createElement('div');
    mountNode.setAttribute('id', 'mention-dropdown');
    context.parentNode.appendChild(mountNode);
  }
  View.renderComponent({
    mountNode,
    component: MentionDropdown,
    getProps: ({
      unmountComponent
    }) => {
      unmountMentionDropdown = () => {
        unmountMentionDropdown = undefined;
        unmountComponent();
        mountNode.remove();
      };
      context.removeEventListener('blur', unmountMentionDropdown);
      context.addEventListener('blur', unmountMentionDropdown);
      return {
        query: formattedFragment,
        referenceElement: context,
        onSelectedValue: entity => {
          _applyUsername(context, fragment, entity);
        }
      };
    }
  });
};

// User Autocomplete

const USER_CHAR = '@';
function _isTypingUsername(fragment) {
  return fragment && /^@[a-zA-Z0-9\-\_\.]*$/.test(fragment);
}
function _applyUsername(context, fragment, entity) {
  const formatted = Format.generateCustomMentionMarkdown(entity) + ' ';
  _applyValue(context, fragment, formatted, Format.convertCustomMarkdownMentionsToNaked);
}

// Emoji Autocomplete

const EMOJI_CHAR = ':';
const EMOJI_RESULTS_LIMIT = 30;
function _isTypingEmoji(fragment) {
  return fragment && /^:[a-z0-9\_\-\+]+$/.test(fragment);
}
exports.openEmojiAutocomplete = (context, fragment) => {
  const formattedFragment = convertAccentedCharacters(fragment.substr(1).toLowerCase());
  const items = [];
  let exactMatch = false;
  getEmojis(formattedFragment).then(([{
    emojis
  }]) => {
    for (const emoji of emojis) {
      if (items.length >= EMOJI_RESULTS_LIMIT) break;
      const isDemotedValue = _isDemotedValue(formattedFragment);
      if (emoji === `:${formattedFragment}:`) {
        exactMatch = true;
        items.push({
          name: Format.emojify(emoji),
          rank: 1,
          value: emoji,
          className: 'emoji-item'
        });
      } else if (formattedFragment && emoji.indexOf(formattedFragment) === 0) {
        items.push({
          name: Format.emojify(emoji),
          rank: isDemotedValue ? 12 : 2,
          value: emoji,
          className: 'emoji-item'
        });
      } else if (fragment === EMOJI_CHAR || emoji.indexOf(formattedFragment) !== -1) {
        items.push({
          name: Format.emojify(emoji),
          rank: isDemotedValue ? 13 : 3,
          value: emoji,
          className: 'emoji-item'
        });
      }
    }
    if (exactMatch && _notInMiddleOfWord(context, selectionStart)) exactMatch = false;
    exports.open({
      context,
      items: _sortByRank(_.uniqBy(items, 'name')),
      fragment,
      exactMatch,
      onApply: _applyEmoji,
      className: 'emoji-grid'
    });
  });
  return false;
};
function _isDemotedValue(value) {
  const demotedValues = ['middle_finger', 'thumbdown', 'thumbsdown'];
  return new RegExp(demotedValues.join('|')).test(value);
}
function _applyEmoji(context, fragment, emoji) {
  const formatted = emoji + ' ';
  _applyValue(context, fragment, formatted);
}

// Story Autocomplete

// Used by callbacks to see if we are still typing a Story when the
// callback fires
let typingStory = false;
function _isTypingStory(fragment) {
  typingStory = fragment && Format.STORY_PREFIXES_RE.test(fragment);
  return typingStory;
}
const _filterUsingElasticSearch = (context, fragment, formattedFragment) => {
  if (!formattedFragment) {
    return false;
  }
  const exactMatch = false;
  const items = [{
    html: '<span class="loading-search-results"><span class="fa fa-spin fa-spinner"></span> Loading...</span>'
  }];
  exports.open({
    context,
    items,
    fragment,
    exactMatch,
    onApply: _.noop
  });
  StorySearchController.queryElasticSearchForResults(formattedFragment, null, (items, nextUrl) => {
    if (typingStory) {
      StorySearchController.addLoadMoreButton(items, nextUrl, null, {
        isDropdown: true
      });
      exports.open({
        context,
        items,
        fragment,
        exactMatch,
        onApply: _applyStory
      });
    } else if (exports.isOpen()) {
      _closeCurrentAutocomplete();
    }
  });
};
exports.openStoryAutocomplete = (context, fragment) => {
  const prefix = _getCurrentStoryPrefix(fragment);
  const formattedFragment = convertAccentedCharacters(fragment.substr(prefix.length).toLowerCase());
  clearTimeout(elasticSearchTimeout);
  elasticSearchTimeout = setTimeout(() => {
    if (typingStory) {
      _filterUsingElasticSearch(context, fragment, formattedFragment);
    }
  }, 300);
  return false;
};
function _applyStory(context, fragment, storyNumber) {
  const formatted = Format.STORY_PREFIXES[0] + storyNumber + ' ';
  _applyValue(context, fragment, formatted);
}
function _getCurrentStoryPrefix(fragment) {
  return _.find(Format.STORY_PREFIXES, prefix => fragment.startsWith(prefix));
}

// Generic

exports.isOpen = () => {
  return !!(dropdown && dropdown.dropdownElement);
};
exports.open = ({
  context,
  items,
  fragment,
  exactMatch,
  onApply,
  className
}) => {
  const isAlreadyOpen = exports.isOpen();
  if (isAlreadyOpen) {
    _closeCurrentAutocomplete();
    DropdownController.isClosing = false;
  }
  if (items.length > 0 && exactMatch === false) {
    dropdown = DropdownController.open({
      items,
      animate: !isAlreadyOpen,
      className,
      onApply: username => {
        onApply(context, fragment, username);
      },
      onKeyDown: e => {
        if (Utils.keyPressed(e, 'DOWN_ARROW')) {
          DropdownController.focusNext(dropdown);
          return false;
        } else if (Utils.keyPressed(e, 'UP_ARROW')) {
          DropdownController.focusPrev(dropdown);
          return false;
        } else if (Utils.keyPressed(e, 'ENTER') || Utils.keyPressed(e, 'TAB')) {
          const focused = dropdown.dropdownElement.find('.item:focus');
          if (focused.length > 0) {
            focused.click();
          } else {
            dropdown.dropdownElement.find('.item').first().click();
          }
          return false;
        }
      },
      onKeyUp: e => {
        if (Utils.keyPressed(e, 'ESCAPE') && !AutocompleteController.isClosing) {
          DropdownController.close(dropdown);
          return false;
        }
      },
      target: context,
      width: 240
    });
  }
};
function _closeCurrentAutocomplete() {
  DropdownController.close(dropdown);
  clearTimeout(elasticSearchTimeout);
}
function _applyValue(context, fragment, value, cursorTransform) {
  const position = selectionStart - fragment.length;
  InPlaceTextareaController.insertValueAndUpdateCustomLinks({
    element: context,
    value,
    caretPosition: position,
    charsToReplace: fragment.length,
    lengthTransform: cursorTransform
  });
}
function _getFragment(context, start, prefixes) {
  return exports.getStringBackToPrefix($(context).val(), start, prefixes);
}
function _notInMiddleOfWord(context, selectionStart) {
  const text = $(context).val();
  const char = text.charAt(selectionStart);
  const char_is_alphanumeric = /[a-zA-Z0-9\u00C0-\u017F_:]/.test(char);
  return !char_is_alphanumeric;
}

// Returns a substring from `text` starting at the nearest of any of the prefix strings
// back from `end` that is preceeded by whitespace or is at the beginning of the text.
// If the text has characters past `end`, they are included up to a non-letter or
// number or the end of the text.
exports.getStringBackToPrefix = (text, end, prefixes) => {
  let range = '';
  let c;
  let i;
  let matching_prefix;

  // grab any forward characters that are numbers/letters
  for (i = end; i < text.length; i++) {
    c = text.charAt(i);
    if (/[a-zA-Z0-9\u00C0-\u017F]/.test(c)) {
      range = range + c;
    } else {
      break;
    }
  }

  // walk back until we hit a prefix or whitespace
  for (i = end - 1; i >= 0; i--) {
    c = text.charAt(i);
    if (/\s/.test(c)) {
      return '';
    }
    range = c + range;
    matching_prefix = _.find(prefixes, prefix => range.startsWith(prefix));
    if (matching_prefix && (i === 0 || /\s/.test(text.charAt(i - 1)))) {
      return range;
    }
  }
  return '';
};
export { exports as default };