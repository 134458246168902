import HelpController from './help';
import Router from '../_frontloader/router';
import SampleWorkspaceController from './sampleWorkspace';
import ShortcutsController from './shortcuts';
import DropdownController from './dropdown';
import HotReloadController from 'app/client/core/js/controllers/hotReload';
import { PAGE_NAMES, generatePathForPageWithSlug } from 'utils/navigation';
const exports = {};
exports.init = () => {
  Router.addRoutesFromControllers([HelpController, ShortcutsController]);
  SampleWorkspaceController.isSampleObserverAsync().then(isSampleObserver => {
    if (isSampleObserver) {
      $('#top-right-nav #settings-link').addClass('disabled');
    }
  });
};
exports.openMobileMenu = function () {
  DropdownController.open({
    className: 'settings-pane',
    header: $('.settings-pane .left-column').html(),
    target: this,
    maxHeight: 200,
    leftOffset: 7,
    topOffset: -40,
    width: 224
  });
  return false;
};
exports.navigateToLabels = () => {
  HotReloadController.loadURL(generatePathForPageWithSlug(PAGE_NAMES.SETTINGS_LABELS), 'labels');
};
exports.navigateToTeams = () => {
  HotReloadController.loadURL(generatePathForPageWithSlug(PAGE_NAMES.SETTINGS_TEAMS), 'teams');
};
exports.navigateToAutomations = () => {
  HotReloadController.loadURL(generatePathForPageWithSlug(PAGE_NAMES.SETTINGS_AUTOMATIONS), 'automations');
};
export { exports as default };