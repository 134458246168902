import SettingsPageController from 'app/client/settings/js/controllers/settingsPage';
import SettingsPageInit from 'app/client/settings/js/modules/settingsInit';
import ManageCardController from 'app/client/settingsShared/js/controllers/manageCard';
import ManageUsersController from 'app/client/settingsShared/js/controllers/manageUsers';
export const openManageUsers = event => {
  event && event.preventDefault();
  ManageUsersController.open();
  return false;
};
export const openManageUsersAtImportTab = event => {
  event && event.preventDefault();
  ManageUsersController.openAtImportTab();
  return false;
};
export const openManageCard = event => {
  event && event.preventDefault();
  ManageCardController.open();
  return false;
};
export const getSubnav = () => SettingsPageController.getSubnav();
export const loadStyles = () => SettingsPageInit.loadStyles();
export const loadLegacySettingsPage = pageController => SettingsPageController.loadLegacySettingsPage(pageController);