import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import Backend from 'app/client/core/js/modules/backend';
export const getServerURL = () => Backend.getServerURL();
export const getGraphQLServerUrl = () => {
  if (window._SHORTCUT_GRAPHQL_SERVER_URL) {
    return window._SHORTCUT_GRAPHQL_SERVER_URL;
  }
  return new URL('/backend', window.location.origin).toString();
};
export const post = (...args) => Backend.post(...args);
export const get = (...args) => Backend.get(...args);