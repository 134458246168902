import UserModel from 'app/client/core/js/models/user'; // eslint-disable-line import/no-restricted-paths
import { useCallback, useState } from 'react';
import { DEFAULT_SKIN_TONE } from '@clubhouse/shared/utils/emoji';
export { DEFAULT_SKIN_TONE } from '@clubhouse/shared/utils/emoji';
export const useSkinTone = () => {
  const [tone, _setSkinTone] = useState(UserModel.getUserProperties().skin_tone || DEFAULT_SKIN_TONE);
  const setSkinTone = useCallback(newTone => {
    UserModel.updateSkinTone(newTone);
    _setSkinTone(newTone);
  }, []);
  return [tone, setSkinTone];
};