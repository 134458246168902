import { getEpochFromOffsetDate } from '@clubhouse/datalayer/utils/getEpochFromOffsetDate';
import { deleteAllKeys } from '@clubhouse/shared/utils';
import { mergeBacklogConnections } from 'pages/backlog/entities/stories/components/table/mergeBacklogConnections'; // eslint-disable-line import/no-restricted-paths

import { parseCurrentUserPermissionSpaceData } from './applicationState';
import { createConnectionMerger, mergeConnections } from './mergeConnections';
const infiniteScrollMerger = createConnectionMerger(variables => {
  if (variables?.infiniteScroll) return variables;
  return null;
});
export const getTypePolicies = (_options = {}) => ({
  Features: {
    merge: true
  },
  EpicStats: {
    merge: true
  },
  ObjectiveStats: {
    merge: true
  },
  SpaceConfig: {
    fields: {
      storiesInGroup: {
        merge: false,
        keyArgs: ['input', ['groupId']]
      }
    }
  },
  StoryEdge: {
    fields: {
      isStale: {
        read(data) {
          return data ?? false;
        }
      }
    }
  },
  SpaceQuery: {
    fields: {
      matchAllFilterPills: {
        merge: false
      },
      matchAnyFilterPills: {
        merge: false
      }
    }
  },
  StoriesPageTabList: {
    fields: {
      tabs: {
        merge: false
      }
    }
  },
  Reportable: {
    fields: {
      // Derives the `reportNowValueEpoch` field from the `reportNowValue` field, by turning an `DateTimeUTCOffset` into an Epoch.
      reportNowValueEpoch: {
        read(_, {
          readField
        }) {
          const reportNowValue = readField('reportNowValue');
          if (!reportNowValue) return null;
          return getEpochFromOffsetDate(reportNowValue);
        }
      }
    }
  },
  Report_LayerDataPoint: {
    fields: {
      // Derives the `sampledAtEpoch` field from the `sampledAt` field, by turning an `DateTimeUTCOffset` into an Epoch.
      sampledAtEpoch: {
        read(_, {
          readField
        }) {
          const sampledAt = readField('sampledAt');
          if (!sampledAt) return null;
          return getEpochFromOffsetDate(sampledAt);
        }
      }
    }
  },
  Workspace2: {
    fields: {
      parsedCurrentUserPermissionSpaceData: {
        read: (data, {
          readField,
          storage
        }) => {
          const currentUserPermissionSpaceData = readField('currentUserPermissionSpaceData');
          if (!currentUserPermissionSpaceData) {
            return currentUserPermissionSpaceData;
          }
          if (!storage[currentUserPermissionSpaceData]) {
            deleteAllKeys(storage);
            try {
              storage[currentUserPermissionSpaceData] = parseCurrentUserPermissionSpaceData(currentUserPermissionSpaceData);
            } catch (e) {
              return null;
            }
          }
          return storage[currentUserPermissionSpaceData];
        }
      },
      stories: {
        keyArgs: ['input', ['where', 'orderBy'], '@connection', ['key']],
        merge: mergeBacklogConnections
      },
      objectives: {
        keyArgs: ['input', ['where', 'orderBy'], '@connection', ['key']],
        merge: infiniteScrollMerger
      },
      categories: {
        keyArgs: ['input', ['where', 'orderBy'], '@connection', ['key']],
        merge: mergeConnections
      }
    }
  },
  Objective: {
    fields: {
      directEpics: {
        keyArgs: false,
        merge: false
      },
      healthHistory: {
        keyArgs: false,
        merge: mergeConnections
      },
      docsSharedToWorkspace2: {
        keyArgs: false,
        merge: infiniteScrollMerger
      }
    }
  },
  Epic: {
    fields: {
      healthHistory: {
        keyArgs: false,
        merge: mergeConnections
      }
    }
  },
  Story: {
    fields: {
      advancedCustomFields: {
        merge: false
      }
    }
  },
  SelectItemsSingleSelectImpl: {
    merge: true
  }
});