import { useEffect } from 'react';
import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { getSubtasksForOptimisticResponse, isDragDisallowed, isSubtaskData } from '../utils';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export function useSubTaskDragMonitor(subtasks, updateSubTaskMutation) {
  useEffect(() => {
    return monitorForElements({
      canMonitor({
        source
      }) {
        return isSubtaskData(source.data);
      },
      onDrop({
        location,
        source
      }) {
        const target = location.current.dropTargets[0];
        if (!target) return;
        const sourceData = source.data;
        const targetData = target.data;
        if (!isSubtaskData(sourceData) || !isSubtaskData(targetData)) return;
        const indexOfSource = subtasks.findIndex(({
          node
        }) => node.id === sourceData.subtaskId);
        const indexOfTarget = subtasks.findIndex(({
          node
        }) => node.id === targetData.subtaskId);
        const sourceSubTask = subtasks.at(indexOfSource);
        const targetSubTask = subtasks.at(indexOfTarget);
        const closestEdgeOfTarget = extractClosestEdge(targetData);
        const disallowDrag = isDragDisallowed(indexOfSource, indexOfTarget, closestEdgeOfTarget);
        if (disallowDrag) return;
        const subtaskNewPosition = closestEdgeOfTarget === 'top' ? 'subTaskPositionBefore' : 'subTaskPositionAfter';
        const updatedSubtasks = getSubtasksForOptimisticResponse(subtasks, indexOfTarget, indexOfSource, closestEdgeOfTarget);
        async function updateSubTaskPosition() {
          if (!sourceSubTask || !targetSubTask) return;
          try {
            await updateSubTaskMutation({
              variables: {
                storyId: sourceSubTask.node.id,
                input: {
                  [subtaskNewPosition]: targetSubTask.node.id
                }
              },
              optimisticResponse: {
                __typename: 'Mutation',
                storyUpdate: {
                  __typename: 'StoryPayload',
                  story: {
                    __typename: 'Story',
                    id: sourceSubTask.node.id,
                    parentStory: {
                      __typename: 'Story',
                      id: sourceSubTask.node.parentStory.id,
                      subTasks: {
                        __typename: 'StoryConnection',
                        edges: updatedSubtasks
                      }
                    }
                  }
                }
              }
            });
          } catch (err) {
            addToast({
              kind: 'alert',
              width: 400,
              timeout: 5000,
              Content: () => _jsx(ToastText, {
                children: err instanceof Error ? err.message : ''
              })
            });
          }
        }
        updateSubTaskPosition();
      }
    });
  }, [subtasks, updateSubTaskMutation]);
}