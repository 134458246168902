import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import memoizer from 'lru-memoizer';
import MessageBubblePng from '@clubhouse/assets/png/empty-activity.png';
import { Icon } from '@clubhouse/shared/components/Icons';
import { DEFAULT_BOARD_TITLE, getMiroEmbedMetaData } from '@clubhouse/shared/utils/miroLiveEmbed';
import * as EpicGroupsUpdateTemplate from 'app/client/core/views/templates/activity/epicGroupsUpdate.html';
import * as EpicHealthStatusUpdateTemplate from 'app/client/core/views/templates/activity/epicHealthStatusUpdate.html';
import * as ImportUpdatedTemplate from 'app/client/core/views/templates/activity/importUpdated.html';
import * as IterationUpdateGroupTemplate from 'app/client/core/views/templates/activity/iterationUpdateGroup.html';
import * as ProjectCreateDeleteTemplate from 'app/client/core/views/templates/activity/projectCreateDelete.html';
import * as ProjectUpdateArchivedTemplate from 'app/client/core/views/templates/activity/projectUpdateArchived.html';
import * as ProjectUpdateNameTemplate from 'app/client/core/views/templates/activity/projectUpdateName.html';
import * as StoryArchivedUpdateTemplate from 'app/client/core/views/templates/activity/storyArchivedUpdate.html';
import * as StoryBlockingUpdateTemplate from 'app/client/core/views/templates/activity/storyBlockingUpdate.html';
import * as StoryCommentCreateDeleteTemplate from 'app/client/core/views/templates/activity/storyCommentCreateDelete.html';
import * as StoryCreateDeleteTemplate from 'app/client/core/views/templates/activity/storyCreateDelete.html';
import * as StoryCustomFieldsWrapperTemplate from 'app/client/core/views/templates/activity/storyCustomFieldsWrapper.html';
import * as StoryCustomFieldValuesAddTemplate from 'app/client/core/views/templates/activity/storyCustomFieldValuesAdd.html';
import * as StoryCustomFieldValuesRemoveTemplate from 'app/client/core/views/templates/activity/storyCustomFieldValuesRemove.html';
import * as StoryCustomFieldValuesUpdateTemplate from 'app/client/core/views/templates/activity/storyCustomFieldValuesUpdate.html';
import * as StoryDeadlineUpdateTemplate from 'app/client/core/views/templates/activity/storyDeadlineUpdate.html';
import * as StoryDescriptionUpdateTemplate from 'app/client/core/views/templates/activity/storyDescriptionUpdate.html';
import * as StoryEpicUpdateTemplate from 'app/client/core/views/templates/activity/storyEpicUpdate.html';
import * as StoryEstimateUpdateTemplate from 'app/client/core/views/templates/activity/storyEstimateUpdate.html';
import * as StoryFileUpdateTemplate from 'app/client/core/views/templates/activity/storyFileUpdate.html';
import * as StoryGroupUpdateTemplate from 'app/client/core/views/templates/activity/storyGroupUpdate.html';
import * as StoryIterationUpdateTemplate from 'app/client/core/views/templates/activity/storyIterationUpdate.html';
import * as StoryLinkCreateDeleteTemplate from 'app/client/core/views/templates/activity/storyLinkCreateDelete.html';
import * as StoryNameUpdateTemplate from 'app/client/core/views/templates/activity/storyNameUpdate.html';
import * as StoryProjectUpdateTemplate from 'app/client/core/views/templates/activity/storyProjectUpdate.html';
import * as StoryUsersUpdateTemplate from 'app/client/core/views/templates/activity/storyUsersUpdate.html';
import * as StoryWorkflowStateMoveTemplate from 'app/client/core/views/templates/activity/storyWorkflowStateMove.html';
import * as TaskCompleteUpdateTemplate from 'app/client/core/views/templates/activity/taskCompleteUpdate.html';
import * as TaskCreateDeleteTemplate from 'app/client/core/views/templates/activity/taskCreateDelete.html';
import * as TaskDescriptionUpdateTemplate from 'app/client/core/views/templates/activity/taskDescriptionUpdate.html';
import * as TaskOwnersUpdateTemplate from 'app/client/core/views/templates/activity/taskOwnersUpdate.html';
import * as UserJoinedTemplate from 'app/client/core/views/templates/activity/userJoined.html';
import * as EpicAutoLinkTemplate from 'app/client/core/views/templates/epicAutoLink.html';
import * as NotificationDialogTemplate from 'app/client/core/views/templates/notificationDialog.html';
import * as NotificationFeedTemplate from 'app/client/core/views/templates/notificationFeed.html';
import * as NotificationFooterTemplate from 'app/client/core/views/templates/notificationFooter.html';
import * as NotificationItemTemplate from 'app/client/core/views/templates/notificationItem.html';
import * as NotificationTabsTemplate from 'app/client/core/views/templates/notificationTabs.html';
import * as StoryAutoLinkWithNameTemplate from 'app/client/core/views/templates/storyAutoLinkWithName.html';
import { getById as getWorkflowById } from 'data/entity/workflow';
import { PAGE_NAMES, getCurrentPage } from 'utils/navigation';
import DropdownController from './dropdown';
import HotReloadController from './hotReload';
import MessageController from './message';
import MiroController from './miro';
import SampleWorkspaceController from './sampleWorkspace';
import StoryDialogController from './storyDialog';
import { EVENTS, logEvent } from '../../../../../utils/monitoring';
import * as Event from '../_frontloader/event';
import ActivityModel from '../models/activity';
import ColumnModel from '../models/column';
import EpicModel from '../models/epic';
import EpicStateModel from '../models/epicState';
import FeatureModel from '../models/feature';
import GroupModel from '../models/group';
import IterationModel from '../models/iteration';
import MilestoneModel from '../models/milestone';
import ProfileModel from '../models/profile';
import ProjectModel from '../models/project';
import StoryModel from '../models/story';
import StoryLinkModel from '../models/storyLink';
import UserModel from '../models/user';
import ApplicationState from '../modules/applicationState';
import BrowserNotifications from '../modules/browserNotifications';
import Constants from '../modules/constants';
import FloatingCaption from '../modules/floatingCaption';
import Format from '../modules/format';
import Is from '../modules/is';
import { intersection } from '../modules/setUtils';
import SideDrawer from '../modules/sideDrawer';
import Url from '../modules/url';
import Utils from '../modules/utils';
import View from '../modules/view';
import { ReactionCreate, EpicDescription, EpicName, EpicCreateDelete, EpicCommentCreateDelete, TeamUpdateMembers, EpicStateUpdate, EpicArchiveUpdate, EpicRequesterUpdate } from 'components/activity';
import { ObjectiveAutoLink } from 'components/shared/ObjectiveAutoLink';
import { renderComponentToString } from 'utils/helpers';
import { LoadingDots } from '@clubhouse/shared/animations';
import { Center } from '@clubhouse/shared/components/Center';
import _ from 'lodash';
import moment from 'moment';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};
const BADGE_SELECTOR = '#notifications-link .badge';
const RAVEN_BADGE_SELECTOR = '#notifications-link [data-notification-badge]';
const SIDE_DRAWER_SELECTOR = '.side-drawer';
const CONTAINER_SELECTOR = '[data-notification-dialog]';
const FEED_SELECTOR = '[data-notification-feed]';
const DASHBOARD_PARENT_SELECTOR = '.dashboard-activity-feed';
const VISIBLE_CLASS = 'visible';
const NS = '.NotificationsController'; // Event namespace.
const ACTIVITY_FILTERS = [{
  id: 'all',
  name: 'All Activity',
  shortName: 'All Activity'
}, {
  id: 'comments',
  name: 'Comments',
  shortName: 'Comments'
}, {
  id: 'mentions',
  name: 'Mentions',
  shortName: 'Mentions'
}];
const NOTIFICATION_CONTENT_SELECTOR = '[data-notification-content]';
let isLoadingInitially = true;
let isLoadingMore = false;
let noResultsCount = 0;
function getFeedElement() {
  const sideDrawer = $(SIDE_DRAWER_SELECTOR);
  return sideDrawer.length ? sideDrawer.find(FEED_SELECTOR) : $(FEED_SELECTOR);
}
function getContainerElement() {
  const sideDrawer = $(SIDE_DRAWER_SELECTOR);
  return sideDrawer.length ? sideDrawer.find(CONTAINER_SELECTOR) : $(CONTAINER_SELECTOR);
}
exports.renderObjectiveAutoLink = props => {
  return renderComponentToString(_jsx(ObjectiveAutoLink, {
    ...props
  }));
};
exports.init = () => {
  isLoadingInitially = true;
  isLoadingMore = false;
  noResultsCount = 0;
  if (getCurrentPage() === PAGE_NAMES.DASHBOARD) ActivityModel.fetchSinceLastCheck();
  Event.onlyOn('pageDestroy' + NS, () => {
    exports.destroyFloatingCaption();
    exports.destroyInfiniteScrolling();
  });
  Event.onlyOn('newMentions' + NS, mentions => {
    if (!exports.isOpen()) {
      exports.showMessages(mentions);
      // We don't want to update the viewed activity timestamp here, because the user may
      // have missed the mention message (since it goes away on its own after a few seconds).

      if (_getBadgeTrigger() === 'mentions') {
        exports.showBadge();
      }
    }
  });
  Event.onlyOn('newComments' + NS, comments => {
    const me = ProfileModel.getCurrentUserProfile();
    const meId = me?.permissions?.[0]?.id || null;
    const commentNotByMe = _.some(comments, comment => {
      return comment.member_id !== meId;
    });
    if (!exports.isOpen() && commentNotByMe && _getBadgeTrigger() !== 'mentions') {
      exports.showBadge();
    }
    setTimeout(() => {
      const miroEmbedCommentElems = document.querySelectorAll('.notification.comment .miro-container .miro-iframe');
      miroEmbedCommentElems.forEach(el => {
        MiroController.shouldShowPreview() ? el.classList.remove('collapsed') : el.classList.add('collapsed');
      });
    }, 75);
  });
  Event.onlyOn('importUpdates' + NS, importList => {
    importList.forEach(i => {
      i.actions.forEach(a => {
        const status = a.changes && a.changes.import_status ? a.changes.import_status.new : '';
        if (a.action === 'update' && (status === 'waiting-for-mapping' || status === 'done') && !ActivityModel.getViewedImport(a.id, status)) {
          ActivityModel.updateViewedImport(a.id, status);
          const html = _formatTemplateContent(i, {});
          MessageController.success(html, {
            user: {},
            timeout: 12000
          });
        }
      });
    });
  });
  Event.onlyOn('storiesLookedUp' + NS, () => {
    exports.redrawFeed();
  });
  Event.onlyOn('noNewActivity' + NS, () => {
    exports.hideBadge();
  });
  Event.onlyOn('activityFetched' + NS, () => {
    isLoadingInitially = false;
  });
  SampleWorkspaceController.isSampleObserverAsync().then(isSampleObserverAsync => {
    if (isSampleObserverAsync) {
      $('#top-right-nav #notifications-link').addClass('disabled');
    }
  });
};
exports.getActivityTemplatesForDay = (activity, day) => {
  return _.compact(_.map(_.filter(activity, {
    day
  }), memoizedGetTemplateForChange));
};

/**
 * Be careful if you call this function, it is expensive (mostly `_formatTemplateContent`)
 * and this is likely to get called multiple times. Consider using `memoizedGetTemplateForChange`.
 */
function _getTemplateForChange(change, i) {
  const profile = ProfileModel.getAllDetailsById(change.member_id) || {};
  const name = Format.fullName(profile, undefined, change.automation_id ? 'Automation' : undefined);
  const content = _formatTemplateContent(change, profile);
  const changed = moment(change.changed_at);
  return content ? NotificationItemTemplate.render({
    changedDate: changed.format(Constants.LONG_DATE_TIME_NO_YEAR),
    changedHour: changed.format(Constants.TIME_FORMAT),
    classNames: _getClassesForChange(change, i).join(' '),
    name,
    id: change.id,
    automation: !!change.automation_id,
    profile,
    html: content
  }) : '';
}
exports.renderLoading = () => {
  return View.renderComponentDelayed({
    componentKey: 'notifications-loading',
    component: () => _jsx(Center, {
      children: _jsx(LoadingDots, {})
    })
  }).html;
};

/**
 * `_getTemplateForChange` is expensive (mostly `_formatTemplateContent`) and is likely to get called multiple times.
 * We should refactor the activity feed to use React and be smarter about re-rendering, but for now we memoize.
 *
 * The following implementation relies on a LRU cache that will hold 100 entries. It is an arbitrary number.
 */
const memoizedGetTemplateForChange = memoizer.sync({
  load: (...args) => {
    return _getTemplateForChange(...args);
  },
  hash: (change, i) => `${change.id}:${i}`,
  max: 100
});
function _getClassesForChange(change, count) {
  const classNames = ['notification'];
  if (count === 0) {
    classNames.push('first-of-day');
  }
  if (change.isComment) {
    classNames.push('comment');
  }
  if (change.isMention || change.isGroupMention) {
    classNames.push('mention');
  }
  if (change.userJoined) {
    classNames.push('user-joined');
  }
  if (_.some(change.actions, ActivityModel.isMilestone)) {
    classNames.push('milestone');
  }
  return classNames;
}
function _hasNewMentionedTask(change) {
  return change.actions.some(action => action.action === 'create' && action.entity_type === 'story-task' && action.mention_ids?.length);
}
function _isTaskNotifHandledByUpdateAction(change, action) {
  const hasMentionedTask = action.mention_ids?.length;
  const notificationHandledByUpdateAction = hasMentionedTask && Boolean(change.actions.find(el => el.action === 'update' && el.changes.task_ids?.adds?.includes(action.id)));
  return notificationHandledByUpdateAction;
}
function _formatTemplateContent(change, profile) {
  const name = Format.fullName(profile, undefined, change.automation_id ? 'Automations' : undefined);
  const hasNewMentionedTask = _hasNewMentionedTask(change);
  const htmlSegments = _.compact(_.map(change.actions, action => {
    const type = action.action;
    const changes = action.changes || {};
    const ref = ActivityModel.getReference(change, action.id);
    const storyRef = ActivityModel.getReference(change, action.story_id); // Won't always exist
    const refType = ref.entity_type;
    let text = '';
    if (type === 'update') {
      if (action.entity_type === 'health') {
        text = _formatHealthUpdate(change, action, name);
      } else if (refType === 'story-comment') {
        text = _formatStoryComment(change, action, name);
      } else if (refType === 'epic-comment') {
        text = _formatEpicComment(change, action, name);
      }
      if (changes.workflow_state_id) {
        text = exports.formatStoryWorkflowStateMove({
          change,
          action,
          story: ref,
          name
        });
      } else if (changes.epic_id) {
        text = exports.formatStoryEpicUpdate({
          change,
          action,
          historyModel: ActivityModel,
          story: ref,
          name
        });
      } else if (changes.project_id) {
        text = exports.formatStoryProjectUpdate({
          change,
          action,
          historyModel: ActivityModel,
          story: ref,
          name
        });
      } else if (changes.iteration_id) {
        text = exports.formatStoryIterationUpdate({
          change,
          action,
          historyModel: ActivityModel,
          story: ref,
          name
        });
      } else if (changes.name) {
        if (refType === 'story') {
          text = exports.formatStoryName({
            change,
            action,
            modelName: 'Activity',
            story: ref,
            name
          });
        } else if (refType === 'epic') {
          text = _formatEpicName(changes, ref, name);
        } else if (refType === 'project') {
          text = _formatProjectName(changes, ref, name);
        }
      } else if (changes.description) {
        if (refType === 'story') {
          text = exports.formatStoryDescription({
            change,
            action,
            modelName: 'Activity',
            story: ref,
            name
          });
        } else if (refType === 'story-task') {
          text = exports.formatTaskDescription(change, changes.description, storyRef, name);
        } else if (refType === 'epic') {
          text = _formatEpicDescription(change, changes.description, ref, name);
        }
      } else if (changes.blocked) {
        text = exports.formatStoryBlocking(changes.blocked, ref, name);
      } else if (changes.deadline) {
        text = exports.formatStoryDeadline(changes.deadline, ref, name);
      } else if (changes.archived) {
        if (refType === 'story') {
          text = exports.formatStoryArchived(changes.archived, ref, name);
        } else if (refType === 'epic') {
          text = _formatEpicArchived(changes.archived, ref, name);
        } else if (refType === 'project') {
          text = _formatProjectArchived(changes.archived, ref, name);
        }
      } else if (changes.estimate) {
        text = exports.formatStoryEstimate(changes.estimate, ref, name);
      } else if (changes.complete) {
        if (refType === 'story-task') {
          text = exports.formatTaskComplete({
            change,
            action,
            historyModel: ActivityModel,
            story: storyRef,
            name
          });
        }
      } else if (changes.owner_ids) {
        if (refType === 'story-task') {
          text = exports.formatTaskOwners({
            change,
            action,
            historyModel: ActivityModel,
            story: storyRef,
            name
          });
        } else {
          text = exports.formatStoryUsers({
            change,
            changeMap: changes.owner_ids,
            pluralMap: {
              multiple: 'owners',
              singular: 'an owner'
            },
            story: ref,
            name
          });
        }
      } else if (changes.task_ids) {
        text = _formatStoryTaskUpdate(change, action, ref, name);
      } else if (changes.file_ids) {
        text = _formatStoryFileUpdate(change, changes.file_ids, ref, name);
      } else if (changes.epic_state_id) {
        text = _formatEpicState(changes.epic_state_id, ref, name);
      } else if (changes.requested_by_id) {
        if (refType === 'epic') {
          text = _formatEpicRequester(changes.requested_by_id.new, ref, name);
        }
      } else if (changes.group_ids) {
        if (refType === 'iteration') {
          text = _formatIterationGroups(changes.group_ids, ref, name);
        } else if (refType === 'epic') {
          text = _formatEpicGroups(changes.group_ids, ref, name);
        }
      } else if (changes.group_id) {
        if (refType === 'story') {
          text = exports.formatStoryGroupUpdate({
            change: changes.group_id,
            action,
            story: ref,
            name
          });
        }
      } else if (changes.group_member_ids) {
        if (refType === 'group') {
          text = _formatGroupMemberChanges(changes.group_member_ids, ref, name);
        }
      } else if (changes.import_status) {
        text = _formatImportUpdated(changes.import_status, ref);
      } else if (changes.custom_field_value_ids) {
        text = exports.formatStoryCustomFieldValuesUpdate({
          change,
          action,
          story: ref,
          name
        });
      }
    } else if (type === 'create') {
      if (action.entity_type === 'health') {
        text = _formatHealthUpdate(change, action, name);
      } else if (refType === 'story' && !hasNewMentionedTask) {
        text = exports.formatStoryCreateDelete(change, ref, type, name);
      } else if (refType === 'story-task') {
        const notificationHandledByUpdate = _isTaskNotifHandledByUpdateAction(change, action);
        if (!notificationHandledByUpdate) {
          text = exports.formatTaskCreateDelete({
            change,
            task: ref,
            type,
            /** If the @mentioned task is being created simultaneously with a new story
             *  we need to use the story ref of the newly created story so that the new @mentioned task toast msg
             *  references the new story correctly and not the "Unknown Story" placeholder.
             **/
            story: change.actions.some(action => action.action === 'create' && action.entity_type === 'story') ? ActivityModel.getReference(change, change.actions.find(action => action.action === 'create' && action.entity_type === 'story')?.id) : storyRef,
            name
          });
        }
      } else if (refType === 'story-link') {
        text = exports.formatStoryLinkCreateDelete({
          change,
          storyLink: ref,
          type,
          historyModel: ActivityModel,
          name
        });
      } else if (refType === 'story-comment') {
        text = _formatStoryComment(change, action, name);
      } else if (refType === 'epic') {
        text = _formatEpicCreateDelete(change, ref, type, name);
      } else if (refType === 'epic-comment') {
        text = _formatEpicComment(change, action, name);
      } else if (refType === 'project') {
        text = _formatProjectCreateDelete(ref, type, name);
      } else if (refType === 'reaction') {
        text = _formatReactionCreated(change, ref, name);
      }
    } else if (type === 'delete') {
      if (refType === 'story') {
        text = exports.formatStoryCreateDelete(change, ref, type, name);
      } else if (refType === 'story-task') {
        text = exports.formatTaskCreateDelete({
          change,
          task: ref,
          type,
          story: storyRef,
          name
        });
      } else if (refType === 'story-link') {
        text = exports.formatStoryLinkCreateDelete({
          change,
          storyLink: ref,
          type,
          historyModel: ActivityModel,
          name
        });
      } else if (refType === 'epic') {
        text = _formatEpicCreateDelete(change, ref, type, name);
      } else if (refType === 'project') {
        text = _formatProjectCreateDelete(ref, type, name);
      }
    } else if (type === 'join-via-domain') {
      text = _formatUserJoinedWorkspace(name);
    }
    return text;
  }));
  return Utils.grammaticalJoin(htmlSegments);
}
exports.showMessages = changes => {
  changes.forEach(change => {
    if (ActivityModel.alreadyViewedMentionNotification(change)) {
      return;
    }
    const profile = ProfileModel.getAllDetailsById(change.member_id);
    if ((change.isMention || change.isGroupMention) && !UserModel.isLoggedInProfile(profile)) {
      ActivityModel.updateLastViewedMention();
      if (BrowserNotifications.notDisabled()) {
        BrowserNotifications.message({
          title: _getNotificationTitle(change, profile),
          body: _getNotificationBody(change),
          onClick: () => {
            _goToEntityUrl(change);
            try {
              window.focus();
            } catch {}
          },
          user: profile,
          fallback: sendMessage.bind(null, change)
        });
      } else {
        sendMessage(_.assign(change, {
          user: profile
        }));
      }
    }
  });
  function sendMessage(change) {
    const profile = ProfileModel.getAllDetailsById(change.member_id) || {};
    const html = _formatTemplateContent(change, profile);
    MessageController.info(html, {
      user: profile,
      timeout: 12000
    });
  }
};
function _getNotificationTitle(change, profile) {
  return Format.fullName(profile) + ' mentioned you in ' + _getNotificationType(change);
}
function _getNotificationBody(change) {
  let body = '';
  if (_isStoryDescriptionMention(change) || _isEpicDescriptionMention(change)) {
    const descChange = _getEntityForChange(change);
    if (_.get(descChange, 'action') === 'update') {
      body = _.get(descChange, 'changes.description.new', '');
    } else {
      body = _.get(descChange, 'description', '');
    }
  } else {
    body = _.get(_getCommentFromChange(change), 'text', '');
  }
  return body;
}
function _getCommentFromChange(change) {
  let type;
  if (ActivityModel.isStoryComment(change)) {
    type = 'story-comment';
  } else if (ActivityModel.isEpicComment(change)) {
    type = 'epic-comment';
  } else if (ActivityModel.isHealth(change)) {
    type = 'health';
  }
  return _.find(change.actions, {
    entity_type: type
  });
}
function _getEntityForChange(change) {
  let type;
  if (ActivityModel.isStoryComment(change) || ActivityModel.isStoryMention(change) || ActivityModel.isStoryGroupMention(change)) {
    type = 'story';
  } else if (ActivityModel.isEpicComment(change) || ActivityModel.isEpicMention(change)) {
    type = 'epic';
  }
  return _.find(change.actions, {
    entity_type: type
  });
}
function _getNotificationType(change) {
  if (_isStoryDescriptionMention(change)) {
    return 'a Story';
  } else if (_isEpicDescriptionMention(change)) {
    return 'an Epic';
  } else if (ActivityModel.isHealth(change)) {
    return 'a health comment';
  } else {
    return 'a comment';
  }
}
function _goToEntityUrl(change) {
  const entity = _getEntityForChange(change);
  const comment = _getCommentFromChange(change);
  if (ActivityModel.isStoryComment(change) || ActivityModel.isStoryMention(change) || ActivityModel.isStoryGroupMention(change)) {
    StoryModel.fetchStory(entity.id, (err, story) => {
      if (story) {
        StoryDialogController.loadStory(story, _.get(comment, 'id'));
      }
    });
  } else if (ActivityModel.isEpicComment(change) || ActivityModel.isEpicMention(change) || ActivityModel.isEpicGroupMention(change)) {
    HotReloadController.loadURL(EpicModel.generateURL(entity, _.get(comment, 'id')), 'epics');
  } else if (ActivityModel.isHealth(change)) {
    // TODO: Where do we navigate to? Epic? Roadmap?
    HotReloadController.loadURL(Url.getSlugPath() + '/roadmap', 'roadmap');
  }
}
function _isStoryDescriptionMention(change) {
  return ActivityModel.isStoryMention(change) && !_getCommentFromChange(change);
}
function _isEpicDescriptionMention(change) {
  return ActivityModel.isEpicMention(change) && !_getCommentFromChange(change);
}
exports.open = () => {
  let badge, badgeVisible;
  if (document.body.classList.contains('using-raven-nav')) {
    badge = document.querySelector(RAVEN_BADGE_SELECTOR);
    badgeVisible = badge && badge.dataset.visible === 'true';
  } else {
    badge = $(BADGE_SELECTOR);
    badgeVisible = badge.length > 0 && badge.hasClass(VISIBLE_CLASS);
  }
  logEvent(EVENTS.ActivityFeed_Opened, {
    badge_visible: `${badgeVisible}`,
    current_filter: ActivityModel.getFilter()
  });
  if (SampleWorkspaceController.isSampleObserver()) {
    return false;
  }
  ActivityModel.resetLookback();
  View.freezeBodyOverflow();
  SideDrawer.render({
    innerHTML: exports.renderFeedTemplate(),
    width: 'var(--sideDrawerWidthMedium)',
    overflow: 'hidden',
    onOpen: () => {
      Event.onlyOn('resize' + NS, exports.onResize);
      Event.onlyOn('newActivityFetched' + NS, exports.redrawFeed);
      exports.initInfiniteScrolling();
      exports.onResize();
      exports.hideBadge();
      ActivityModel.updateViewedActivityTimestamp();
      isLoadingMore = false;
    },
    onOpenEnd: () => {
      exports.initFloatingCaption();
    },
    onClose: () => {
      ActivityModel.updateViewedActivityTimestamp();
      View.unfreezeBodyOverflow();
      Event.off('resize' + NS);
      Event.off('newActivityFetched' + NS);
      exports.destroyFloatingCaption();
      exports.destroyInfiniteScrolling();
    }
  });
  return false;
};
let infiniteScrollIntervalID = null;
exports.initInfiniteScrolling = () => {
  infiniteScrollIntervalID = setInterval(pollToLoadMore, 3000);
};
function pollToLoadMore() {
  if (isLoadingMore) {
    return;
  }
  const isVisible = isLoadingInitially || endOfActivityIsVisible();
  if (isVisible) {
    if (exports.hasRecentActivity()) {
      exports.showMore();
    } else {
      _displayGivingUpMessage();
      exports.destroyInfiniteScrolling();
    }
  }
}
function endOfActivityIsVisible() {
  const feed = getFeedElement();
  if (feed.length) {
    const contentHeight = feed.find('.notifications').height() - feed.height();
    return feed.scrollTop() > contentHeight;
  }
  return true;
}
function _displayGivingUpMessage() {
  const html = `<p class="limit-reached">We haven\'t found any additional activity. Once you start creating
    and making changes to Stories${FeatureModel.isProjectsFeatureEnabled() ? ', Projects,' : ''} and Epics, that activity will appear here.</p>`;
  getFeedElement().find('.loading-activity').html(html);
}
exports.destroyInfiniteScrolling = () => {
  clearInterval(infiniteScrollIntervalID);
};
const _getScrollbarWidth = () => {
  const feed = getFeedElement().get(0);
  const caption = getContainerElement().find('.caption').get(0);
  if (feed && caption) {
    return feed.offsetWidth - caption.clientWidth;
  }
  return 0;
};
exports.initFloatingCaption = () => {
  FloatingCaption.init({
    context: getFeedElement(),
    getWidth: () => `calc(100% - ${_getScrollbarWidth()}px)`,
    usePosition: true,
    topOffset: 0
  });
};
exports.destroyFloatingCaption = () => {
  FloatingCaption.destroy(getFeedElement());
};
exports.renderFeedTemplate = options => {
  return NotificationDialogTemplate.render(compileFeedDataForTemplate(options));
};
exports.reRenderFeedTemplate = (options, parentElement) => {
  const data = compileFeedDataForTemplate(options);
  parentElement.find('.tabs').html(NotificationTabsTemplate.render(data));
  parentElement.find('.notification-footer').html(NotificationFooterTemplate.render(data));
  const newFeedChildren = $(NotificationFeedTemplate.render(data)).children();
  const feedParent = parentElement.find('.notifications');
  const feedChildren = feedParent.children();
  const elementsToAppend = [];
  newFeedChildren.each((n, el) => {
    const oldEl = feedChildren[n];
    if (!oldEl) {
      elementsToAppend.push(el);
    } else {
      const id = Utils.data(el, 'id');
      const oldID = Utils.data(oldEl, 'id');
      if (!id || !oldID || id !== oldID || hasStoryLinkUpdate(el, oldEl)) {
        View.replaceElement(oldEl, el);
      }
    }
  });
  if (elementsToAppend.length > 0) {
    feedParent.append(elementsToAppend);
  }
};
function hasStoryLinkUpdate(el, oldEl) {
  // Handle cases where the comment has a link to a story which was just fetched
  return !el.querySelector(NOTIFICATION_CONTENT_SELECTOR)?.isEqualNode(oldEl.querySelector(NOTIFICATION_CONTENT_SELECTOR));
}
function compileFeedDataForTemplate(options) {
  options = options || {};
  const currentFilter = ActivityModel.getFilter();
  const includeOwnActivity = _getOwnActivityFilter();
  const activity = ActivityModel.getPageActivity(currentFilter, includeOwnActivity);
  const hasActivity = !!ActivityModel.getActivityFeedData().length;
  const hasRecentActivity = exports.hasRecentActivity();
  return _.assign({
    isLoadingInitially,
    activity,
    hasActivity,
    hasRecentActivity,
    currentFilter,
    activityFilters: ACTIVITY_FILTERS,
    timeRange: ActivityModel.getTimeRange(),
    noActivityImage: MessageBubblePng
  }, options);
}
exports.redrawFeed = options => {
  setTimeout(() => {
    const miroTitleElems = document.querySelectorAll('.title-link');
    miroTitleElems.forEach(el => {
      getMiroEmbedMetaData(el.href).then(metaData => {
        el.textContent = metaData.title;
      }).catch(() => {
        el.textContent = DEFAULT_BOARD_TITLE;
      });
    });
  }, 25);
  if (exports.isOpen()) {
    exports.redrawPanelFeed(options);
  }
  if (exports.dashboardFeedExists()) {
    exports.redrawDashboardFeed(options);
  }
};
exports.redrawPanelFeed = options => {
  const feed = getFeedElement();
  const notAlreadyRendered = feed.find('.notifications').length === 0;
  if (options && options.forceRedraw === true || notAlreadyRendered) {
    const existingScrollTop = feed.scrollTop();
    exports.destroyFloatingCaption();
    const html = exports.renderFeedTemplate(options);
    getContainerElement().get(0).innerHTML = html;
    exports.onResize();
    exports.initFloatingCaption();
    feed.scrollTop(existingScrollTop);
  } else {
    exports.reRenderFeedTemplate(options, feed);
  }
};
exports.dashboardFeedExists = () => {
  return $(DASHBOARD_PARENT_SELECTOR).length > 0;
};
exports.redrawDashboardFeed = options => {
  const notAlreadyRendered = $(DASHBOARD_PARENT_SELECTOR).find('.notification-feed').length === 0;
  if (options && options.forceRedraw === true || notAlreadyRendered) {
    const html = exports.renderFeedTemplate({
      showButton: true
    });
    $(DASHBOARD_PARENT_SELECTOR).html(html);
  } else {
    exports.reRenderFeedTemplate({
      showButton: true
    }, $(DASHBOARD_PARENT_SELECTOR));
  }
  Event.trigger('resize');
};
exports.showMore = function () {
  if (isLoadingMore) {
    return false;
  }
  isLoadingMore = true;
  ActivityModel.advanceLookback();
  View.changeButtonToSaving(this, 'Loading');
  const oldest = ActivityModel.getOldestActivityTimestamp();
  const lookback = ActivityModel.getLookback();
  if (oldest < lookback) {
    exports.redrawFeed();
    isLoadingMore = false;
    pollToLoadMore();
  } else {
    ActivityModel.fetchMoreActivity(err => {
      if (err && err.limit) {
        _showLimitMessage();
      } else {
        exports.redrawFeed();
      }
      if (err && err.noResults) {
        noResultsCount++;
      } else {
        noResultsCount = 0;
      }
      isLoadingMore = false;
      pollToLoadMore();
    });
  }
};
exports.fetchAndRenderInitialActivity = () => {
  ActivityModel.fetchLastDay(err => {
    if (err && err.limit) {
      _showLimitMessage();
    } else {
      exports.redrawFeed();
    }
  });
};
exports.hasRecentActivity = () => {
  return noResultsCount < 10;
};
function _showLimitMessage() {
  const formattedDate = moment(ActivityModel.LIMIT).format(Constants.SHORT_DATE_FORMAT);
  const html = '<p class="limit-reached">Activity not available before ' + formattedDate + '</p>';
  getFeedElement().find('.show-more').html(html);
}
exports.hideBadge = () => {
  if (document.body.classList.contains('using-raven-nav')) {
    document.querySelector(RAVEN_BADGE_SELECTOR)?.setAttribute('data-visible', 'false');
  } else {
    const parent = $(BADGE_SELECTOR);
    if (parent.length > 0 && parent.hasClass(VISIBLE_CLASS)) {
      // Ref: https://help.shortcut.com/agent/tickets/2370

      parent.removeClass(VISIBLE_CLASS);
    }
  }
};
exports.showBadge = () => {
  if (document.body.classList.contains('using-raven-nav')) {
    document.querySelector(RAVEN_BADGE_SELECTOR)?.setAttribute('data-visible', 'true');
  } else {
    const parent = $(BADGE_SELECTOR);
    if (parent.length > 0 && !parent.hasClass(VISIBLE_CLASS)) {
      // Ref: https://help.shortcut.com/agent/tickets/2370
      // Log.log('Showing new activity badge', { numberOfComments: numberOfComments });

      parent.addClass(VISIBLE_CLASS);
    }
  }
};
exports.isOpen = () => !!getContainerElement().length;
exports.onResize = () => {
  const paddingBottom = 0;
  const element = $(SIDE_DRAWER_SELECTOR).find(FEED_SELECTOR);
  if (element.length) {
    const win = $(window);
    const minHeight = win.height() + win.scrollTop() - paddingBottom - element.offset().top;
    element.css({
      minHeight
    });
  }
};
exports.close = () => {
  SideDrawer.close();
};
function _getBadgeTrigger() {
  return ApplicationState.get('Notifications.BadgeTrigger') || 'comments';
}
function _setBadgeTrigger(name) {
  return ApplicationState.set('Notifications.BadgeTrigger', name);
}
function _showBadgeForAllComments() {
  _setBadgeTrigger('comments');
  exports.redrawFeed();
  return false;
}
function _showBadgeForMentionsOnly() {
  _setBadgeTrigger('mentions');
  exports.redrawFeed();
  return false;
}
function _getOwnActivityFilter() {
  return ApplicationState.get('Notifications.OwnActivityFilter') || 'show';
}
function _setOwnActivityFilter(setting) {
  return ApplicationState.set('Notifications.OwnActivityFilter', setting);
}
function _toggleIncludeMyOwnChanges() {
  const setting = _getOwnActivityFilter();
  _setOwnActivityFilter(setting === 'show' ? 'hide' : 'show');
  exports.redrawFeed();
  return false;
}

// Template Helpers

function _formatStoryNameForTemplate(story, {
  commentId
} = {}) {
  return story ? StoryAutoLinkWithNameTemplate.render({
    ...story,
    commentId
  }) : 'this story';
}
exports.renderFieldsIcon = data => {
  return View.renderComponentDelayed({
    componentKey: `field-icon-${data.id}-${data.story?.id}`,
    component: Icon.Field,
    cssClass: 'change-icon-svg',
    props: {
      fill: 'currentColor',
      width: '12px'
    }
  }).html;
};
function _decorateWithUrl(change, model, id, historyModel) {
  const entity = ActivityModel.getReference(change, id, historyModel);
  return _.assign({}, entity, {
    url: model.generateURL(entity)
  });
}
function _decorateEpic(change, id, historyModel) {
  const epic = EpicModel.getById(id);
  return epic ? _.assign({}, epic, {
    link: EpicAutoLinkTemplate.render(epic)
  }) : _decorateWithUrl(change, EpicModel, id, historyModel);
}
const getIteration = (change, id, historyModel) => {
  let iteration = IterationModel.getById(id);
  if (!iteration) {
    iteration = ActivityModel.getReference(change, id, historyModel);
  }
  return iteration;
};
const getGroup = (change, id, historyModel) => {
  let group = GroupModel.getById(id);
  if (!group) {
    group = ActivityModel.getReference(change, id, historyModel);
  }
  return group;
};
function _formatStoryLinkName(change, storyLinkId, historyModel) {
  const storyLinkStory = StoryModel.getById(storyLinkId);
  const storyLinkName = storyLinkStory ? StoryAutoLinkWithNameTemplate.render(storyLinkStory) : StoryModel.generateStoryLinkForTemplate(ActivityModel.getReference(change, storyLinkId, historyModel));
  return storyLinkId === change.story_id ? '<strong>this story</strong>' : storyLinkName;
}
function _getChangedUserDetailsForTemplate(change, changeMap, pluralMap) {
  const profileIDs = changeMap.adds || changeMap.removes;
  const user = ProfileModel.getAllDetailsById(change.member_id);
  const names = _.map(profileIDs, id => {
    const profile = ProfileModel.getAllDetailsById(id);
    return '<strong>' + Format.fullName(profile, user) + '</strong>';
  });
  return {
    names: Utils.grammaticalJoin(names),
    suffix: ' as ' + (names.length > 1 ? pluralMap.multiple : pluralMap.singular)
  };
}

// Templates

exports.formatStoryCreateDelete = (change, story, type, name) => {
  const workflowState = ColumnModel.getById(story.workflow_state_id);
  return StoryCreateDeleteTemplate.render({
    created: type === 'create',
    description: Format.markdownify(story.description, 'template-story-description'),
    isMention: change.isMention,
    isGroupMention: change.isGroupMention,
    name,
    story: _formatStoryNameForTemplate(name && story),
    // Only pass in the reference if it's not story history
    workflowState: _.get(workflowState, 'name')
  });
};
function _formatStoryComment(change, action, name) {
  const story = action.story_id ? ActivityModel.getReference(change, action.story_id, ActivityModel) : change.actions[1]; // The story_id is not always in the `action`. The story is present in the `change` object
  // for a comment because the story is also updated when a comment is created.

  return StoryCommentCreateDeleteTemplate.render({
    added: true,
    comment: Format.markdownify(action.text || action.changes?.text?.new, 'comment-notification'),
    isMention: change.isMention,
    isGroupMention: change.isGroupMention,
    name,
    story: _formatStoryNameForTemplate(story, {
      commentId: change.primary_id
    })
  });
}
exports.formatStoryWorkflowStateMove = ({
  change,
  action,
  story,
  name
}) => {
  const changes = ActivityModel.getChanges(change, action);
  const ownerID = _.head(_.get(changes.owner_ids, 'adds', []));
  const newWorkflowState = ColumnModel.getById(changes.workflow_state_id.new);
  const workflowName = getWorkflowById(newWorkflowState?.workflow_id)?.name;
  const data = {
    completed: _.get(changes.completed, 'new'),
    name,
    started: _.get(changes.started, 'new'),
    story: _formatStoryNameForTemplate(story),
    workflowName,
    newWorkflowStateName: newWorkflowState?.name
  };
  if (ownerID) {
    const owner = ProfileModel.getAllDetailsById(ownerID);
    data.owner = Format.fullName(owner, owner);
  }
  return StoryWorkflowStateMoveTemplate.render(data);
};
exports.formatStoryEpicUpdate = ({
  change,
  action,
  historyModel,
  story,
  name
}) => {
  const changeMap = ActivityModel.getChanges(change, action).epic_id;
  return StoryEpicUpdateTemplate.render({
    name,
    new: _decorateEpic(change, changeMap.new, historyModel),
    old: _decorateEpic(change, changeMap.old, historyModel),
    story: _formatStoryNameForTemplate(story)
  });
};
exports.formatStoryCustomFieldValuesUpdate = ({
  change,
  action,
  story,
  name
}) => {
  //    "$User set the $Field of this story to $Value."
  //    "$User removed the $Field of this story."
  //    "$User changed the $Field of this story from $ValueOld to $ValueNew."
  const changeMap = ActivityModel.getChanges(change, action).custom_field_value_ids;
  const storyLink = _formatStoryNameForTemplate(story);
  const valueIdsAdded = changeMap.adds ?? [];
  const valueIdsRemoved = changeMap.removes ?? [];
  const getReference = id => {
    return change.references.find(r => r.id === id);
  };
  const valuesAdded = valueIdsAdded.map(getReference).filter(Boolean);
  const valuesRemoved = valueIdsRemoved.map(getReference).filter(Boolean);

  // Solves sc-193012
  // There is a case where the references will be nulled out if a user deletes
  // the custom field enum that was in use
  if (!valuesAdded.length && !valuesRemoved.length) return '';
  const fieldsAdded = new Set(valuesAdded.map(value => value.field_id));
  const fieldsRemoved = new Set(valuesRemoved.map(value => value.field_id));
  const fieldsUpdated = intersection(fieldsAdded, fieldsRemoved);
  const valuesUpdated = valuesAdded.filter(value => fieldsUpdated.has(value.field_id));

  // sets the `old` property of the new value to the old value
  valuesUpdated.forEach(value => {
    value.old = valuesRemoved.find(r => r.field_id === value.field_id);
  });
  const valuesOnlyAdded = valuesAdded.filter(value => !fieldsUpdated.has(value.field_id));
  const valuesOnlyRemoved = valuesRemoved.filter(value => !fieldsUpdated.has(value.field_id));
  const addedFragments = valuesOnlyAdded.map(value => StoryCustomFieldValuesAddTemplate.render({
    item: value,
    story: storyLink
  }));
  const removedFragments = valuesOnlyRemoved.map(value => StoryCustomFieldValuesRemoveTemplate.render({
    item: value,
    story: storyLink
  }));
  const updatedFragments = valuesUpdated.map(value => StoryCustomFieldValuesUpdateTemplate.render({
    item: value,
    story: storyLink
  }));
  const fragments = [].concat(addedFragments, removedFragments, updatedFragments).filter(s => s && s.length);
  return StoryCustomFieldsWrapperTemplate.render({
    id: change.id,
    story: story,
    checks: {
      hasAdded: addedFragments && addedFragments.length,
      hasRemoved: removedFragments && removedFragments.length,
      hasUpdated: updatedFragments && updatedFragments.length
    },
    name,
    body: Utils.grammaticalJoin(fragments)
  });
};
exports.formatStoryGroupUpdate = ({
  change,
  action,
  story,
  name
}) => {
  const changeMap = ActivityModel.getChanges(change, action).group_id;
  return StoryGroupUpdateTemplate.render({
    name,
    new: getGroup(change, changeMap.new, ActivityModel),
    old: getGroup(change, changeMap.old, ActivityModel),
    story: _formatStoryNameForTemplate(story)
  });
};
exports.formatStoryIterationUpdate = ({
  change,
  action,
  historyModel,
  story,
  name
}) => {
  const changeMap = ActivityModel.getChanges(change, action).iteration_id;
  return StoryIterationUpdateTemplate.render({
    name,
    new: getIteration(change, changeMap.new, historyModel),
    old: getIteration(change, changeMap.old, historyModel),
    story: _formatStoryNameForTemplate(story)
  });
};
exports.formatStoryProjectUpdate = ({
  change,
  action,
  historyModel,
  story,
  name
}) => {
  const changeMap = ActivityModel.getChanges(change, action).project_id;
  return StoryProjectUpdateTemplate.render({
    name,
    new: _decorateWithUrl(change, ProjectModel, changeMap.new, historyModel),
    old: _decorateWithUrl(change, ProjectModel, changeMap.old, historyModel),
    story: _formatStoryNameForTemplate(story)
  });
};
exports.formatStoryName = ({
  change,
  action,
  modelName,
  story,
  name
}) => {
  // TODO: This is a temporary fix until we figure out why the API
  // server isn't returning a change map for some story name updates.
  let storyName = ActivityModel.getChanges(change, action).name;
  if (_.isString(storyName)) {
    storyName = {
      new: storyName
    };
  }
  return StoryNameUpdateTemplate.render({
    action,
    change,
    modelName,
    name,
    prop: 'name',
    story: _formatStoryNameForTemplate(story),
    storyName: storyName.new
  });
};
exports.formatStoryDescription = ({
  change,
  action,
  modelName,
  story,
  name
}) => {
  return StoryDescriptionUpdateTemplate.render({
    action,
    change,
    description: Format.markdownify(action.changes.description.new, 'description-notification'),
    isMention: change.isMention,
    isGroupMention: change.isGroupMention,
    modelName,
    name,
    prop: 'description',
    story: _formatStoryNameForTemplate(story)
  });
};
exports.formatStoryBlocking = (blocked, story, name) => {
  return StoryBlockingUpdateTemplate.render({
    name,
    new: blocked.new,
    old: !!blocked.old,
    story: _formatStoryNameForTemplate(story)
  });
};
exports.formatStoryDeadline = (deadline, story, name) => {
  return StoryDeadlineUpdateTemplate.render({
    name,
    new: deadline.new && moment(deadline.new).format(Constants.SHORT_DATE_FORMAT),
    old: !!deadline.old,
    story: _formatStoryNameForTemplate(story)
  });
};
exports.formatStoryArchived = (archived, story, name) => {
  return StoryArchivedUpdateTemplate.render({
    archived,
    name,
    story: _formatStoryNameForTemplate(story)
  });
};
exports.formatStoryEstimate = (estimate, story, name) => {
  return StoryEstimateUpdateTemplate.render({
    checks: {
      hasNew: _.isNumber(estimate.new),
      hasOld: _.isNumber(estimate.old)
    },
    estimate,
    name,
    story: _formatStoryNameForTemplate(story)
  });
};
exports.formatStoryUsers = ({
  change,
  changeMap,
  pluralMap,
  story,
  name
}) => {
  const added = !!changeMap.adds;
  const details = _getChangedUserDetailsForTemplate(change, changeMap, pluralMap);
  return StoryUsersUpdateTemplate.render({
    added,
    formattedNames: details.names,
    name,
    story: _formatStoryNameForTemplate(story),
    suffix: details.suffix
  });
};
exports.formatStoryLinkCreateDelete = ({
  change,
  storyLink,
  type,
  historyModel,
  name
}) => {
  return StoryLinkCreateDeleteTemplate.render({
    created: type === 'create',
    object: _formatStoryLinkName(change, storyLink.object_id, historyModel),
    name,
    subject: _formatStoryLinkName(change, storyLink.subject_id, historyModel),
    verb: StoryLinkModel.RELATIVE_VERBS[storyLink.verb].activity
  });
};
exports.formatTaskCreateDelete = ({
  change,
  task,
  type,
  story,
  name
}) => {
  return TaskCreateDeleteTemplate.render({
    created: type === 'create',
    isMention: change.isMention,
    isGroupMention: change.isGroupMention,
    name,
    story: _formatStoryNameForTemplate(story),
    task: Format.markdownify(task.description, 'task-notification', {
      inline: true
    })
  });
};
exports.formatTaskDescription = (change, description, story, name) => {
  return TaskDescriptionUpdateTemplate.render({
    name,
    isMention: change.isMention,
    isGroupMention: change.isGroupMention,
    story: _formatStoryNameForTemplate(story),
    task: Format.markdownify(description.new, 'task-notification', {
      inline: true
    })
  });
};
exports.formatTaskComplete = ({
  change,
  action,
  historyModel,
  story,
  name
}) => {
  const task = ActivityModel.getReference(change, action.id, historyModel);
  return TaskCompleteUpdateTemplate.render({
    complete: ActivityModel.getChanges(change, action).complete.new,
    isMention: change.isMention,
    isGroupMention: change.isGroupMention,
    name,
    story: _formatStoryNameForTemplate(story),
    task: Format.markdownify(task.description, 'task-notification', {
      inline: true
    })
  });
};
exports.formatTaskOwners = ({
  change,
  action,
  historyModel,
  story,
  name
}) => {
  const changeMap = ActivityModel.getChanges(change, action).owner_ids;
  const details = _getChangedUserDetailsForTemplate(change, changeMap, {
    multiple: 'owners',
    singular: 'the owner'
  });
  const task = ActivityModel.getReference(change, action.id, historyModel);
  return TaskOwnersUpdateTemplate.render({
    added: !!changeMap.adds,
    isMention: change.isMention,
    isGroupMention: change.isGroupMention,
    name,
    ownerNames: details.names,
    story: _formatStoryNameForTemplate(story),
    suffix: details.suffix,
    task: Format.markdownify(task.description, 'task-notification', {
      inline: true
    })
  });
};
function _formatStoryTaskUpdate(change, action, story, name) {
  const changeMap = ActivityModel.getChanges(change, action).task_ids;
  const taskId = _.head(changeMap.adds || changeMap.removes);
  const task = ActivityModel.getReference(change, taskId, ActivityModel);
  return TaskCreateDeleteTemplate.render({
    created: !!changeMap.adds,
    isMention: change.isMention,
    isGroupMention: change.isGroupMention,
    name,
    story: _formatStoryNameForTemplate(story),
    task: Format.markdownify(task.description, 'task-notification', {
      inline: true
    })
  });
}
const _formatEpicCreateDelete = (change, epic, type, name) => renderComponentToString(_jsx(EpicCreateDelete, {
  created: type === 'create',
  description: epic.description,
  epic,
  isMention: change.isMention,
  isGroupMention: change.isGroupMention,
  name
}));
function _formatImportUpdated(change, importObj) {
  return ImportUpdatedTemplate.render({
    change,
    importObj
  });
}
function _formatEpicComment(change, action, name) {
  // The epic_id is not always in the `action`. The epic is present in the `change` object
  // for a comment because the epic is also updated when a comment is created.
  const epic = action.epic_id ? ActivityModel.getReference(change, action.epic_id, ActivityModel) : change.actions[1];
  return renderComponentToString(_jsx(EpicCommentCreateDelete, {
    added: true,
    comment: action.text || action.changes?.text?.new,
    epic: epic,
    isMention: change.isMention,
    isGroupMention: change.isGroupMention,
    name
  }));
}
const _formatEpicName = (changes, epic, name) => renderComponentToString(_jsx(EpicName, {
  epic,
  name,
  oldName: changes.name.old
}));
const _formatEpicDescription = (change, description, epic, name) => renderComponentToString(_jsx(EpicDescription, {
  change,
  description: description.new,
  epic,
  isMention: change.isMention,
  isGroupMention: change.isGroupMention,
  name
}));
function _formatHealthUpdate(change, action, name) {
  const isHealthCreated = action.action === 'create';
  const isCurrentHealthUpdated = action.action === 'update' && change.reasons.some(reason => reason.entity_type !== 'mention') && !!action.changes?.status?.new;
  const isOldHealthCommentUpdated = action.action === 'update' && change.reasons.some(reason => reason.reason === 'mention') && !!action.changes?.text?.new;
  let entity = null;
  for (const ref of change.references || []) {
    if (ref.entity_type === 'epic') {
      entity = EpicModel.getById(ref.id);
      break;
    } else if (ref.entity_type === 'milestone') {
      entity = MilestoneModel.getById(ref.id);
      break;
    }
  }
  if (isHealthCreated || isCurrentHealthUpdated) {
    const newStatus = action.changes?.status?.new || action.status;
    const newText = action.changes?.text?.new || action.text;
    return EpicHealthStatusUpdateTemplate.render({
      name,
      entity,
      status: newStatus,
      statusLabel: newStatus === 'onTrack' ? 'On Track' : newStatus === 'atRisk2' ? 'At Risk' : newStatus === 'offTrack' ? 'Off Track' : newStatus === 'noHealth' ? 'No Health' : '',
      comment: newText ? Format.markdownify(newText, 'health-comment-notification') : ''
    });
  } else if (isOldHealthCommentUpdated) {
    const newText = action.changes.text.new;
    return EpicHealthStatusUpdateTemplate.render({
      name,
      entity,
      comment: Format.markdownify(newText, 'health-comment-notification')
    });
  } else return '';
}
function _formatEpicArchived(archived, epic, name) {
  return renderComponentToString(_jsx(EpicArchiveUpdate, {
    name: name,
    epic: epic,
    isArchived: archived.new
  }));
}
function _formatEpicState(stateIds, epic, name) {
  const oldState = EpicStateModel.getById(stateIds.old);
  const newState = EpicStateModel.getById(stateIds.new);
  return renderComponentToString(_jsx(EpicStateUpdate, {
    name: name,
    epic: epic,
    oldStateName: oldState ? oldState.name : 'deleted status',
    newStateName: newState ? newState.name : 'deleted status',
    isDone: newState.type === Constants.WORKFLOW_STATE_TYPES.DONE
  }));
}
function _formatEpicRequester(requestedById, epic, name) {
  const requester = ProfileModel.getAllDetailsById(requestedById);
  return renderComponentToString(_jsx(EpicRequesterUpdate, {
    name: name,
    epic: epic,
    requesterName: requester.name
  }));
}
function _formatEpicGroups(groupIdChanges, epic, name) {
  const {
    adds = [],
    removes = []
  } = groupIdChanges;
  const addedGroups = adds.map(GroupModel.getById);
  const removedGroups = removes.map(GroupModel.getById);
  return EpicGroupsUpdateTemplate.render({
    name,
    epic,
    addedGroups,
    removedGroups
  });
}
function _formatGroupMemberChanges(groupMembersChanges, group, name) {
  const {
    adds = [],
    removes = []
  } = groupMembersChanges;
  const currentMemberId = UserModel.getLoggedInUserPermissionID();
  const added = adds.indexOf(currentMemberId) !== -1;
  const removed = removes.indexOf(currentMemberId) !== -1;
  if (!added && !removed) return '';
  return renderComponentToString(_jsx(TeamUpdateMembers, {
    addedOrRemoved: added ? 'added' : 'removed',
    name,
    team: GroupModel.getById(group.id)
  }));
}
function _formatIterationGroups(groupIdChanges, iteration, name) {
  const {
    adds = [],
    removes = []
  } = groupIdChanges;
  const addedGroups = adds.map(GroupModel.getById);
  const removedGroups = removes.map(GroupModel.getById);
  return IterationUpdateGroupTemplate.render({
    name,
    iteration,
    addedGroups,
    removedGroups
  });
}
function _formatProjectCreateDelete(project, type, name) {
  return ProjectCreateDeleteTemplate.render({
    created: type === 'create',
    name,
    project
  });
}
function _formatProjectName(changes, project, name) {
  return ProjectUpdateNameTemplate.render({
    name,
    oldName: changes.name.old,
    // Show the name at the time of update
    project: _.assign({}, project, {
      name: Format.sanitizeAndEmojify(changes.name.new)
    })
  });
}
function _formatProjectArchived(archived, project, name) {
  return ProjectUpdateArchivedTemplate.render({
    archived,
    name,
    project
  });
}
function _formatStoryFileUpdate(change, file_ids, story, name) {
  const fileId = _.head(file_ids.adds || file_ids.removes);
  const file = ActivityModel.getReference(change, fileId, ActivityModel);
  return StoryFileUpdateTemplate.render({
    added: !!file_ids.adds,
    name,
    story: _formatStoryNameForTemplate(story),
    file
  });
}
function _formatReactionCreated(change, reaction, name) {
  const {
    emoji,
    comment_id
  } = reaction;
  const comment = ActivityModel.getReference(change, comment_id, ActivityModel);
  const currentUserId = UserModel.getLoggedInUserPermissionID();
  const isAuthor = comment.author_id === currentUserId;
  const story = ActivityModel.getReference(change, comment.story_id, ActivityModel);
  return renderComponentToString(_jsx(ReactionCreate, {
    name,
    isAuthor,
    emoji,
    story
  }));
}
function _formatUserJoinedWorkspace(name) {
  const permission = UserModel.getLoggedInUserPermission();
  const {
    name: orgName,
    url_slug: orgSlug
  } = permission.organization;
  const workspaceName = permission.workspace2.name;
  return UserJoinedTemplate.render({
    name: Format.sanitizeAndEmojify(name),
    isOwner: Is.ownerOnly(permission),
    orgName: Format.sanitizeAndEmojify(orgName),
    orgSlug: Format.sanitizeAndEmojify(orgSlug),
    workspaceName: Format.sanitizeAndEmojify(workspaceName)
  });
}

// ----- Actions -----

exports.updateNotificationFilter = function () {
  const id = Utils.data(this, 'filter-id');
  ActivityModel.setFilter(id);
  getFeedElement().scrollTop(0);
  isLoadingMore = false;
  exports.redrawFeed({
    forceRedraw: true
  });
  return false;
};
exports.openSettingsDropdown = function () {
  const badgeTrigger = _getBadgeTrigger();
  const ownActivityFilter = _getOwnActivityFilter();
  const items = [{
    html: '<div class="caption">Include My Own Changes?</div>'
  }, {
    name: '<strong>Yes</strong>, include my changes',
    iconLeft: ownActivityFilter === 'show' ? 'fa-circle' : 'fa-circle-o',
    value: _toggleIncludeMyOwnChanges
  }, {
    name: '<strong>No</strong>, exclude my changes',
    iconLeft: ownActivityFilter === 'hide' ? 'fa-circle' : 'fa-circle-o',
    value: _toggleIncludeMyOwnChanges
  }, {
    hr: true
  }, {
    html: '<div class="caption">Show Activity Badge...</div>'
  }, {
    name: 'For all <strong>comments</strong>',
    iconLeft: badgeTrigger === 'comments' ? 'fa-circle' : 'fa-circle-o',
    value: _showBadgeForAllComments
  }, {
    name: 'For <strong>mentions</strong> only',
    iconLeft: badgeTrigger === 'mentions' ? 'fa-circle' : 'fa-circle-o',
    value: _showBadgeForMentionsOnly
  }];
  DropdownController.open({
    items,
    target: this,
    className: 'historical-detail-dropdown',
    width: 200
  });
  return false;
};
export { exports as default };