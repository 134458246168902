import "core-js/modules/es.array.push.js";
import LabelController from 'app/client/core/js/controllers/label.js';
import StoryController from 'app/client/core/js/controllers/story.js';
import LabelModel from 'app/client/core/js/models/label.js';
window.AppAssignments = window.AppAssignments || [];
window.AppAssignments.push(() => {
  window.App = window.App || {
    Controller: {},
    Model: {}
  };
  [[['Controller', 'Label'], LabelController], [['Controller', 'Story'], StoryController], [['Model', 'Label'], LabelModel], [['Controller', 'Label'], LabelController], [['Controller', 'Story'], StoryController], [['Model', 'Label'], LabelModel]].reduce((accum, [op, n]) => {
    op.reduce((obj, part) => {
      return obj[part] || (obj[part] = n);
    }, accum);
    return accum;
  }, window.App);
});
import classnames from 'classnames';
import _ from 'lodash';
import { getDefaultLabelColor, sortByLowercaseName, useLabels } from 'data/entity/label';
import { getImpliedLabels } from 'data/entity/story';
import * as Is from 'utils/is';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
const LabelContainer = ({
  labels,
  onClick,
  contextMenu,
  onRemove
}) => {
  const labelIds = labels.map(l => l.id);
  const {
    labels: allLabels
  } = useLabels();
  const selectedLabels = sortByLowercaseName(allLabels.filter(l => labelIds.indexOf(l.id) > -1));
  return _jsx(_Fragment, {
    children: selectedLabels.map(label => {
      return _jsxs("div", {
        className: classnames('tag', {
          archived: label.archived
        }),
        "data-model": "Label",
        "data-id": label.id,
        "data-on-click": onClick,
        "data-tooltip": label.archived ? 'This Label is archived' : undefined,
        "data-tabindex": true,
        children: [_jsx("span", {
          className: "color fa fa-circle",
          style: {
            color: label.color || getDefaultLabelColor()
          }
        }), _jsx(Emojify, {
          children: label.name
        }), contextMenu && _jsx("span", {
          className: "more-items fa fa-ellipsis-h",
          "data-tooltip": "Show Label options",
          "data-context-menu": contextMenu,
          "data-context-menu-right": "-5",
          "data-context-menu-top": "-4",
          id: `label-context-${label.id}-${_.random(label.id)}`
        }), onRemove && _jsx("span", {
          className: "delete fa fa-times",
          "data-on-click": onRemove,
          "data-tooltip": "Remove Label"
        })]
      }, label.id);
    })
  });
};
export const StoryLabels = ({
  story
}) => {
  const impliedLabels = getImpliedLabels(story);
  return _jsxs("div", {
    className: "labels-container",
    children: [_jsx("div", {
      className: "title",
      children: "Labels"
    }), _jsx("div", {
      className: "labels story-labels",
      children: story.readOnly && !story.labels.length ? _jsx("p", {
        className: "none-found",
        children: "No Story labels."
      }) : _jsxs(_Fragment, {
        children: [Is.mobile() ? _jsx(LabelContainer, {
          labels: story.labels,
          onRemove: "App.Controller.Story.removeLabel"
        }) : _jsx(LabelContainer, {
          labels: story.labels,
          contextMenu: "App.Controller.Label.contextMenuItemsForStory"
        }), !story.readOnly && _jsx("div", {
          children: _jsx("button", {
            id: 'story-dialog-add-label-dropdown',
            "data-tabindex": true,
            "data-on-click": 'App.Controller.Story.addStoryLabel',
            className: classnames('add-labels', 'action', 'micro', 'white'),
            children: "Add Labels"
          })
        })]
      })
    }), impliedLabels.length > 0 && _jsxs(_Fragment, {
      children: [_jsxs("div", {
        className: "title",
        children: ["Epic Labels", ' ', _jsx("span", {
          className: "fa fa-question-circle",
          "data-tooltip": "Labels attached to the parent Epic of this Story."
        })]
      }), _jsx("div", {
        className: "labels implied-labels",
        children: _jsx(LabelContainer, {
          labels: impliedLabels,
          contextMenu: "App.Controller.Label.contextMenuItemsForEpic"
        })
      })]
    })]
  });
};
StoryLabels.displayName = "StoryLabels";