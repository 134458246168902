import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.set.difference.v2.js";
import "core-js/modules/esnext.set.intersection.v2.js";
import "core-js/modules/esnext.set.is-disjoint-from.v2.js";
import "core-js/modules/esnext.set.is-subset-of.v2.js";
import "core-js/modules/esnext.set.is-superset-of.v2.js";
import "core-js/modules/esnext.set.symmetric-difference.v2.js";
import "core-js/modules/esnext.set.union.v2.js";
import { useMemo } from 'react';
import { emptyArray, insertIf } from '@clubhouse/shared/utils';
import { getTeamColor } from '@clubhouse/shared/utils/teams';
import { Select, SelectOptionChecked, SelectOptionDivider, SelectOptionNoItemsFound, SelectOptionSubLabel } from '../Select';
import { TeamIcon } from '../TeamIcon';
import { TeamTooltip } from '../TeamTooltip';
import { newTeamItem } from './CreateNewTeamItem';
import { DefaultTeamSelectTrigger } from './TeamSelectTrigger/TeamSelectTrigger';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const EMPTY_WORKFLOWS_TOOLTIP_TEXT = "You cannot assign a Story to a Team that doesn't have a Workflow. Update your Workflows from Settings.";
const MAX_NUMBER_OF_TEAMS = 6;
const None = {
  selectedValues: [],
  name: _jsx("em", {
    children: "None"
  }),
  key: 'no-value'
};
const NoItemsFound = {
  Component: SelectOptionNoItemsFound,
  key: 'no-value-found'
};
const getDivider = key => ({
  Component: SelectOptionDivider,
  key: `divider-${key}`
});
const MyTeams = {
  name: 'My Teams',
  Component: SelectOptionSubLabel,
  key: 'my-teams-label'
};
const StoryTeams = {
  name: 'Story Teams',
  Component: SelectOptionSubLabel,
  key: 'story-teams-label'
};
const OtherTeams = {
  name: 'Other Teams',
  Component: SelectOptionSubLabel,
  key: 'other-teams-label'
};
const getTeamItemWithWorkflowInfo = ({
  isSearchOnly = false,
  team
}) => {
  const emptyWorkflows = !team.workflow_ids || team.workflow_ids.length === 0;
  const teamColor = getTeamColor(team);
  return {
    ...getTeamItem({
      isSearchOnly,
      team: {
        display_icon: team.display_icon,
        workflow_ids: team.workflow_ids,
        name: team.name,
        id: String(team.id),
        color_key: teamColor
      }
    }),
    isDisabled: emptyWorkflows,
    tooltipText: emptyWorkflows ? EMPTY_WORKFLOWS_TOOLTIP_TEXT : null
  };
};
const sortItems = (a, b) => a.name.localeCompare(b.name, 'en-US');
export const SelectOptionTeam = ({
  additionalData,
  ...props
}) => {
  const iconUrl = additionalData?.displayIcon?.url;
  const Icon = useMemo(() => () => _jsx(TeamIcon, {
    iconUrl: iconUrl,
    size: 24,
    iconType: "new-team-icon",
    backgroundColor: props.highlightColor
  }), [iconUrl, props.highlightColor]);
  return _jsx(SelectOptionChecked, {
    ...props,
    Icon: Icon
  });
};
SelectOptionTeam.displayName = "SelectOptionTeam";
export const getTeamItem = ({
  isSearchOnly = false,
  team
}) => {
  const teamColor = team.color_key ?? getTeamColor(team);
  return {
    additionalData: {
      displayIcon: team.display_icon
    },
    additionalSearchTerms: [team?.name],
    Component: SelectOptionTeam,
    isSearchOnly,
    highlightColor: teamColor,
    name: team?.name,
    value: String(team?.id)
  };
};
export const TeamSelect = ({
  allowMultipleSelections = true,
  currentUser,
  label,
  isDisabled = false,
  isSearchOnly = true,
  onChange,
  onClose,
  onOpen,
  maxSelected: propsMaxSelected = 1,
  selectedValues = emptyArray,
  showCreateNewTeam = false,
  TargetComponent = DefaultTeamSelectTrigger,
  teams,
  storyTeams = emptyArray,
  users,
  maxHeight = 380
}) => {
  const currentTeamsMap = useMemo(() => new Map(teams.map(t => [String(t.id), t])), [teams]);
  const selectedIds = useMemo(() => new Set(selectedValues), [selectedValues]);
  const selectedTeams = useMemo(() => selectedIds?.size > 0 ? teams.filter(t => selectedIds.has(t.id)).sort(sortItems) : [], [teams, selectedIds]);
  const maxSelected = allowMultipleSelections ? propsMaxSelected : 1;
  const teamItems = useMemo(() => {
    const currentTeams = [...currentTeamsMap.values()];
    const shouldShowCreateNewTeam = showCreateNewTeam && currentTeams.length < MAX_NUMBER_OF_TEAMS;
    const myTeamIds = new Set((currentUser?.group_ids ?? []).map(String));
    const storyTeamIds = new Set(storyTeams);
    let myTeams = currentTeams.filter(t => myTeamIds.has(String(t.id)));
    let storiesTeams = currentTeams.filter(t => storyTeamIds.has(String(t.id)) && !myTeamIds.has(String(t.id)));
    let otherTeams = currentTeams.filter(t => !storyTeamIds.has(String(t.id)) && !myTeamIds.has(String(t.id)));
    if (allowMultipleSelections) {
      myTeams = myTeams.filter(t => !selectedIds.has(t.id));
      storiesTeams = storiesTeams.filter(t => !selectedIds.has(t.id));
      otherTeams = otherTeams.filter(t => !selectedIds.has(t.id));
    }
    const myTeamItems = myTeams.map(team => getTeamItemWithWorkflowInfo({
      isSearchOnly: false,
      team
    })).sort(sortItems);
    const storyTeamItems = storiesTeams.map(team => getTeamItemWithWorkflowInfo({
      isSearchOnly,
      team
    })).sort(sortItems);
    const otherTeamItems = otherTeams.map(team => getTeamItemWithWorkflowInfo({
      isSearchOnly,
      team
    })).sort(sortItems);
    const hasSelected = !!allowMultipleSelections && selectedTeams.length > 0;
    const hasMyTeams = !!currentUser && currentTeams.length > 0;
    const hasStoryTeams = storyTeamItems.length > 0;
    const hasOtherTeams = otherTeamItems.length > 0;
    const hasNoTeams = currentTeams.length === 0;
    const items = [[None], insertIf(hasSelected, selectedTeams.map(team => getTeamItemWithWorkflowInfo({
      team
    }))), insertIf(hasMyTeams, [MyTeams, ...(myTeamItems.length ? myTeamItems : [NoItemsFound])]), insertIf(hasStoryTeams, [...(hasMyTeams || hasOtherTeams ? [StoryTeams] : []), ...storyTeamItems]), insertIf(hasOtherTeams, [...(hasMyTeams || hasStoryTeams ? [OtherTeams] : []), ...otherTeamItems]), insertIf(hasNoTeams, [NoItemsFound]), insertIf(shouldShowCreateNewTeam, [newTeamItem])].reduce((acc, section, i) => {
      if (acc.length > 0 && section.length > 0) {
        acc.push(getDivider(String(i)));
      }
      acc.push(...section);
      return acc;
    }, []);
    return items;
  }, [currentTeamsMap, showCreateNewTeam, currentUser, storyTeams, allowMultipleSelections, selectedTeams, selectedIds, isSearchOnly]);
  const targetComponentProps = useMemo(() => {
    const team = selectedTeams?.[0];
    return {
      selectedTeam: team ? {
        id: team.id,
        display_icon: team.display_icon ? {
          url: team.display_icon.url
        } : null,
        color_key: team.color_key,
        name: team.name
      } : null,
      Tooltip: ({
        children
      }) => _jsx(TeamTooltip, {
        team: selectedTeams?.[0],
        users: users,
        children: children
      })
    };
  }, [selectedTeams, users]);
  return _jsx(Select, {
    isDisabled: isDisabled,
    onChange: onChange,
    onOpen: onOpen,
    onClose: onClose,
    maxSelected: maxSelected,
    isSearchable: true,
    items: teamItems,
    label: label ?? 'Update Team',
    maxHeight: maxHeight,
    selectedValues: selectedValues.length === 0 ? ['None'] : selectedValues,
    unit: "Team",
    width: Select.WIDTH.LARGE,
    TargetComponent: TargetComponent,
    targetComponentProps: targetComponentProps
  });
};
TeamSelect.displayName = "TeamSelect";