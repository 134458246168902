import { createBrowserInspector } from '@statelyai/inspect';
import { useActorRef } from '@xstate/react';
import { useCallback, useEffect } from 'react';
import { quickSearchSessionMachine } from './quickSearchSessionMachine';
const inspector = createBrowserInspector({
  autoStart: false // Turn to `true` if you want the inspector to automatically start
});

// Hooking the inspector on `window` so we could start it from the console via: `window._QUICK_SEARCH_SESSION_MACHINE_INSPECTOR.start()`.
window._QUICK_SEARCH_SESSION_MACHINE_INSPECTOR = inspector;
/**
 * Hook used to encapsulate the communication between Quick Search and Quick Search Session state machine (XState).
 *
 * The state machine isn't used as a source of truth, but rather as a way to manage the session state of the Quick Search,
 * and to log the interactions and session start/end timestamps for analytics purposes (Segment).
 */
export function useQuickSearchSession({
  isOpen,
  searchInput
}) {
  // We're using `useActorRef` to get the actor reference of the machine in order to minimize the number of re-renders.
  // We don't need the state (snapshot) of the machine here, so we're not using `useMachine` hook.
  // The only thing we want to do, is to communicate state changes to the state machine.
  const actorRef = useActorRef(quickSearchSessionMachine, {
    inspect: inspector.inspect
  });
  const openSearch = useCallback(() => {
    actorRef.send({
      type: 'OPEN_SEARCH'
    });
  }, [actorRef]);
  const closeSearch = useCallback(() => {
    actorRef.send({
      type: 'CLOSE_SEARCH'
    });
  }, [actorRef]);
  const setSearchInput = useCallback(text => {
    actorRef.send({
      type: 'INPUT_TEXT',
      text
    });
  }, [actorRef]);
  const setSelectedTab = useCallback(tab => {
    actorRef.send({
      type: 'SELECT_TAB',
      tab
    });
  }, [actorRef]);
  const setResults = useCallback(results => {
    actorRef.send({
      type: 'RESULTS',
      results
    });
  }, [actorRef]);
  const logInteraction = useCallback(interactionType => {
    actorRef.send({
      type: 'INTERACTION',
      interactionType
    });
  }, [actorRef]);
  const logEntitySelected = useCallback((entity, index) => {
    actorRef.send({
      type: 'INTERACTION',
      interactionType: 'entity-selected',
      entity,
      index
    });
  }, [actorRef]);
  const logRecentSearchesViewed = useCallback(() => {
    actorRef.send({
      type: 'INTERACTION',
      interactionType: 'recent-searches-viewed'
    });
  }, [actorRef]);
  const logRecentSearchesApplied = useCallback((selection, recentSearches) => {
    actorRef.send({
      type: 'INTERACTION',
      interactionType: 'recent-searches-applied',
      selection,
      recentSearches
    });
  }, [actorRef]);
  const logExpandOperators = useCallback(() => {
    actorRef.send({
      type: 'INTERACTION',
      interactionType: 'expand-operators'
    });
  }, [actorRef]);
  const logSearchOperatorSelected = useCallback(operator => {
    actorRef.send({
      type: 'INTERACTION',
      interactionType: 'search-operator-selected',
      operator
    });
  }, [actorRef]);
  const logViewAllSearchOperators = useCallback(() => {
    actorRef.send({
      type: 'INTERACTION',
      interactionType: 'view-all-search-operators'
    });
  }, [actorRef]);
  const logSearchOperatorUserInput = useCallback(operatorUserInput => {
    actorRef.send({
      type: 'INTERACTION',
      interactionType: 'search-operator-user-input',
      operatorUserInput
    });
  }, [actorRef]);
  const logAdvancedSearchViewStoriesInPage = useCallback(() => {
    actorRef.send({
      type: 'INTERACTION',
      interactionType: 'advanced-search-view-stories-in-page'
    });
  }, [actorRef]);

  /**
   * Hooking up Quick Search's state to Quick Search Session state machine.
   */
  useEffect(() => {
    if (isOpen) {
      openSearch();
    } else {
      closeSearch();
    }
  }, [closeSearch, isOpen, openSearch]);
  useEffect(() => {
    setSearchInput(searchInput);
  }, [setSearchInput, searchInput]);
  return {
    openSearch,
    closeSearch,
    setSearchInput,
    setSelectedTab,
    setResults,
    logInteraction,
    logEntitySelected,
    logRecentSearchesViewed,
    logRecentSearchesApplied,
    logExpandOperators,
    logViewAllSearchOperators,
    logSearchOperatorSelected,
    logSearchOperatorUserInput,
    logAdvancedSearchViewStoriesInPage
  };
}