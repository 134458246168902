import * as ManageTwoFactorAuthTemplate from 'app/client/settingsShared/views/templates/manageTwoFactorAuth.html';
import * as ServerMessageTemplate from 'app/client/core/views/templates/serverMessage.html';
import * as Event from '../../../core/js/_frontloader/event';
import User from '../../../core/js/modules/user';
import UserModel from '../../../core/js/models/user';
import Utils from '../../../core/js/modules/utils';
import Validator from '../../../core/js/modules/validator';
import View from '../../../core/js/modules/view';
import SettingsPage from '../../../settings/js/controllers/settingsPage';
import _ from 'lodash';
const exports = {};
const PARENT_SELECTOR = '#manage-two-factor-auth';
const BUTTON_SELECTOR = PARENT_SELECTOR + ' button.action';
const renderTemplate = data => {
  const html = ManageTwoFactorAuthTemplate.render(data);
  $(PARENT_SELECTOR).html(html);
};
exports.openPage = (extras = {}) => {
  renderTemplate({
    me: User.getCurrentUser(),
    passwordAuthDisabled: User.passwordAuthDisabled(),
    ...extras
  });
  if (extras.qrCodeData) {
    exports.createQRCodeImage(extras.qrCodeData);
  }
  Validator.init(PARENT_SELECTOR);
  SettingsPage.onDestroy('ManageTwoFactorAuth', () => {
    Validator.destroy(PARENT_SELECTOR);
  });
};
exports.onError = (title, message) => {
  const html = ServerMessageTemplate.render({
    className: 'error',
    iconLeft: 'fa-minus-circle',
    title,
    message
  });
  $(PARENT_SELECTOR).find('.server-messages').hide().html(html).slideDown(200);
};
exports.createQRCodeImage = async ({
  email_host,
  email_user,
  secret_key
}) => {
  const {
    default: QRCode
  } = await import(/* webpackChunkName: "qrcode" */'@keeex/qrcodejs-kx');
  return new QRCode(document.getElementById('qr-code'), {
    text: `otpauth://totp/${BRAND.NAME}:${email_user}@${email_host}?secret=${secret_key}&issuer=${BRAND.NAME}`,
    width: 128,
    height: 128,
    colorDark: '#000000',
    colorLight: '#ffffff',
    correctLevel: QRCode.CorrectLevel.H
  });
};
exports.enterPassword = () => {
  const context = $(PARENT_SELECTOR);
  const data = Utils.formData(context);
  if (Validator.isValid(context)) {
    View.changeButtonToSaving(BUTTON_SELECTOR, 'Please wait');
    UserModel.startTwoFactorSignup(data.password, res => {
      UserModel.fetchCurrentUser(() => {
        if (_.isPlainObject(res) && res.qr_code_data) {
          exports.openPage({
            qrCodeData: res.qr_code_data
          });
          $('.two-factor-confirmation input').focus();
        } else {
          exports.onError('Incorrect password.', 'Please try again.');
          View.revertButtonState(BUTTON_SELECTOR, 'Continue <span class="fa fa-arrow-right"></span>');
        }
      });
    });
  }
  return false;
};
exports.enterCode = () => {
  const context = $(PARENT_SELECTOR);
  const data = Utils.formData(context);
  if (Validator.isValid(context)) {
    View.changeButtonToSaving(BUTTON_SELECTOR);
    UserModel.confirmTwoFactor(data.two_factor_code, res => {
      if (res.recovery_code) Event.trigger('two-factor-auth-enabled');
      UserModel.fetchCurrentUser(() => {
        if (_.isPlainObject(res) && res.recovery_code) {
          exports.openPage({
            recovery_code: res.recovery_code
          });
          $('.recovery-code-box input').focus().select();
        } else {
          exports.onError('Incorrect code.', 'Please try again.');
          View.revertButtonState(BUTTON_SELECTOR, 'Continue <span class="fa fa-arrow-right"></span>');
        }
      });
    });
  }
  return false;
};
exports.disable = () => {
  const context = $(PARENT_SELECTOR);
  const data = Utils.formData(context);
  if (Validator.isValid(context)) {
    View.changeButtonToSaving(BUTTON_SELECTOR);
    UserModel.disableTwoFactor(data, res => {
      if (_.isPlainObject(res) && res.error) {
        exports.onError('Incorrect password or code.', 'Please try again.');
        View.revertButtonState(BUTTON_SELECTOR, 'Disable <span class="fa fa-arrow-right"></span>');
      } else {
        UserModel.fetchCurrentUser(() => {
          exports.openPage();
        });
      }
    });
  }
  return false;
};
export { exports as default };