import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';
import * as LocalStorage from 'utils/localStorage';
const FEEDBACK_VAR = 'feedbackVar';
const makeFeedbackVar = () => makeVar(LocalStorage.getValue(FEEDBACK_VAR) ?? {
  isOpen: false,
  subject: ''
});
let feedbackVar;
export const useOpenFeedbackForm = () => {
  if (!feedbackVar) feedbackVar = makeFeedbackVar();
  const isOpen = useReactiveVar(feedbackVar).isOpen;
  const subject = useReactiveVar(feedbackVar).subject;
  const openFeedbackForm = useCallback(force => {
    const newValue = {
      subject: feedbackVar().subject,
      isOpen: force ?? !isOpen
    };
    feedbackVar(newValue);
    LocalStorage.setValue(FEEDBACK_VAR, newValue);
  }, [isOpen]);
  const setSubject = useCallback(newSubject => {
    const newValue = {
      subject: newSubject,
      isOpen: feedbackVar().isOpen
    };
    feedbackVar(newValue);
    LocalStorage.setValue(FEEDBACK_VAR, newValue);
  }, []);
  return {
    isOpen,
    openFeedbackForm,
    setSubject,
    subject
  };
};