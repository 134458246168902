import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
export const isParseableUrl = (text = '') => {
  try {
    new URL(text);
    return true;
  } catch {}
  return false;
};
export const isInUrl = (text, index) => {
  let endOfWord = text.length,
    startOfWord = 0;
  for (startOfWord = index; startOfWord > 0; --startOfWord) {
    if (/\s|\(|\[|>|"/.test(text.charAt(startOfWord - 1))) break;
  }
  for (endOfWord = index; endOfWord < text.length; ++endOfWord) {
    if (/\s|\)|\]|<|"/.test(text.charAt(endOfWord))) break;
  }
  const word = text.substring(startOfWord, endOfWord);
  const isUrl = /^https?:\/\//.test(word) && isParseableUrl(word);
  return isUrl;
};
export const isValidUrl = (text = '') => {
  if (!/^https?:\/\/[^\n]*$/i.test(text)) return false;
  return isParseableUrl(text);
};