import { DeleteStoryActionFragmentDoc } from "../../../../../datalayer/__generated_graphql_types__/graphql";
import { gql } from '@clubhouse/datalayer';
import { Emojify } from '@clubhouse/shared/components/Emojify';
import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { deleteStory } from 'data/entity/story';
import { useCallback } from 'react';
import { closeCurrentStoryDialog } from 'utils/storyDialog';
import { useStoryConfirmationDialog } from '../hooks/useStoryConfirmationDialog';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const DeleteStoryActionFragment = DeleteStoryActionFragmentDoc;
export const useDeleteStoryDialog = (story, isSubtask) => {
  const {
    openDialog,
    closeDialog
  } = useStoryConfirmationDialog();
  const {
    id: storyId,
    name
  } = story;
  const onDelete = useCallback(() => {
    openDialog(isSubtask ? 'deleteSubtask' : 'delete', {
      onClick: async () => {
        try {
          closeDialog();
          await deleteStory(storyId);
          closeCurrentStoryDialog();
          addToast({
            kind: 'success',
            timeout: 5000,
            Content: () => _jsxs(ToastText, {
              children: [isSubtask ? 'Sub-Task' : 'Story', ' ', _jsx("strong", {
                children: _jsx(Emojify, {
                  children: name
                })
              }), ' ', "deleted."]
            })
          });
        } catch {
          addToast({
            kind: 'alert',
            Content: () => _jsxs(ToastText, {
              children: ["We were unable to delete", ' ', _jsx("strong", {
                children: _jsx(Emojify, {
                  children: name
                })
              })]
            })
          });
        }
      }
    });
  }, [name, isSubtask, storyId, closeDialog, openDialog]);
  return onDelete;
};
export const DeleteStoryAction = ({
  id: storyId,
  name,
  isSubtask = false
}) => {
  const onDelete = useDeleteStoryDialog({
    id: storyId,
    name
  }, isSubtask);
  return _jsxs(MoreActions.Item, {
    icon: "Trash",
    aside: "shift D",
    onClick: onDelete,
    children: ["Delete ", isSubtask ? 'Sub-Task' : 'Story']
  });
};
DeleteStoryAction.displayName = "DeleteStoryAction";