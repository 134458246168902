import { FooterAnchorButton } from '@clubhouse/shared/components/Select';
import { PAGE_NAMES, generatePathWithSlug, getPathForPage, navigateOnClick } from 'utils/navigation';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const WorkflowFooter = ({
  onClick
}) => {
  const pageName = PAGE_NAMES.SETTINGS_WORKFLOWS;
  const href = generatePathWithSlug(getPathForPage(pageName));
  return _jsx(FooterAnchorButton, {
    onClick: navigateOnClick(href),
    href: href,
    text: "Edit Workflows",
    icon: "Workflow"
  });
};
WorkflowFooter.displayName = "WorkflowFooter";