import "core-js/modules/es.array.push.js";
import Backend from '../modules/backend';
import Collection from '../_frontloader/collection';
import CompanyModel from './company';
import EpicStateModel from './epicState';
import Globals from '../_frontloader/globals';
import IconModel from './icon';
import InviteModel from './invite';
import Is from '../modules/is';
import OrganizationProfileModel from './organizationProfile';
import PaymentPlan2Model from './paymentPlan2';
import PermissionModel from './permission';
import ProfileModel from './profile';
import Url from '../modules/url';
import UserModel from './user';
import Utils from '../modules/utils';
import { ENTITLEMENTS } from 'data/entity/entitlements';
import { ORGANIZATION_FEATURES } from '../modules/organizationFeatures';
import { purify } from '@clubhouse/shared/utils/format';
import _ from 'lodash';
const exports = {
  Promises: {}
};

/*

Example Organization entity:

{
  beta: true
  disabled: false
  estimate_scale: EstimateScale
  id: "5390b4a4-5b91-4e27-b80b-879e2762bf69"
  location: "NYC"
  name: "Shortcut"
  public_url: "http://shortcut.com"
  signup_domain: "shortcut.com"
  url_slug: "internal"
}

*/

Collection.create('Organization', exports);
exports.DEFAULT_BRANCH_FORMATTER = '[owner_username]/[story_id]/[story_name]';
exports.isValid = obj => {
  return obj && obj.id && obj.url_slug;
};

/**
   Ignore paths that are used on the company managment page. The user
   needs to be able to access these urls on that page.
*/
function allowedPath(currentPath) {
  // With this page, the static value is at the first part of the url,
  // so we can use startsWith
  return _.startsWith(currentPath, '/organizations') ||
  // With these pages, the company slug is on the first part of the
  // url so we should use endsWith.
  _.endsWith(currentPath, '/login') || _.endsWith(currentPath, '/settings/billing') || _.endsWith(currentPath, '/settings/account/two-factor-auth') || _.endsWith(currentPath, '/settings/account/password') || _.endsWith(currentPath, '/settings/account/email') || _.endsWith(currentPath, '/settings/account');
}
exports.normalize = org => {
  IconModel.updateWithType(org.loading_icons, IconModel.TYPES.LOADING);
  IconModel.updateWithType([org.display_icon], IconModel.TYPES.ORGANIZATION);

  // Only included on the org entity from direct requests to the endpoint
  _.get(org, 'epic_workflow.epic_states', []).forEach(EpicStateModel.updateIfValid);
  const current_slug = Url.getCurrentSlug();

  // On the organizations management page we were redirecting users
  // back to organizations list page when the organization was
  // disabled. Since the only way to enable an organization is
  // actually on this management page, users were left without a way to
  // enable their organization.

  if (org.url_slug === current_slug && org.disabled === true && !allowedPath(Url.getCurrentPathname())) {
    return Utils.redirect('/organizations');
  }
};
exports.getCurrentTestValue = name => {
  const org = exports.getCurrent() || {};
  org.properties = org.properties || {};
  return org.properties[name];
};
exports.getFirstEnabledPermission = () => {
  let permission = false;
  UserModel.getLoggedInUserProfiles().forEach(profile => {
    if (!permission && profile.disabled === false) {
      permission = _.find(profile.permissions, {
        disabled: false
      });
    }
  });
  return permission;
};
exports.isOrgDefault = org => {
  const profile = OrganizationProfileModel.getOrgProfileForLoggedInUser(org.id);
  const permission = UserModel.getLoggedInUserPermission(org.id);
  return profile.default && permission.default;
};

// We have the current org from the first UserModel.fetchCurrentUser request.
exports.getCurrent = () => {
  // Check if we're on the manage organizations page
  const company = CompanyModel.getFromUrl();
  if (company) {
    if (company.disabled) {
      // If company is disabled, return any org
      const org = company.organizations[0];
      if (org) return org;
    } else {
      // Otherwise return first non-disabled org.
      const org = company.organizations.find(organization => !organization.disabled);
      if (org) return org;
    }
  }
  const currentSlug = Url.getCurrentSlug();

  // If the current slug exists and isn't disabled, try using that first:
  if (currentSlug) {
    const enabledMatchingSlug = exports.get({
      url_slug: currentSlug,
      disabled: false
    });
    if (enabledMatchingSlug) {
      return enabledMatchingSlug;
    }
  }
  const permission = UserModel.getDefaultPermissionFromProfile(OrganizationProfileModel.getDefault());
  const defaultOrgId = _.get(permission, 'organization.id');

  // It's possible that the default workspace is enabled, but the permission itself is disabled,
  // in which case let's look for the first workspace with a non-disabled permission.
  // Ref: https://app.shortcut.com/internal/story/104055/invitation-link-not-working
  if (permission && permission.disabled) {
    const firstEnabledPermission = exports.getFirstEnabledPermission();
    if (firstEnabledPermission) {
      return exports.getById(_.get(firstEnabledPermission, 'organization.id'));
    }
  }
  return (
    // User could belong to only disabled orgs.
    // fall back to any non-disabled workspace.
    exports.get({
      id: defaultOrgId,
      disabled: false,
      is_public_demo: false
    }) ||
    // fall back to default workspace.
    exports.get({
      disabled: false,
      is_public_demo: false
    }) || exports.first()
  );
};
exports.getFromCurrentSlug = () => {
  const currentSlug = Url.getCurrentSlug();
  return exports.get({
    url_slug: currentSlug
  }) || {};
};
exports.getActiveFilter = org => {
  const company = _.get(OrganizationProfileModel.getOrgProfileForLoggedInUser(org.id), 'company', {});
  const permission = PermissionModel.get({
    organization: {
      id: org.id
    }
  });
  return !exports.isDisabled(org.id) && !company.locked_out && !permission.disabled;
};
exports.getAllActive = () => {
  return _.sortBy(exports.filter(exports.getActiveFilter), org => {
    return _.get(OrganizationProfileModel.getOrgProfileForLoggedInUser(org.id), 'position');
  });
};
exports.getAllOtherActive = () => {
  const current = exports.getCurrent() || {};
  return _.reject(exports.getAllActive(), org => {
    return org.id === current.id;
  });
};
exports.getSlugsOtherThanSlug = slug => {
  return _.without(exports.map('url_slug'), slug);
};
exports.getOwnersDisabledOrgs = () => {
  return exports.filter(org => {
    const company = OrganizationProfileModel.getOrgProfileForLoggedInUser(org.id).company;
    const permission = PermissionModel.get({
      organization: {
        id: org.id
      }
    }) || {};
    return exports.isDisabled(org.id) && !company.locked_out && Is.ownerOnly(permission);
  });
};
exports.getCurrentName = () => {
  const org = exports.getCurrent();
  return _.get(org, 'name', 'Unknown');
};
exports.getCurrentID = () => {
  const org = exports.getCurrent();
  return _.get(org, 'id', '');
};
exports.getActiveUsersAndInvites = (orgID = Globals.get('organizationID')) => {
  const activeUsers = ProfileModel.getAllActiveProfileDetails(orgID);
  const invites = orgID ? InviteModel.allPendingForOrganization(orgID) : InviteModel.allPending();
  return activeUsers.concat(invites);
};
exports.getActiveUserCount = (orgID = Globals.get('organizationID')) => {
  return exports.getActiveUsersAndInvites(orgID).length;
};
exports.getBillableUserCount = () => {
  return CompanyModel.getCurrent().num_billable;
};
exports.getCurrentUtcOffset = () => {
  const org = exports.getCurrent();
  return _.get(org, 'utc_offset', '');
};
exports.getCurrentCreationDate = () => {
  const org = exports.getCurrent();
  return _.get(org, 'created_at', '');
};
exports.getSlackConfiguration = () => {
  const org = exports.getCurrent();
  return _.get(org, 'slack', {});
};
exports.getBranchFormat = () => {
  const org = exports.getCurrent();
  return org && org.vcs_branch_format ? purify(org.vcs_branch_format) : exports.DEFAULT_BRANCH_FORMATTER;
};
exports.updateGitBranchFormat = (format, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.put('/api/private/workspace2/vcs-branch-format', {
    data: {
      vcs_branch_format: format
    },
    onComplete: (res, xhr) => {
      if (xhr.status === 200) {
        exports.getCurrent().vcs_branch_format = format;
        callback(null);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
exports.disableStoryTemplates = () => {
  return new Promise((resolve, reject) => {
    Backend.put('/api/private/entity-templates/disable', {
      onComplete: response => {
        if (response.error) {
          reject(response);
        } else {
          exports.fetchCurrent(resolve);
        }
      }
    });
  });
};
exports.enableStoryTemplates = () => {
  return new Promise((resolve, reject) => {
    Backend.put('/api/private/entity-templates/enable', {
      onComplete: response => {
        if (response.error) {
          reject(response);
        } else {
          exports.fetchCurrent(resolve);
        }
      }
    });
  });
};
exports.disableIterations = () => {
  return new Promise((resolve, reject) => {
    Backend.put('/api/private/iterations/disable', {
      onComplete: response => {
        if (response.error) {
          reject(response);
        } else {
          exports.fetchCurrent(resolve);
        }
      }
    });
  });
};
exports.enableIterations = () => {
  return new Promise((resolve, reject) => {
    Backend.put('/api/private/iterations/enable', {
      onComplete: response => {
        if (response.error) {
          reject(response);
        } else {
          exports.fetchCurrent(resolve);
        }
      }
    });
  });
};
exports.templatesAreEnabled = () => {
  return _.get(exports.getCurrent(), ORGANIZATION_FEATURES.STORY_TEMPLATES);
};
exports.iterationsAreEnabled = () => {
  return _.get(exports.getCurrent(), ORGANIZATION_FEATURES.ITERATIONS);
};
exports.writeIsEnabled = () => {
  const plan = PaymentPlan2Model.getPlanForCurrentOrg();
  return plan && PaymentPlan2Model.hasEntitlement(plan, ENTITLEMENTS.WRITE) && !exports.getCurrent().docs_disabled;
};
exports.trueCustomFieldsIsEnabled = () => {
  const plan = PaymentPlan2Model.getPlanForCurrentOrg();
  return plan && PaymentPlan2Model.hasEntitlement(plan, ENTITLEMENTS.CUSTOM_FIELDS);
};
exports.overlappingPRsIsEnabled = () => {
  const plan = PaymentPlan2Model.getPlanForCurrentOrg();
  return plan && PaymentPlan2Model.hasEntitlement(plan, ENTITLEMENTS.OVERLAPPING_PRS);
};
exports.getIntegrationDisallowList = () => {
  const disallowList = _.get(exports.getCurrent(), 'integration_disallow_list');
  return _.isArray(disallowList) ? disallowList : [];
};
exports.isIntegrationEnabled = integrationType => {
  return !_.includes(exports.getIntegrationDisallowList(), integrationType);
};
exports.allowIntegration = (integrationType, callback) => {
  const disallowList = _.without(exports.getIntegrationDisallowList(), integrationType);
  exports.saveChanges({
    integration_disallow_list: disallowList
  }, callback);
};
exports.disallowIntegration = (integrationType, callback) => {
  const disallowList = exports.getIntegrationDisallowList();
  disallowList.push(integrationType);
  exports.saveChanges({
    integration_disallow_list: disallowList
  }, callback);
};
exports.isScimEnabled = id => {
  const org = exports.getById(id) || {};
  return Boolean(org.scim_enabled);
};
exports.isDisabled = id => {
  const org = exports.getById(id) || {};
  const company = _.get(OrganizationProfileModel.getOrgProfileForLoggedInUser(id), 'company', {});
  return company.disabled || org.disabled;
};
exports.fetch = (org, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  if (org) {
    Backend.get('/api/private/workspace2', {
      onComplete: (res, xhr) => {
        if (xhr.status === 401) {
          // Org or company is disabled.
          // Whatever that may be, it is not the concern of this function so, callback!
          callback(null);
        } else if (exports.isValid(res)) {
          if (!Is.testEnvironment() && res.url_slug !== org.url_slug) {
            return Utils.redirectToSlug(res.url_slug);
          }
          exports.update(res);
          callback(null, exports.getById(res.id));
        } else {
          exports.defaultErrorHandler(res, callback);
        }
      },
      organization_id: org.id
    });
  } else {
    callback({
      message: 'Unable to fetch organization.'
    });
  }
};
exports.Promises.fetchAutoJoinEnabled = () => new Promise(resolve => Backend.get(`/api/private/workspace2/auto-join`, {
  onComplete: res => {
    exports.defaultGetHandler(res, () => resolve(res));
  }
}));
exports.Promises.setAutoJoinEnabled = data => new Promise(resolve => Backend.put(`/api/private/workspace2/auto-join`, {
  data,
  onComplete: res => {
    exports.defaultGetHandler(res, () => resolve(res));
  },
  actionContext: 'settings'
}));
exports.Promises.fetchAvailableDomains = () => new Promise(resolve => Backend.get(`/api/private/workspace2/auto-join/available-domains`, {
  onComplete: res => {
    exports.defaultGetHandler(res, () => resolve(res));
  }
}));
exports.Promises.fetchEnabledDomains = () => new Promise(resolve => Backend.get(`/api/private/workspace2/auto-join/enabled-domains`, {
  onComplete: res => {
    exports.defaultGetHandler(res, () => resolve(res));
  }
}));
exports.Promises.setEnabledDomains = data => new Promise(resolve => Backend.put(`/api/private/workspace2/auto-join/enabled-domains`, {
  data,
  onComplete: res => {
    exports.defaultGetHandler(res, () => resolve(res));
  }
}));
exports.fetchCurrent = callback => {
  exports.fetch(exports.getCurrent(), callback);
};
exports.saveChanges = (updates, callback) => {
  Backend.put('/api/private/workspace2', {
    data: updates,
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.Promises.saveChanges = data => new Promise(resolve => Backend.put('/api/private/workspace2', {
  data,
  onComplete: res => {
    exports.defaultGetHandler(res, () => resolve(res));
  }
}));
exports.reEnableOrg = (org, callback, options) => {
  Backend.put('/api/private/owner/workspaces2/' + org.id + '/enable', _.assign({}, options, {
    onComplete: res => {
      exports.defaultGetHandler(res, () => {
        // We also request a new payment plan with the billable users count updated
        PaymentPlan2Model.fetch(callback);
      });
    }
  }));
};
exports.disableOrg = (org, callback) => {
  Backend.put('/api/private/owner/workspaces2/' + org.id + '/disable', {
    onComplete: res => {
      exports.defaultGetHandler(res, callback);
    }
  });
};
exports.deleteOrg = (org, callback) => {
  Backend.delete('/api/private/owner/workspaces2/' + org.id, {
    onComplete: (res, xhr) => {
      exports.defaultDeleteHandler(org, res, xhr, callback);
    }
  });
};
export { exports as default };