import { AlertMessage } from '@clubhouse/shared/components/AlertMessage';
import { Text } from '@clubhouse/shared/components/Typography/Text';
import { useOpenFeedbackForm } from 'components/feedback/useOpenFeedbackForm';
import { KNOWLEDGE_BASE_SLUGS } from 'utils/knowledgeBase';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const ProjectsInfoBanner = () => {
  const {
    openFeedbackForm
  } = useOpenFeedbackForm();
  return _jsxs(AlertMessage, {
    size: AlertMessage.SIZE.SMALL,
    kind: AlertMessage.KIND.INFO,
    children: [_jsx(AlertMessage.Title, {
      children: "Try Teams and Custom Fields"
    }), _jsxs(Text, {
      bold: true,
      children: ["Projects is a legacy feature. We recommend checking out our help documentation on how to leverage", _jsx("a", {
        href: KNOWLEDGE_BASE_SLUGS.TEAMS,
        target: "_blank",
        rel: "noopener noreferrer",
        children: "\xA0Teams"
      }), "\xA0and", _jsx("a", {
        href: KNOWLEDGE_BASE_SLUGS.CUSTOM_FIELDS,
        target: "_blank",
        rel: "noopener noreferrer",
        children: "\xA0Custom Fields"
      }), ", instead. For questions about Projects, please reach out to our", _jsx("a", {
        href: "#",
        onClick: () => openFeedbackForm(),
        children: "\xA0Support Team"
      }), "."]
    })]
  });
};
ProjectsInfoBanner.displayName = "ProjectsInfoBanner";