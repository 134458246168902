import "core-js/modules/es.array.push.js";
import { RELATIONSHIPS, VERBS } from 'data/entity/storyLinkConst';
import StoryModel from './story';
import { getOrFetchStory } from '../../../../../data/entity/story';
import BaseUtils from '../_frontloader/baseUtils';
import Collection from '../_frontloader/collection';
import StoryLookupController from '../controllers/storyLookup';
import Backend from '../modules/backend';
import Iterate from '../modules/iterate';
import { renderComponentToString } from 'utils/helpers';
import { EVENT_TYPES, dispatchEvent } from 'utils/collectionizeToApolloMessageBus';
import { SizedIcon } from '@clubhouse/shared/components/SizedIcon/SizedIcon';
import _ from 'lodash';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
const exports = {};

/*

Example StoryLink entity:

{
  created_at: "2016-05-05T01:17:08Z"
  id: 500004217
  object_id: 2669
  subject_id: 6881
  type: "subject"
  updated_at: "2016-05-05T01:17:08Z"
  verb: "relates to"
}

*/

Collection.create('StoryLink', exports);
exports.VERBS = VERBS;
exports.RELATIONSHIPS = RELATIONSHIPS;
exports.RELATIVE_VERBS = {};
exports.RELATIVE_VERBS[exports.VERBS.RELATE] = {
  subject: 'Relates to',
  object: 'Relates to',
  activity: 'as related to',
  className: 'relationship',
  icon: 'RelatedTo',
  color: 'var(--shapes-primaryMain)'
};
exports.RELATIVE_VERBS[exports.VERBS.BLOCK] = {
  subject: 'Blocks',
  object: 'Blocked by',
  objectDropdown: 'Is blocked by',
  activity: 'to block',
  className: 'blocker',
  icon: 'Warn',
  color: 'var(--iconYellowColor)'
};
exports.RELATIVE_VERBS[exports.VERBS.DUPLICATE] = {
  subject: 'Duplicates',
  object: 'Duplicated by',
  objectDropdown: 'Is duplicated by',
  activity: 'as a duplicate of',
  className: 'duplicate',
  icon: 'Copy',
  color: 'var(--iconOrangeColor)'
};
exports.getLinkFromName = linkName => {
  const defaultLinkType = 'relates to';
  const links = exports.RELATIONSHIPS;
  return links[linkName] || links[defaultLinkType];
};
exports.isValid = link => {
  return link && link.id && link.object_id && link.subject_id && link.verb;
};
exports.isSubject = link => {
  return link.type === 'subject';
};
exports.getRelativeVerb = link => {
  const relative_verb = exports.RELATIVE_VERBS[link.verb];
  return relative_verb ? relative_verb[link.type] : '';
};
exports.getRelatedStoryID = link => {
  return exports.isSubject(link) ? link.object_id : link.subject_id;
};
exports.getRelatedStory = link => {
  return StoryModel.getById(exports.getRelatedStoryID(link));
};
exports.getClassName = link => {
  const relative_verb = exports.RELATIVE_VERBS[link.verb];
  return relative_verb ? relative_verb.className : '';
};
exports.getIcon = link => {
  const relationship = _.find(exports.RELATIONSHIPS, {
    verb: link.verb,
    type: link.type
  });

  // this migh not match on related, so fall back to that.
  const relative_verb = exports.RELATIVE_VERBS[link.verb];
  return relationship ? renderComponentToString(_jsx(SizedIcon, {
    icon: relationship.icon,
    size: 16,
    customFill: relationship.color
  })) : relative_verb ? renderComponentToString(_jsx(SizedIcon, {
    icon: relative_verb.icon,
    size: 16,
    customFill: relative_verb.color
  })) : '';
};
exports.isArchived = link => {
  const story = StoryModel.getById(link.isSubject ? link.object_id : link.subject_id);
  return story ? StoryModel.isArchived(story) : false;
};
exports.isInvalid = link => {
  let isInvalid = false;
  if (link.object_id && !StoryModel.getById(link.object_id)) {
    isInvalid = true;
  }
  if (link.subject_id && !StoryModel.getById(link.subject_id)) {
    isInvalid = true;
  }
  return isInvalid;
};
exports.isValidWithFetch = async link => {
  if (link.object_id && link.subject_id) {
    const results = await Promise.allSettled([getOrFetchStory(link.object_id), getOrFetchStory(link.subject_id)]);
    return results.some(el => Boolean(el.value));
  }
  if (link.object_id) {
    try {
      const story = await getOrFetchStory(link.object_id);
      return Boolean(story);
    } catch {
      return false;
    }
  }
  if (link.subject_id) {
    try {
      const story = await getOrFetchStory(link.subject_id);
      return Boolean(story);
    } catch {
      return false;
    }
  }
  return true;
};
exports.dedupeByKey = links => {
  return Object.values(links.reduce((acc, link) => {
    acc[exports.getKey(link)] = link;
    return acc;
  }, {}));
};
exports.getLinksForStory = story => {
  let links = _.map(story.story_links, link => {
    // Inferring link types when adding a new story and only one ID is present
    link.type = link.type || (link.subject_id ? 'object' : 'subject');
    link.isSubject = exports.isSubject(link);

    // Might be a story which isn't loaded.
    if (!exports.getRelatedStory(link)) {
      StoryLookupController.add(exports.getRelatedStoryID(link));
    }
    return link;
  });
  links = exports.dedupeByKey(_.compact(links));
  return _.sortBy(links, link => {
    return exports.getRelativeVerb(link) + link.id;
  });
};
exports.getBlockedByCount = story => {
  if (StoryModel.isDoneState(story)) {
    return 0;
  }
  let blocked_stories = 0;
  Iterate.each(story.story_links, link => {
    if (link.verb !== exports.VERBS.BLOCK || link.subject_id !== story.id) {
      return;
    }
    blocked_stories += 1;
  });
  return blocked_stories;
};
exports.getBlockersCount = story => {
  let blockers = 0;
  Iterate.each(story.story_links, link => {
    if (link.verb !== exports.VERBS.BLOCK || link.object_id !== story.id) {
      return;
    }
    if (StoryModel.isDoneState({
      workflow_state_id: link.subject_workflow_state_id
    })) {
      return;
    }
    blockers += 1;
  });
  return blockers;
};
exports.hasBlocker = story => {
  return story.blocked && exports.getBlockersCount(story) > 0;
};
exports.isBlocker = story => {
  return story.blocker && exports.getBlockedByCount(story) > 0;
};
exports.storyIsInLink = (story, link) => {
  return story.id === link.object_id || story.id === link.subject_id;
};
exports.addStoryLinksToStories = story => {
  Iterate.each(story.story_links || [], link => {
    exports.updateStoriesWithNewStoryLink(link, [story]);
  });
};
exports.getKey = storyLink => {
  const {
    verb,
    object_id = 'no-object-id',
    subject_id = 'no-subject-id'
  } = storyLink;
  return [verb, object_id, subject_id].join('-').replace(/\s/g, '');
};
exports.updateStoryLinks = ({
  storyLinks = [],
  existingStoryLink,
  update
}) => {
  if (existingStoryLink) {
    _.remove(storyLinks, link => exports.getKey(link) === exports.getKey(existingStoryLink));
  }
  if (update) {
    const updatedLink = _.pickBy({
      ...update
    }, _.identity);
    storyLinks.push(updatedLink);
  }
  return storyLinks;
};
exports.updateStoriesWithNewStoryLink = (link, excludedStories) => {
  StoryModel.each(story => {
    if (exports.storyIsInLink(story, link) && !_.some(excludedStories, {
      id: story.id
    })) {
      const type = story.id === link.subject_id ? 'subject' : 'object';
      _.remove(story.story_links, item => item.id === link.id);
      story.story_links.push({
        ...link,
        type
      });
      StoryModel.update(story);
      StoryModel.trigger('storySaved', story);
    }
  });
};
exports.save = (link, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.post('/api/private/story-links', {
    data: link,
    onComplete: res => {
      if (!res.error) {
        exports.updateStoriesWithNewStoryLink(res);
      }
      callback(res.error, res);
    }
  });
};
exports.delete = (id, callback) => {
  callback = _.isFunction(callback) ? callback : _.noop;
  Backend.delete('/api/private/story-links/' + id, {
    onComplete: (res, xhr) => {
      if (xhr.status === 204 || xhr.status === 404) {
        StoryModel.each(story => {
          const link = _.remove(story.story_links, {
            id
          });
          if (link.length) {
            StoryModel.update(story);
            StoryModel.trigger('storySaved', story);
          }
        });
        callback(null);
        dispatchEvent(EVENT_TYPES.STORY_UPDATED);
      } else {
        exports.defaultErrorHandler(res, callback);
      }
    }
  });
};
const update = (entity, callback) => {
  const {
    id,
    verb,
    subject_id,
    object_id
  } = entity;
  Backend.put(`/api/private/story-links/${id}`, {
    data: {
      verb,
      subject_id,
      object_id
    },
    onComplete: res => {
      if (res.error) {
        return exports.defaultErrorHandler(res, callback);
      }
      exports.defaultGetHandler(res, callback);
      exports.updateStoriesWithNewStoryLink(res);
      dispatchEvent(EVENT_TYPES.STORY_UPDATED);
    }
  });
};
exports.Promises = {
  update: BaseUtils.promisify(update),
  delete: BaseUtils.promisify(exports.delete)
};
export { exports as default };