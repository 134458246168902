import _ from 'lodash';
const exports = {};
const _getTopOffset = (el, options) => {
  const offsetFn = options.usePosition ? 'position' : 'offset';
  return $(el)[offsetFn]().top;
};
const getCaptionElement = $parent => {
  const el = document.createElement('div');
  el.className = 'caption floating-caption';
  $parent.append(el);
  return el;
};
exports.init = options => {
  const $parent = $(options.context);
  const captionEl = getCaptionElement($parent);
  $parent.on('scroll.FloatingCaption', function () {
    const yPos = _getTopOffset(this, options);
    if (yPos > 0) {
      let caption = '';
      let $caption = null;
      let topOffset = 0;
      const captions = Array.from($parent.find('.caption'));
      captions.forEach(c => {
        const $c = $(c);
        if (_getTopOffset($c, options) < yPos) {
          caption = c.textContent.trim();
          $caption = $c;
        }
      });
      if (caption) {
        captionEl.textContent = caption;
        if (_.isFunction(options.topOffset)) {
          topOffset = options.topOffset();
        } else if (typeof options.topOffset === 'number') {
          topOffset = options.topOffset;
        }
        const calculatedWidth = typeof options.getWidth === 'function' ? options.getWidth() : options.width;
        $(captionEl).css({
          top: yPos - topOffset,
          width: calculatedWidth || $caption?.width(),
          zIndex: 2
        });
      }
    }
  });
};
exports.destroy = context => {
  $(context).off('scroll.FloatingCaption');
};
export { exports as default };