import { MoreActions } from '@clubhouse/shared/components/MoreActions';
import { ToastText, addToast } from '@clubhouse/shared/components/Toast';
import { StoryAutoLink } from 'components/shared/StoryAutoLink';
import { useCallback } from 'react';
import { useStoryConfirmationDialog } from '../hooks/useStoryConfirmationDialog';
import { useUpdateParentStoryMutation } from '../hooks/useUpdateParentStoryMutation';
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const showToast = (success, message) => {
  addToast({
    kind: success ? 'success' : 'alert',
    width: 400,
    timeout: 5000,
    Content: () => _jsx(ToastText, {
      children: message
    })
  });
};
export const ConvertToStoryAction = ({
  story
}) => {
  const {
    openDialog,
    closeDialog
  } = useStoryConfirmationDialog();
  const updateParentStory = useUpdateParentStoryMutation();
  const convertToStory = useCallback(() => {
    openDialog('convertToStory', {
      onClick: async () => {
        try {
          closeDialog();
          await updateParentStory({
            storyId: story.id,
            parentStory: null
          });
          showToast(true, _jsxs(_Fragment, {
            children: ["Sub-Task", ' ', _jsx("strong", {
              children: _jsx(StoryAutoLink, {
                id: story.id,
                publicId: story.publicId,
                name: story.name,
                type: story.type
              })
            }), ' ', "was converted to a Story."]
          }));
        } catch {
          showToast(false, _jsxs(_Fragment, {
            children: ["Unable to convert", ' ', _jsx("strong", {
              children: _jsx(StoryAutoLink, {
                id: story.id,
                publicId: story.publicId,
                name: story.name,
                type: story.type
              })
            }), "."]
          }));
        }
      }
    });
  }, [closeDialog, openDialog, story, updateParentStory]);
  return _jsx(MoreActions.Item, {
    icon: "Story",
    onClick: convertToStory,
    children: "Convert to Story"
  });
};
ConvertToStoryAction.displayName = "ConvertToStoryAction";