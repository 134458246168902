import { useEffect, useState } from 'react';
import { CustomFieldSelect } from '@clubhouse/shared/components/CustomFieldSelect';
import { filterFieldsByStoryType } from '@clubhouse/shared/utils/customFields';
import { getValueIdsByFieldId, useCustomFields } from 'data/entity/customField';
import { jsx as _jsx, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const CustomFieldSelectList = ({
  storyType,
  storyCustomFields = [],
  onChange,
  isTouched,
  maxHeight
}) => {
  const [storyCustomFieldValues, setStoryCustomFieldValue] = useState(getValueIdsByFieldId(storyCustomFields));
  const {
    fields
  } = useCustomFields({
    filter: ({
      enabled
    }) => enabled
  });
  const filteredFields = filterFieldsByStoryType(storyType, fields);
  useEffect(() => {
    setStoryCustomFieldValue(getValueIdsByFieldId(storyCustomFields));
  }, [storyCustomFields]);
  return _jsx(_Fragment, {
    children: filteredFields.map(field => _jsx(CustomFieldSelect, {
      field: field,
      selectedValue: storyCustomFieldValues[field.id],
      isTouched: isTouched?.(field.id),
      onChange: valueId => {
        setStoryCustomFieldValue({
          ...storyCustomFieldValues,
          [field.id]: valueId
        });
        onChange(field.id, valueId);
      },
      maxHeight: maxHeight
    }, field.id))
  });
};