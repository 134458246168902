import "core-js/modules/es.array.push.js";
import _ from 'lodash';
const exports = {};
exports.temporaryMemoize = (fn, delay) => {
  let cachedResult = null;
  let timeout;
  return () => {
    if (_.isNull(cachedResult)) {
      cachedResult = fn();
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cachedResult = null;
      }, delay || 250);
    }
    return cachedResult;
  };
};
exports.toNumber = str => {
  return str === '' || _.isArray(str) || _.isBoolean(str) || isNaN(str) ? str : Number.parseFloat(str);
};

/*
 * Wraps a function with a promise and replaces the callback functionality
 * with promise behavior.
 *
 * A note on optional arguments: The callback argument to the original function will always be supplied
 * by the promisified version. This means that all the arguments must be
 * specified, even previously optional arguments.
 *
 * If you want to promisify a
 * function with optional arguments, you'll need to wrap it first in a function that
 * ensures null values for the argument in question, then promisify the wrapper.
 *
 *
 * Promisify has two modes, default and "customArgs"
 *
 *
 * Default mode
 *
 * By default promisify assumes that the callback being replaced has a
 * signature that follows the nodejs standard:
 *   function callback(err, data) => {...}
 * (similar to `Bluebird.promisify` and `utils.promisify` in node.js)
 *
 * For example:
 * Collection.defaultGetHandler follows this pattern, so if you're promisifying
 * a function that passes its callback to Collection.defaultGetHandler, you
 * should use this option.
 *
 * "customArgs" mode
 *
 * If youre callback doesn't follow the nodeJs standard, you can pass "customArgs"
 * to get the *first* callback parameter as the argument to `resolve`.
 *
 * Example:
 *   // Prior function and usage:
 *   exports.myFunc = (one, two, callback) {...}; // where callback is expected to have just one argument
 *   exports.myFunc('something', 'something else', (result) => {
 *     // do stuff with `result`
 *   });
 *
 *   // Now promisified:
 *   exports.myFuncAsync = BaseUtils.promisify(exports.myFunc);
 *   exports.myFuncAsync('something', 'something else').then((result) => {
 *     // do stuff with `result`
 *   });
 *
 */
exports.promisify = (fn, options = {}) => {
  return (...args) => {
    return new Promise((resolve, reject) => {
      let callback = (err, data, errContext) => {
        if (err) {
          reject(options.passErrorContext && errContext ? errContext : err);
        } else {
          resolve(data);
        }
      };
      if (options.customArgs) {
        callback = resolve;
      }
      args.push(callback);
      fn(...args);
    });
  };
};
export { exports as default };