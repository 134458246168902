import { CopyInput } from '@clubhouse/shared/components/CopyInput';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const StoryId = ({
  storyId
}) => {
  return _jsxs("div", {
    className: "attribute story-id",
    children: [_jsx("span", {
      className: "name",
      children: "Story ID"
    }), _jsx(CopyInput, {
      value: storyId,
      className: "clipboard"
    })]
  });
};
StoryId.displayName = "StoryId";