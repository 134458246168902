import { COLORS } from '@clubhouse/shared/colors';
import { TINTS } from '@clubhouse/shared/color';
import * as constants from '@clubhouse/shared/constants';
import { TIERS } from '@clubhouse/shared/types';
import _ from 'lodash';
import moment from 'moment';
const exports = {};

// Ref: https://segment.com/clubhouse-kurt/sources/appclient/overview
exports.SEGMENT_KEY = 'FRjmPTKDumNBnI6fW0CelAeBG0jr0HGg';
exports.LOCALSTORAGE_PREFIX = `${BRAND.LOCALSTORAGE_NAMESPACE}.`;
exports.DEFAULT_POINT_SCALE = '0,1,2,4,8';
exports.UPLOAD_LIMIT_IN_MB = 50;
exports.PAGE_RENDER_TRACE_ID = 'page_render_trace_id';
exports.SHORT_DATE_FORMAT = 'MMM D YYYY';
exports.SHORT_DATE_NO_YEAR = 'MMM D';
exports.SHORT_DATE_TIME_NO_YEAR = 'MMM D, h:mm a';
exports.SHORT_DATE_TIME_FORMAT = 'MMM D YYYY, h:mm a';
exports.LONG_DATE_TIME_NO_YEAR = 'MMMM D h:mm:ss a';
exports.LONG_DATE_TIME_FORMAT = 'MMMM D YYYY, h:mm:ss a';
exports.TIME_FORMAT = 'h:mm a';
exports.ISO_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss[Z]';

// This is used as an explicit placeholder when we don't want to set a
// filter value (ie: No Milestone), instead of passing in a falsy value.
exports.NO_FILTER_KEY = 'NONE';
exports.SIDEBAR_SETTINGS_KEY = 'CLUBHOUSE.Stories.sidebarExpandedFilters.';
exports.ANON_PAGES = ['confirm-email', 'create-organization', 'verify-email', 'invites', 'invite-link', 'login', 'recovery', 'reset', 'signup', 'sso', 'contacts'];
exports.RESERVED_SLUGS = exports.ANON_PAGES.concat(['auth', 'appauth', 'charts', 'company', 'dashboard', 'epic', 'epics', 'files', 'help', 'insights', 'integration', 'integrations', 'label', 'labels', 'milestone', 'milestones', 'objective', 'objectives', 'oauth', 'organizations', 'settings', 'stories', 'story', 'project', 'projects', 'reports', 'search', 'signup-new-org', 'status', 'team',
// 2020-11-23: There is an organization named `teams`, so we should not add `teams` as a reserved slug.
'write']);
exports.PROJECT_COLORS = ['#880000',
// darker red
'#cc0000',
// red
'#dd6e13',
// orange
'#e2c534',
// yellow
'#37710c',
// dark green
'#54ba08',
// green
'#9de061',
// light green
'#ceefaa',
// very light green
'#004c82',
// dark blue
'#007cbd',
// blue
'#08bbdf',
// light blue
'#7ce8cf',
// light turquoise
'#4e0380',
// dark purple
'#aa44dd',
// light purple
'#e566a0',
// pink
'#986030',
// dark brown
'#ae9744',
// light brown
'#090909',
// black
'#888888',
// dark grey
'#c0c0c0',
// light grey
'#3e1191',
// deep purple
'#6515dd',
// purple
'#8b78fa',
// soft purple
'#ff5555',
// sunset
'#fbb81b',
// sunrise
'#00d38c',
// green
'#a3c5eb',
// dusty blue
'#414042' // graphite
];
exports.LABEL_COLORS = ['#f5e6ad',
// lighter yellow
TINTS.yellow,
// yellow
TINTS.red,
// light red
'#cc5856',
// red
COLORS.PINK72,
// magenta
TINTS.purple,
// purple
'#e69235',
// orange
TINTS['yellow-green'],
// light green
'#49a940',
// green
TINTS.turquoise,
// purple
'#6db5ec',
// light blue
TINTS.slate,
// slate blue
'#4641d2',
// dark blue
TINTS.brass,
// brown
TINTS['light-gray'],
// lighter gray
COLORS.GRAY60,
// light gray
TINTS.black // black
];
exports.STORY_TYPE_ICONS = {
  feature: 'fa-certificate',
  bug: 'fa-bug',
  chore: 'fa-wrench'
};
exports.NO_STORY_TEMPLATE = constants.NO_STORY_TEMPLATE;
exports.WORKFLOW_STATE_TYPES = constants.WORKFLOW_STATE_TYPES;
exports.WORKFLOW_STATE_PALETTE = {
  archived: '#555',
  accepted: '#00d38c',
  completed: '#0b0',
  done: '#00d38c',
  delivered: '#0db350',
  deployed: '#0db350',
  canceled: '#877',
  rejected: '#9c1212',
  finished: '#d29800',
  'ready for deploy': '#d29800',
  'in progress': '#edd868',
  'in development': '#edd868',
  doing: '#edd868',
  started: '#edd868',
  unstarted: '#b486f9',
  'ready for dev': '#b486f9',
  'ready for development': '#b486f9',
  'to do': '#b486f9',
  backlog: '#a3c5eb',
  unscheduled: '#a3c5eb',
  icebox: '#a3c5eb'
};
exports.READYMADE_ESTIMATE_SCALES = [{
  id: 'default',
  name: 'Default',
  values: [0, 1, 2, 4, 8]
}, {
  id: 'linear',
  name: 'Linear',
  values: [0, 1, 2, 3, 4]
}, {
  id: 'fibonacci',
  name: 'Fibonacci',
  values: [0, 1, 2, 3, 5, 8]
}];
exports.UTC_OFFSETS = [{
  value: '-12:00'
}, {
  value: '-11:00'
}, {
  value: '-10:00'
}, {
  value: '-09:30'
}, {
  value: '-09:00'
}, {
  value: '-08:00'
}, {
  value: '-07:00'
}, {
  value: '-06:00'
}, {
  value: '-05:00'
}, {
  value: '-04:00'
}, {
  value: '-03:30'
}, {
  value: '-03:00'
}, {
  value: '-02:00'
}, {
  value: '-01:00'
}, {
  value: 'Z',
  display: '±00:00'
}, {
  value: '+01:00'
}, {
  value: '+02:00'
}, {
  value: '+03:00'
}, {
  value: '+03:30'
}, {
  value: '+04:00'
}, {
  value: '+04:30'
}, {
  value: '+05:00'
}, {
  value: '+05:30'
}, {
  value: '+05:45'
}, {
  value: '+06:00'
}, {
  value: '+06:30'
}, {
  value: '+07:00'
}, {
  value: '+08:00'
}, {
  value: '+08:30'
}, {
  value: '+08:45'
}, {
  value: '+09:00'
}, {
  value: '+09:30'
}, {
  value: '+10:00'
}, {
  value: '+10:30'
}, {
  value: '+11:00'
}, {
  value: '+12:00'
}, {
  value: '+12:45'
}, {
  value: '+13:00'
}, {
  value: '+14:00'
}];
exports.DAYS = [{
  name: 'Sunday',
  value: 1
}, {
  name: 'Monday',
  value: 2
}, {
  name: 'Tuesday',
  value: 3
}, {
  name: 'Wednesday',
  value: 4
}, {
  name: 'Thursday',
  value: 5
}, {
  name: 'Friday',
  value: 6
}, {
  name: 'Saturday',
  value: 7
}];
exports.CARD_MONTHS = [{
  name: 'January',
  value: '01'
}, {
  name: 'February',
  value: '02'
}, {
  name: 'March',
  value: '03'
}, {
  name: 'April',
  value: '04'
}, {
  name: 'May',
  value: '05'
}, {
  name: 'June',
  value: '06'
}, {
  name: 'July',
  value: '07'
}, {
  name: 'August',
  value: '08'
}, {
  name: 'September',
  value: '09'
}, {
  name: 'October',
  value: '10'
}, {
  name: 'November',
  value: '11'
}, {
  name: 'December',
  value: '12'
}];

// Used by completed column headers on the Stories page.
exports.MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
exports.CARD_YEARS = _.range(moment().format('YYYY'), moment().add(16, 'years').format('YYYY'));
exports.PAYMENT_PLAN_NAMES = {
  invoiced: 'Invoiced',
  'free-plan': 'Free',
  // grandfathered
  'non-profit-plan': 'Non Profit',
  'complimentary-plan': 'Complimentary',
  'yearly-standard-plan': 'Standard',
  // deprecated
  'monthly-standard-plan': 'Standard',
  // deprecated
  subscribed: 'Standard',
  enterprise: 'Enterprise'
};

// Per user
exports.STANDARD_PLAN_UNIT_COST = {
  month: 10,
  year: 102
};
exports.STANDARD_PLAN_RATES = {
  month: 10,
  year: 8.5
};

// Fixed cost
exports.SMALL_PLAN_RATES = {
  month: 10,
  year: 100
};
exports.CARD_ICONS = {
  Visa: 'fa-cc-visa',
  MasterCard: 'fa-cc-mastercard',
  'American Express': 'fa-cc-amex',
  Discover: 'fa-cc-discover',
  JCB: 'fa-cc-jcb'
};
exports.ICONS = {
  bz2: 'fa-file-archive-o',
  rar: 'fa-file-archive-o',
  iso: 'fa-file-archive-o',
  tar: 'fa-file-archive-o',
  gz: 'fa-file-archive-o',
  zip: 'fa-file-archive-o',
  aiff: 'fa-file-audio-o',
  mp3: 'fa-file-audio-o',
  m4a: 'fa-file-audio-o',
  ogg: 'fa-file-audio-o',
  flac: 'fa-file-audio-o',
  wav: 'fa-file-audio-o',
  wma: 'fa-file-audio-o',
  asp: 'fa-file-code-o',
  aspx: 'fa-file-code-o',
  c: 'fa-file-code-o',
  h: 'fa-file-code-o',
  html: 'fa-file-code-o',
  css: 'fa-file-code-o',
  js: 'fa-file-code-o',
  xml: 'fa-file-code-o',
  xls: 'fa-file-excel-o',
  xlt: 'fa-file-excel-o',
  xlm: 'fa-file-excel-o',
  xltx: 'fa-file-excel-o',
  xlsx: 'fa-file-excel-o',
  xlsm: 'fa-file-excel-o',
  bmp: 'fa-file-image-o',
  gif: 'fa-file-image-o',
  jpg: 'fa-file-image-o',
  jpeg: 'fa-file-image-o',
  png: 'fa-file-image-o',
  tif: 'fa-file-image-o',
  tiff: 'fa-file-image-o',
  blank: 'fa-file-o',
  pdf: 'fa-file-pdf-o',
  ppt: 'fa-file-powerpoint-o',
  pptx: 'fa-file-powerpoint-o',
  txt: 'fa-file-text-o',
  mpeg: 'fa-file-video-o',
  mpg: 'fa-file-video-o',
  avi: 'fa-file-video-o',
  doc: 'fa-file-word-o',
  docx: 'fa-file-word-o'
};
exports.USER_ABORTED = 'The request was cancelled.';
exports.REQUEST_FAILED = 'The request failed. If you have any browser extensions that affect network requests, ' + `please try disabling them. Otherwise <a href="mailto:${BRAND.SUPPORT_EMAIL}">email us</a>.`;
exports.NETWORK_ERROR = 'We may be having temporary server issues. Please try again later.';
exports.STRIPE_ERROR = 'We may be having trouble communicating with our payment processor. Please try again later.';
exports.SERVER_DOWN_ERROR = 'We are having temporary server issues. We are aware and working on the problem ' + 'right now. Please try again later.';
exports.RESOURCE_NOT_FOUND_ERROR = 'Sorry, you do not have access to this resource.';
exports.UNABLE_TO_PARSE_RESPONSE_ERROR = 'We were unable to parse the server response. This could be a networking issue, so please try again later.';
exports.SILENT_ERRORS = ['Already in position.'];
exports.CHART_COLORS = ['#63ACD7',
// blue
'#62CB8C',
// mint
'#EA9458',
// orange
'#E6D566',
// yellow
'#E27680',
// rose
'#9C81B6',
// violet
'#ABCFE4',
// light blue
'#E566A0',
// pink
'#ABE1E4',
// light cyan
'#FD8686',
// coral
'#8184C7',
// blue-purple
'#C0C0C0',
// light grey
'#FFC232',
// yellow
'#888888',
// dark grey
'#7FD4CF',
// cyan
'#A3C5EB' // powder blue
];
export const DISPLAY_CONFIG = {
  [TIERS.FREE]: {
    displayName: 'Free'
  },
  [TIERS.FREE_LIMITED]: {
    displayName: 'Free'
  },
  [TIERS.STANDARD]: {
    displayName: 'Standard'
  },
  [TIERS.TEAM]: {
    displayName: 'Team'
  },
  [TIERS.TEAM_WRITE]: {
    displayName: 'Team (with Write)'
  },
  [TIERS.BUSINESS]: {
    displayName: 'Business'
  },
  [TIERS.BUSINESS_WRITE]: {
    displayName: 'Business (with Write)'
  },
  [TIERS.ENTERPRISE]: {
    displayName: 'Enterprise'
  }
};
export { exports as default };