import { isValidUrl } from '@clubhouse/shared/utils/url';
import { getSelection, isSupportedEntityLink } from '../utils';
const isInLink = (before, after) => before.endsWith('[') || after.startsWith('](') || before.endsWith('](');
const prefix = '[';
const midfix = '](';
const postfix = ')';
export const linkCommand = {
  key: 'k',
  name: 'Link',
  icon: 'Link',
  events: {
    drop(e, textarea, setValue) {
      if (!e.dataTransfer?.items?.length) return;
      if (e.dataTransfer?.types.includes('Files')) return;
      if (e.defaultPrevented) return;
      const {
        before,
        selected,
        after,
        selectionStart,
        selectionEnd
      } = getSelection(textarea);
      if (isInLink(before, after)) return;
      let item = null;
      for (const i of e.dataTransfer.items) {
        if (i.kind === 'string' && i.type.match(/^text\/uri-list/)) {
          item = i;
          break;
        }
      }
      if (!item) return;
      e.preventDefault();
      item.getAsString(draggedText => {
        if (isSupportedEntityLink(draggedText) && !selected) {
          const link = draggedText;
          const newValue = `${before}${link}${after}`;
          setValue(newValue, {
            selectionStart,
            selectionEnd
          });
        } else if (selected) {
          const link = `${prefix}${selected}${midfix}${draggedText}${postfix}`;
          const newValue = `${before}${link}${after}`;
          const selStart = selectionStart + prefix.length;
          setValue(newValue, {
            selectionStart: selStart,
            selectionEnd: selStart + selected.length
          });
        } else {
          const newValue = `${before}${prefix}${midfix}${draggedText}${postfix}${after}`;
          const cursor = selectionStart + prefix.length;
          setValue(newValue, {
            selectionStart: cursor,
            selectionEnd: cursor
          });
        }
      });
    },
    paste(e, textarea, setValue, pressedKeys) {
      if (e.clipboardData?.types.includes('Files')) return;
      if (e.defaultPrevented) return;
      const {
        before,
        selected,
        after,
        selectionStart
      } = getSelection(textarea);
      if (!selected) return;
      const pastedText = e.clipboardData?.getData('text');
      if (typeof pastedText !== 'string') return;
      if (isInLink(before, after)) return;
      if (!isValidUrl(pastedText)) return;
      if (pressedKeys.Shift) return;
      e.preventDefault();
      const linkTitle = selected || 'link';
      const link = `${prefix}${linkTitle}${midfix}${pastedText}${postfix}`;
      const newValue = `${before}${link}${after}`;
      const selStart = selectionStart + prefix.length;
      setValue(newValue, {
        selectionStart: selStart,
        selectionEnd: selStart + linkTitle.length
      });
    }
  },
  fn(textarea, setValue) {
    const {
      before,
      selected,
      after,
      selectionStart,
      selectionEnd
    } = getSelection(textarea);
    const selectedIsUrl = isValidUrl(selected);
    if (selectedIsUrl) {
      const newValue = `${before}${prefix}${midfix}${selected}${postfix}${after}`;
      const cursor = selectionStart + prefix.length;
      setValue(newValue, {
        selectionStart: cursor,
        selectionEnd: cursor
      });
    } else {
      const url = 'add-url';
      if (before.endsWith(prefix) && after.startsWith(`${midfix}${url}${postfix}`)) {
        const newValue = `${before.slice(0, -prefix.length)}${selected}${after.slice(midfix.length + url.length + postfix.length)}`;
        const selStart = selectionStart - prefix.length;
        const selEnd = selectionEnd - prefix.length;
        setValue(newValue, {
          selectionStart: selStart,
          selectionEnd: selEnd
        });
      } else {
        // Disable for now. This requires user to approve clipboard access. Not sure we want to ask about that?
        // if ('readText' in navigator.clipboard) {
        //   try {
        //     const str = await navigator.clipboard.readText();

        //     if (isValidUrl(str)) url = str;
        //   } catch {}
        // }

        const title = selected || 'add link title';
        const newValue = `${before}${prefix}${title}${midfix}${url}${postfix}${after}`;
        const selStart = selected.length > 0 // If text was selected, move cursor to the url. Otherwise, keep cursor in the label
        ? selectionStart + prefix.length + midfix.length + selected.length : selectionStart + prefix.length;
        const selEnd = selected.length > 0 ? selectionStart + prefix.length + midfix.length + selected.length + url.length : selectionStart + title.length + prefix.length;
        setValue(newValue, {
          selectionStart: selStart,
          selectionEnd: selEnd
        });
      }
    }
  }
};