import { Emojify } from '@clubhouse/shared/components/Emojify';
import { Tooltip, TooltipText } from '@clubhouse/shared/components/Tooltip';
import { THEME_NAMES, ThemeContextProvider } from '@clubhouse/shared/utils/theme';
import { Spaced } from '@clubhouse/shared/components/Spaced';
import { WorkflowStateIcon } from '../WorkflowStateIcon';
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const WorkflowStateTooltipContent = ({
  type,
  name
}) => _jsx(ThemeContextProvider, {
  themeName: THEME_NAMES.DARK,
  children: _jsxs(Spaced, {
    horizontally: true,
    amount: 4,
    align: "center",
    children: [_jsx(WorkflowStateIcon, {
      type: type
    }), _jsx(TooltipText, {
      children: _jsx(Emojify, {
        children: name
      })
    })]
  })
});
WorkflowStateTooltipContent.displayName = "WorkflowStateTooltipContent";
export const WorkflowStateTooltip = ({
  state,
  name,
  visible,
  followCursor,
  children
}) => {
  return _jsx(Tooltip, {
    visible: visible,
    followCursor: followCursor,
    content: _jsx(WorkflowStateTooltipContent, {
      type: state.type,
      name: name
    }),
    children: children
  });
};
WorkflowStateTooltip.displayName = "WorkflowStateTooltip";